import { useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Lottie from 'react-lottie';

// import hf1 from '../img/json/final/HOUSEHOLDONE.json';
// import hf2 from '../img/json/final/HOUSEHOLDTWO.json';
// import hf3 from '../img/json/final/HOUSEHOLDTHREE.json';
import hf4 from '../img/json/final/HOUSEHOLDFOUR.json';

import hydrantOneClean from '../img/json/HYDRANTONECLEAN.json';
import hydrantOneTainted from '../img/json/HYDRANTONETAINTED.json';
import hydrantOneToxic from '../img/json/HYDRANTONETOXIC.json';
import hydrantTwoClean from '../img/json/HYDRANTTWOCLEAN.json';
import hydrantTwoTainted from '../img/json/HYDRANTTWOTAINTED.json';
import hydrantTwoToxic from '../img/json/HYDRANTTWOTOXIC.json';
import pipeBurstOneClean from '../img/json/LEAKONECLEAN.json';
import pipeBurstOneTainted from '../img/json/LEAKONETAINTED.json';
import pipeBurstOneToxic from '../img/json/LEAKONETOXIC.json';
import pipeBurstTwoClean from '../img/json/LEAKTWOCLEAN.json';
import pipeBurstTwoTainted from '../img/json/LEAKTWOTAINTED.json';
import pipeBurstTwoToxic from '../img/json/LEAKTWOTOXIC.json';
import pipeBurstThreeClean from '../img/json/LEAKTHREECLEAN.json';
import pipeBurstThreeTainted from '../img/json/LEAKTHREETAINTED.json';
import pipeBurstThreeToxic from '../img/json/LEAKThHREETOXIC.json';
import seepage from '../img/json/HOUSEHOLDOILDROPONEBLACK.json';

import agriculturalPipeBurstOneOneClean from '../img/json/AGRLEAKONECLEAN.json';
import agriculturalPipeBurstOneOneTainted from '../img/json/AGRLEAKONETAINTED.json';
import agriculturalPipeBurstOneOneToxic from '../img/json/AGRLEAKONETOXIC.json';
import agriculturalPipeBurstOneTwoClean from '../img/json/AGRLEAKTWOCLEAN.json';
import agriculturalPipeBurstOneTwoTainted from '../img/json/AGRLEAKTWOTAINT.json';
import agriculturalPipeBurstOneTwoToxic from '../img/json/AGRLEAKTWOTOXIC.json';
import agriculturalPipeBurstOneThreeClean from '../img/json/AGRLEAKTHREECLEAN.json';
import agriculturalPipeBurstOneThreeTainted from '../img/json/AGRLEAKTHREETAINT.json';
import agriculturalPipeBurstOneThreeToxic from '../img/json/AGRLEAKTHREETOXIC.json';

import toiletsClean from '../img/json/NONTOILETSALTCLEAN.json';
import toiletsCleanSlowOne from '../img/json/NEWFLUSH.json';
import toiletsCleanSlowTwo from '../img/json/NEWFLUSHB.json';

import nonHouseHoldBurst1a from '../img/json/final/NONHOUSEHOLDONE.json';
import nonHouseHoldBurst1aToxic from '../img/json/final/NONHOUSEHOLDONETOXIC.json';
import nonHouseHoldBurst1b from '../img/json/final/NONHOUSEHOLDONEB.json';
import nonHouseHoldBurst1bToxic from '../img/json/final/NONHOUSEHOLDONEBTOXIC.json';
import nonHouseHoldBurst2a from '../img/json/final/NONHOUSEHOLDTWOA.json';
import nonHouseHoldBurst2b from '../img/json/final/NONHOUSEHOLDTWOB.json';
import vanWashSeepage from '../img/json/final/NONHOUSEHOLDTHREE.json';

import nonHouseholdPipeBurstOneClean from '../img/json/NONLEAKONECLEAN.json';
// import nonHouseholdPipeBurstOneTainted from '../img/json/NONLEAKONETAINTED.json';
import nonHouseholdPipeBurstOneToxic from '../img/json/NONLEAKONETOXIC.json';
import nonHouseholdPipeBurstTwoClean from '../img/json/NONLEAKTWOCLEAN.json';
// import nonHouseholdPipeBurstTwoTainted from '../img/json/NONLEAKTWOTAINTED.json';
import nonHouseholdPipeBurstTwoToxic from '../img/json/NONLEAKTWOTOXIC.json';
import nonHouseholdPipeBurstThreeClean from '../img/json/NONLEAKTHREECLEAN.json';
// import nonHouseholdPipeBurstThreeTainted from '../img/json/NONLEAKTHREETAINTED.json';
import nonHouseholdPipeBurstThreeToxic from '../img/json/NONLEAKTHREETOXIC.json';

import { isCrossConnectionSelector, isBoreholePump8BARSelector, isStoptapOnSelector, isHydrantOnSelector, isNonHouseholdPipeBurstTwoSelector, isWashingMachineOnSelector, isOutsideTapRunningSelector, isShowerHeadImmersedSelector, reduceNonHouseholdCisternLevelSelector, isWaterOptimiserFittedSelector, isNonHouseholdPipeBurstSelector, isNonHouseholdNegativePressureSelector, isAgriculturalPipeBurstOneSelector, isHydrantHighPressureSelector, isHydrantUnsafeSelector, isHouseholdPipeBurstSelector, isSeepageSelector, waterSelectorFamily } from '../recoil/state.recoil';
import { WATER_COLORS, WATER_QUALITY } from '../config/constants';

const style = { position: 'absolute', top: 0, left: 0 };
const defaultOptions = {
    loop:             true,
    autoplay:         true, 
    rendererSettings: {},
};

const HydrantWater = () => {
    const isHydrantOn = useRecoilValue(isHydrantOnSelector);
    // const isHydrantUnsafe = useRecoilValue(isHydrantUnsafeSelector);
    // const isHydrantHighPressure = useRecoilValue(isHydrantHighPressureSelector);
    // const active = isHydrantUnsafe || isHydrantHighPressure ? true : false;
    const active = isHydrantOn ? true : false;
    // const { contaminated } = useRecoilValue(waterSelectorFamily('T1H'));
    // const isOutsideTapRunning = useRecoilValue(isOutsideTapRunningSelector);
    // const isShowerHeadImmersed = useRecoilValue(isShowerHeadImmersedSelector);
    // const isTainted = isOutsideTapRunning || isShowerHeadImmersed ? true : false;
    // const data = useMemo(() => contaminated ? isTainted ? [hydrantOneTainted,hydrantTwoTainted] : [hydrantOneToxic,hydrantTwoToxic] : [hydrantOneClean,hydrantTwoClean], [contaminated]);
    if (!active)
        return null;
    else
        return <>
            <Lottie 
                style={style}
                options={{ ...defaultOptions, animationData: hydrantOneTainted }}
                isStopped={false}
                isPaused={false}
            />
            <Lottie 
                style={style}
                options={{ ...defaultOptions, animationData: hydrantTwoTainted }}
                isStopped={false}
                isPaused={false}
            />
        </>
};

const PipeBurstWater = () => {
    const active = useRecoilValue(isHouseholdPipeBurstSelector);
    const { contaminated } = useRecoilValue(waterSelectorFamily('T2H'));
    const isOutsideTapRunning = useRecoilValue(isOutsideTapRunningSelector);
    const isShowerHeadImmersed = useRecoilValue(isShowerHeadImmersedSelector);
    const isTainted = isOutsideTapRunning || isShowerHeadImmersed ? true : false;
    const data = useMemo(() => contaminated ? isTainted ? [pipeBurstOneTainted,pipeBurstTwoTainted,pipeBurstThreeTainted] : [pipeBurstOneToxic,pipeBurstTwoToxic,pipeBurstThreeToxic] : [pipeBurstOneClean,pipeBurstTwoClean,pipeBurstThreeClean], [contaminated]);
    if (!active)
        return null;
    else
        return <>
            <Lottie 
                style={style}
                options={{ ...defaultOptions, animationData: data[0] }}
                isStopped={false}
                isPaused={false}
            />
            <Lottie 
                style={style}
                options={{ ...defaultOptions, animationData: data[1] }}
                isStopped={false}
                isPaused={false}
            />
            <Lottie 
                style={style}
                options={{ ...defaultOptions, animationData: data[2] }}
                isStopped={false}
                isPaused={false}
            />
        </>
};

const Seepage = () => {
    const active = useRecoilValue(isSeepageSelector);
    if (!active)
        return null;
    else
        return <Lottie 
            style={style}
            options={{ ...defaultOptions, animationData: seepage }}
            isStopped={false}
            isPaused={false}
        />
};

const WashingMachine = () => {
    const active = useRecoilValue(isWashingMachineOnSelector);
    if (!active)
        return null;
    else
        return <>
            <Lottie 
                style={style}
                options={{ ...defaultOptions, animationData: hf4 }}
                isStopped={false}
                isPaused={false}
            />
        </>
};

const AgriculturalPipeBurstOneWater = () => {
    const isAgriculturalPipeBurstOne = useRecoilValue(isAgriculturalPipeBurstOneSelector);
    const isCrossConnection = useRecoilValue(isCrossConnectionSelector);
    const isBoreholePump8BAR = useRecoilValue(isBoreholePump8BARSelector);
    const isStoptapOn = useRecoilValue(isStoptapOnSelector);

    const active = isAgriculturalPipeBurstOne || (isBoreholePump8BAR && isCrossConnection && isStoptapOn);

    const { contaminated } = useRecoilValue(waterSelectorFamily('T1A'));
    const data = useMemo(() => contaminated ? [agriculturalPipeBurstOneOneTainted,agriculturalPipeBurstOneTwoTainted,agriculturalPipeBurstOneThreeTainted] : [agriculturalPipeBurstOneOneClean,agriculturalPipeBurstOneTwoClean,agriculturalPipeBurstOneThreeClean], [contaminated]);
    if (!active)
        return null;
    else
        return <>
            <Lottie 
                style={style}
                options={{ ...defaultOptions, animationData: data[0] }}
                isStopped={false}
                isPaused={false}
            />
            <Lottie 
                style={style}
                options={{ ...defaultOptions, animationData: data[1] }}
                isStopped={false}
                isPaused={false}
            />
            <Lottie 
                style={style}
                options={{ ...defaultOptions, animationData: data[2] }}
                isStopped={false}
                isPaused={false}
            />
        </>
};

// const AgriculturalPipeBurstTwoWater = () => {
//     const active = useRecoilValue(isAgriculturalPipeBurstTwoSelector);
//     const { contaminated } = useRecoilValue(waterSelectorFamily('T2A'));
//     const data = useMemo(() => contaminated ? [agriculturalPipeBurstTwoOneTainted,agriculturalPipeBurstTwoTwoTainted,agriculturalPipeBurstTwoThreeTainted] : [agriculturalPipeBurstTwoOneClean,agriculturalPipeBurstTwoTwoClean,agriculturalPipeBurstTwoThreeClean], [contaminated]);
//     if (!active)
//         return null;
//     else
//         return <>
//             <Lottie 
//                 style={style}
//                 options={{ ...defaultOptions, animationData: data[0] }}
//                 isStopped={false}
//                 isPaused={false}
//             />
//             <Lottie 
//                 style={style}
//                 options={{ ...defaultOptions, animationData: data[1] }}
//                 isStopped={false}
//                 isPaused={false}
//             />
//             <Lottie 
//                 style={style}
//                 options={{ ...defaultOptions, animationData: data[2] }}
//                 isStopped={false}
//                 isPaused={false}
//             />
//         </>
// };

// const NonHouseholdSeepage = () => {
//     const active = useRecoilValue(isNonHouseholdSeepageSelector);
//     if (!active)
//         return null;
//     else
//         return <Lottie 
//             style={style}
//             options={{ ...defaultOptions, animationData: vanWashSeepage }}
//             isStopped={false}
//             isPaused={false}
//         />
// };

const NonHouseholdToilets = () => {
    const active = useRecoilValue(isNonHouseholdNegativePressureSelector);
    const isWaterOptimiserFitted = useRecoilValue(isWaterOptimiserFittedSelector);
    const reduceCisternLevel = useSetRecoilState(reduceNonHouseholdCisternLevelSelector);
    return <>
            <Lottie 
                style={style}
                options={{ ...defaultOptions, animationData: isWaterOptimiserFitted ? toiletsCleanSlowOne : toiletsClean }}
                isStopped={active}
                isPaused={active}
                eventListeners={[
                    {
                        eventName: 'loopComplete',
                        callback: reduceCisternLevel,
                    }
                ]}
            />
        </>
};

const NonHouseholdPipeBurstWater = () => {
    const active = useRecoilValue(isNonHouseholdPipeBurstSelector);
    const { contaminated } = useRecoilValue(waterSelectorFamily('T1N'));
    const data = useMemo(() => contaminated ? 
        nonHouseHoldBurst1aToxic : 
        nonHouseHoldBurst1a, 
    [contaminated]);
    if (!active)
        return null;
    else
        return <>
            <Lottie 
                style={style}
                options={{ ...defaultOptions, animationData: data }}
                isStopped={false}
                isPaused={false}
            />
        </>
};

const NonHouseholdPipeBurstTwoWater = () => {
    const active = useRecoilValue(isNonHouseholdPipeBurstTwoSelector);
    const { contaminated } = useRecoilValue(waterSelectorFamily('T1N_2'));
    const data = useMemo(() => contaminated ? 
        nonHouseHoldBurst1bToxic : 
        nonHouseHoldBurst1b, 
    [contaminated]);
    if (!active)
        return null;
    else
        return <>
            <Lottie 
                style={style}
                options={{ ...defaultOptions, animationData: data }}
                isStopped={false}
                isPaused={false}
            />
        </>
};

const LottieAnimations = ({ scenario }) => {
    return <>
        {parseInt(scenario) === 1 && <>
            <WashingMachine />
            <HydrantWater />
            <PipeBurstWater />
            <Seepage />
        </>}
        {parseInt(scenario) === 2 && <>
            <AgriculturalPipeBurstOneWater />
            {/* <AgriculturalPipeBurstTwoWater /> */}
        </>}
        {parseInt(scenario) === 3 && <>
            {/* <NonHouseholdSeepage /> */}
            <NonHouseholdPipeBurstWater />
            <NonHouseholdPipeBurstTwoWater />
            <NonHouseholdToilets />
        </>}
    </>
};

export default LottieAnimations;
