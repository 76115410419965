import { useCallback, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { validRoutesAtom, showResetButtonAtom, multiChoiceOpenAtom, scenarioHotspotsSelectorFamily, scenarioHotspotIdsSelectorFamily, isSinkFilterFittedSelector, isHydrantUnsafeSelector, isHydrantHighPressureSelector, isHouseholdNegativePressureSelector, isBathTapRunningSelector, isContaminatedPipeSelector, actionRoutesAtom, currentRouteAtom, currentRouteIdxSelector, resetActionRouteSelector, isStopCockOpenSelector, isNonHouseholdBoundaryBoxOpenSelector, isHouseholdBoundaryBoxOpenSelector } from '../recoil/state.recoil';
import { HOTSPOTS } from '../config/constants';

const getDefaultValue           = id => HOTSPOTS.find(_ => _.id === id).value;
const getBathTap                = _ => _.id === 'T11H';
const getAllExceptBathTap       = _ => _.id !== 'T11H';
const getAllWithMenus           = _ => _.multiChoice === true;
const getAllWithoutMenus        = _ => _.multiChoice === false;
const resetToDefault            = _ => ({ ..._, value: getDefaultValue(_.id) });

export const useActionRoutes = scenario => {
    const [ hotspots, setHotspots ] = useRecoilState(scenarioHotspotsSelectorFamily(parseInt(scenario)));
    const multiChoiceOpen = useRecoilValue(multiChoiceOpenAtom);
    const actionRoutes = useRecoilValue(actionRoutesAtom);
    const [ currentRoute, setCurrentRoute ] = useRecoilState(currentRouteAtom);
    const currentRouteIdx = useRecoilValue(currentRouteIdxSelector);
    const [ validRoutes, setValidRoutes ] = useRecoilState(validRoutesAtom);
    const resetActionRoute = useSetRecoilState(resetActionRouteSelector);
    const clickHandler = useCallback((id, val) => {
        const hotspot = hotspots.find(_ => _.id === id);
        
        const actionRoutesFilter = route => {
            let valid = false;
            if (
                    route[0].id === id &&
                    (
                        Array.isArray(route[0].state) && route[0].state.find(_ => _ === val) || 
                        route[0].state === val
                    )
                )
                valid = true;

            return valid;
        };
        
        // console.clear()
        // console.log('id', id)
        // console.log('val', val)
        // console.log('hotspot', hotspot)
        // console.log('actionRoutes', actionRoutes)
        // console.log('currentRoute', currentRoute)
        // console.log('currentRoute.length', currentRoute.length)
        // console.log('currentRouteIdx', currentRouteIdx)
        
        // // console.log('hotspots', hotspots)
        // // console.log('hotspots', hotspots.filter(({ reverseActive, multiChoice, value }) => {
        // //     if (reverseActive && value === false)
        // //         return true;
        // //     if (!reverseActive && value === true)
        // //         return true;
        // //     if (multiChoice && multiChoiceOpen === id)
        // //         return true;
        // //     return false;
        // // }))

        const newCurrentRoute = [ ...currentRoute, id ];
        const newCurrentRouteIdx = newCurrentRoute.length - 1;

        // console.log('newCurrentRoute', newCurrentRoute)
        // console.log('newCurrentRoute.length', newCurrentRoute.length)
        // console.log('newCurrentRouteIdx', newCurrentRouteIdx)

        setCurrentRoute(newCurrentRoute);

        // console.log('validRoutes', validRoutes)

        // // const newValidRoutes = newCurrentRoute.reduce((arr, val, idx) => {
        // //     // if (validRoutes.filter(_ => _[idx].id === val).length)
        // //     //     arr.push(val);
        // //     // return arr;
        // //     return arr.filter(_ => _[idx].id === val);
        // // }, [ ...validRoutes ]);

        // // console.log('newValidRoutes', newValidRoutes)

        // // const newValidRoutes = validRoutes.reduce((arr, val, idx) => {
        // //     if (val[newCurrentRouteIdx] && val[newCurrentRouteIdx].id === id)
        // //         arr.push(val);
        // //     return arr;
        // // }, []);
        
        // // console.log('newValidRoutes', newValidRoutes)

        // // setValidRoutes(newValidRoutes);

        // // const visibleHotspots = [ ...new Set(newValidRoutes.reduce((arr, val, idx) => {
        // //     if (val[newCurrentRouteIdx + 1])
        // //         arr.push(val[newCurrentRouteIdx + 1].id);
        // //     if (Array.isArray(val[0].state))
        // //         arr.push(val[0].id);
        // //     return arr;
        // // }, [])) ];

        // // console.log('visibleHotspots', visibleHotspots);

        // // console.log('validRoutes', validRoutes.filter(route => route[newCurrentRouteIdx].id === id))
        // // setValidRoutes();

        // // hotspot has been clicked
        //     // id
        //     // val

        // // check for id in current route
        //     // not found
        //         // check if part of a valid route
        //             // map actionRoutes and filter where currentRouteIdx === id



        // // if no current route exists
        //     // if id + val combination exists in actionRoutes
        //     // if id + val combination exists in actionRoutes
        // // else if current route exists



        // // check if hotspot is part of current route
        //     // is not part of route
        //         // add to current route
        //         // idx++
        //     // is part of route



    }, [hotspots, actionRoutes, currentRoute, multiChoiceOpen]);

    return { actionRoutes, currentRoute, setCurrentRoute, currentRouteIdx, resetActionRoute, routeClickHandler: clickHandler };
};

const useHotspots = scenario => {
    const [ last, setLast ] = useState(null);
    const [ menuActive, setMenuActive ] = useRecoilState(multiChoiceOpenAtom);
    const [ hotspots, setHotspots ] = useRecoilState(scenarioHotspotsSelectorFamily(parseInt(scenario)));
    const hotspotIds = useRecoilValue(scenarioHotspotIdsSelectorFamily(parseInt(scenario)));
    const isContaminatedPipe = useRecoilValue(isContaminatedPipeSelector);
    const isHouseholdNegativePressure = useRecoilValue(isHouseholdNegativePressureSelector);
    const isHydrantHighPressure = useRecoilValue(isHydrantHighPressureSelector);
    const isHydrantUnsafe = useRecoilValue(isHydrantUnsafeSelector);
    const isSinkFilterFitted = useRecoilValue(isSinkFilterFittedSelector);
    const isBathTapRunning = useRecoilValue(isBathTapRunningSelector);
    const showResetButton = useSetRecoilState(showResetButtonAtom);
    const isBathTapContamination = isHouseholdNegativePressure && isBathTapRunning;

    const isStopCockOpen = useRecoilValue(isStopCockOpenSelector)
    const isHouseholdBoundaryBoxOpen = useRecoilValue(isHouseholdBoundaryBoxOpenSelector)

    const { routeClickHandler } = useActionRoutes(scenario);

    // const actionRoutes = useRecoilValue(actionRoutesAtom);
    // const [ currentRoute, setCurrentRoute ] = useRecoilState(currentRouteAtom);
    // const currentRouteIdx = useRecoilValue(currentRouteIdxSelector);
    // const resetActionRoute = useSetRecoilState(resetActionRouteSelector);
    
    // console.log('currentRoute', currentRoute, currentRouteIdx)

    const isActive = id => hotspots.find(_ => _.id === id).value;

    const clickHandler = id => {
        const hotspot = hotspots.find(_ => _.id === id);
        const reverseActive = hotspot.reverseActive ? true : false;
        const active = hotspot.value;
        const exclusive = hotspot.exclusive;
        const on = !reverseActive ? true : false;
        const off = !on;
        const multiChoice = hotspot.multiChoice;
        const getAllExceptCurrent = _ => _.id !== id;
        const getAllInGroup = _ => _.exclusive === exclusive;
        const getAllNotInGroup = _ => _.exclusive !== exclusive;
        const getMenuActive = _ => _.id === menuActive;
        const getAllExceptMenuActive = _ => _.id !== menuActive;
        const isActive = active === on;
        const isExclusive = exclusive !== null;
        const isAllExclusive = exclusive === 'all';
        const isMenuInGroupOpen = hotspots.filter(getAllInGroup).filter(getAllWithMenus).length;
        const resetMultiChoiceToDefault = _ => ({ ..._, selected: _.default, timestamp: Date.now() });
        const createHotspotValueObj = value => ({ ...hotspot, value, timestamp: Date.now() });
        // const selected = hotspot?.selected || active;

        setLast(id);

        showResetButton(true);

        // id
        // const availableRoutes = [];
        // for (let i = 0, len = currentRoute.length; i < len; i++) {
        //   actionRoutes.filter(_ => _.[i].id === )
        //   availableRoutes.push();
        // }

        // setCurrentRoute(route => [ ...route, { id, state: selected } ]);
        // const availableRoutes = actionRoutes.map(_ => _[0]);

        // console.log('availableRoutes', availableRoutes)
        // console.log('selected', selected)

        // state funcs
        const open = state => ([
            ...(isBathTapContamination ?
                [ ...state.filter(getAllExceptCurrent).filter(getAllNotInGroup).filter(getAllExceptBathTap), { ...state.find(getBathTap), value: getDefaultValue('T11H') } ] :
                [ ...state.filter(getAllExceptCurrent).filter(getAllNotInGroup) ]),
            ...state.filter(getAllExceptCurrent).filter(getAllInGroup).map(resetToDefault),
            createHotspotValueObj(off) // turn off because hotspot is multi choice
        ]);
        const openAnother = state => {
            return [
                ...state.filter(getAllNotInGroup),
                ...state.filter(getAllInGroup).filter(getAllWithoutMenus),
                // ...state.filter(getAllInGroup).filter(getAllWithMenus).map(resetMultiChoiceToDefault),
                ...state.filter(getAllInGroup).filter(getAllWithMenus),
            ];
        };
        const close = state => {
            if (menuActive) {
                const menuHotspot = state.find(getMenuActive);
                return [
                    ...state.filter(getAllExceptMenuActive),
                    // { ...menuHotspot, selected: menuHotspot.default },
                    { ...menuHotspot },
                ];
            }
            return state;
        };
        const activateExclusive = state => ([ ...state.filter(getAllExceptCurrent).filter(getAllNotInGroup), ...hotspots.filter(getAllExceptCurrent).filter(getAllInGroup).map(resetToDefault), createHotspotValueObj(on) ]);
        const activateAllExclusive = state => ([ ...state.filter(getAllExceptCurrent).map(resetToDefault), createHotspotValueObj(on) ]);
        const activate = state => ([ ...state.filter(getAllExceptCurrent), createHotspotValueObj(on) ]);
        const deactivate = state => ([ ...state.filter(getAllExceptCurrent), createHotspotValueObj(off) ]);
        const resetHydrant = state => ([ ...state.filter(_ => _.id !== "T1H"), { ...state.find(_ => _.id === "T1H"), value: false, selected: 'Safe' } ]);
        // const resetSinkFilter = state => ([ ...state.filter(_ => _.id !== "T8H"), { ...state.find(_ => _.id === "T8H"), value: false, selected: 'Non-Maintained' } ]);
        // const resetHydrantAndSinkFilter = state => ([ ...state.filter(_ => _.id !== "T1H" && _.id !== "T8H"), { ...state.find(_ => _.id === "T1H"), value: false, selected: 'Safe' }, { ...state.find(_ => _.id === "T8H"), value: false, selected: 'Non-Maintained' } ]);
        
        // multi choice hotspot
        if (multiChoice) {
            routeClickHandler(id, hotspot.selected);

            // open menu, reset all other hotspots
            if (isAllExclusive) {
                setHotspots(state => ([
                    ...state.filter(getAllExceptCurrent).filter(_ => _.id !== "T1H" && _.id !== "T4H").map(resetToDefault),
                    { ...state.find(_ => _.id === "T1H"), value: false, selected: 'Safe' },
                    { ...state.find(_ => _.id === "T4H") },
                    createHotspotValueObj(on)
                ]));
            } else {
                // setHotspots(state => ([
                //     ...state.filter(_ => _.id !== "T8H"),
                //     { ...state.find(_ => _.id === "T8H"), value: false, selected: 'Non-Maintained' }
                // ]));
            }
            // no menu open, open menu id
            if (!menuActive) {
                setHotspots(open);
                setMenuActive(id);
            }
            // menu already open, close current menu and open menu id
            else if (menuActive !== id) {
                setHotspots(openAnother);
                setMenuActive(id);
            }
            // clicked currently active hotspot, close menu
            else {
                setHotspots(close);
                // setHotspots(state => ([
                //     ...state.filter(_ => _.id !== "T8H"),
                //     { ...state.find(_ => _.id === "T8H"), value: false, selected: 'Non-Maintained' }
                // ]));
                setMenuActive('');
            }
        }
        // boolean hotspot
        // activate
        else if (!isActive) {
            routeClickHandler(id, true);

            if (last === 'HOffRight' || last === 'AOffRight' || last === 'NOffRight') {
                // console.log('resetToDefault')
                setHotspots(state => state.map(resetToDefault));
                setMenuActive('');
            }
            if (isAllExclusive) {
                // console.log('isAllExclusive')
                setHotspots(activateAllExclusive);
                setHotspots(close);
                setMenuActive('');
            }
            // if exclusive, make all others inactive
            if (isExclusive) {
                // console.log('isExclusive')
                setHotspots(activateExclusive);
                if (isMenuInGroupOpen) {
                    // console.log('isMenuInGroupOpen')
                    setHotspots(close);
                    setMenuActive('');
                }

                // deactivate hydrant high pressure if another 
                // hotspot in group is active
                if ((isHydrantHighPressure || isHydrantUnsafe) && exclusive === 0) {
                    // console.log('(isHydrantHighPressure || isHydrantUnsafe) && exclusive === 0')
                    setHotspots(resetHydrant);
                    setMenuActive('');
                }
                // if (!isSinkFilterFitted) {
                //     setHotspots(resetSinkFilter);
                //     setMenuActive('');
                // }

                // if isBathTapContamination && isMenuOpen
                    // isMenuNewer
                        // reset bathTap to default
                    // else
                        // close menu
            }
            // if not exclusive, only set id
            else {
                // console.log('only set id')
                setHotspots(activate);
            }
        }
        // deactivate
        else {
            routeClickHandler(id, false);

            // console.log('deactivate')
            setHotspots(deactivate);
        }

        // // flow
        // if (!isStopCockOpen) {
        //     console.log('flow !isStopCockOpen')
        //     setHotspots(state => ([
        //         { ...state.find(_ => _.id === "T6H") },
        //         { ...state.find(_ => _.id === "T10H") },
        //         { ...state.find(_ => _.id === "T11H") },
        //         ...state.filter(_ => _.id !== "T6H" && _.id !== "T10H" && _.id !== "T11H").map(resetToDefault),
        //         createHotspotValueObj(off),
        //     ]))
        // }
        // else if (!isHouseholdBoundaryBoxOpen) {
        //     console.log('flow !isHouseholdBoundaryBoxOpen')
        //     setHotspots(state => ([
        //         { ...state.find(_ => _.id === "T3H") },
        //         { ...state.find(_ => _.id === "T10H") },
        //         { ...state.find(_ => _.id === "T11H") },
        //         ...state.filter(_ => _.id !== "T3H" && _.id !== "T10H" && _.id !== "T11H").map(resetToDefault),
        //         createHotspotValueObj(off),
        //     ]))
        // }
    };
    const clickHandlers = hotspotIds.map(id => () => clickHandler(id));

    return { hotspotIds, isActive, clickHandlers };
};

export default useHotspots;