import { useEffect, useMemo, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { ClipPath } from './svg.component';
import { scenarioPipeIdsSelectorFamily, pipeSelectorFamily, hotspotSelectorFamily } from '../recoil/state.recoil';
import { WATER_COLORS, WATER_QUALITY } from '../config/constants';

const PipeSegmentStyles = styled.path`
stroke: ${p => p.defaultContamination ? WATER_COLORS[p.defaultContamination] : p.isHose ? WATER_COLORS[WATER_QUALITY.TAINTED] : WATER_COLORS[WATER_QUALITY.CLEAN]};
${p => p.isvisible ? `display: block;` : `display: none;`}
`;

const PipeSegment = ({ id }) => {
    const [ segment, setSegment ] = useRecoilState(pipeSelectorFamily(id));
    const hotspot = useRecoilValue(hotspotSelectorFamily(segment.displayToggle));
    const isVisible = useMemo(() => {
        if (!segment.displayToggle)
            return true;
        else
            return hotspot.value === segment.displayToggleState;
    }, [hotspot]);
    const ref = useRef();

    useEffect(() => {
        const path = ref.current;
        if (path.style) {
            const length = parseInt(path.getTotalLength());
            setSegment({ ...segment, length });
        }
    }, []);

    return <ClipPath>
        <PipeSegmentStyles 
            ref={ref} 
            id={`${id}-pipe`} 
            isvisible={isVisible}
            {...segment}
        />
    </ClipPath>
};

const Pipes = ({ scenario }) => {
    const scenarioPipeIds = useRecoilValue(scenarioPipeIdsSelectorFamily(parseInt(scenario)));
    if (!scenarioPipeIds.length)
        return null;
    else
        return scenarioPipeIds.map(id => 
            <PipeSegment key={`Pipes/${id}`} id={id} />);
};

export default Pipes;