import { useRecoilValue } from 'recoil';
import { isStoptapOnSelector, isNonHouseholdDeadLegSelector, isNonHouseholdDCVSelector, isWashingMachineOnSelector, isWashingMachineSCVSelector, isShowerDCVSelector, isOutsideTapDCVSelector, isHydrantOnSelector, isBoreholePump8BARSelector, isHouseholdOffscreenRightSelector, isAgriculturalOffscreenLeftSelector, isAgriculturalOffscreenRightSelector, isNonHouseholdOffscreenLeftSelector, isNonHouseholdOffscreenRightSelector, isFillLoopWithoutDCVSelector, isFillLoopWithDCVSelector, isMaintainedFilterSelector, isNonMaintainedFilterSelector, flowAtom, waterAtom, contaminationAtomFamily, meterAtomFamily, multiChoiceOpenAtom, scenarioHotspotsSelectorFamily, isNonHouseholdBoundaryBoxOpenSelector, isNonHouseholdPipeBurstSelector, isNonHouseholdPipeBurstTwoSelector, isNonHouseholdNegativePressureSelector, isNonHouseholdSinkTapRunningSelector, isNonHouseholdIncorrectAirGapSelector, isNonHouseholdNoneFittedSelector, isNonHouseholdSCVFittedSelector, isNonHouseholdRPZFittedSelector, isNonHouseholdVanWashConnectedSelector, isWaterOptimiserFittedSelector, isAgriculturalNegativePressureSelector, isAgriculturalPipeBurstOneSelector, isAgriculturalIncorrectAirGapSelector, isCrossConnectionSelector, isBoreholePumpActiveSelector, isSinkTapRunningSelector, isHouseholdNegativePressureSelector, isHydrantOpenSelector, isHouseholdPipeBurstSelector, isHouseholdBoundaryBoxOpenSelector, isStopCockOpenSelector, isSeepageSelector, isSeepageProofPipeMaterialSelector, isContaminatedPipeSelector, isBathTapRunningSelector, isHouseholdIncorrectAirGapSelector, isSCVFittedSelector, isSinkFilterFittedSelector, isCleanSinkFilterSelector, isNonCompliantSinkFilterSelector, isContaminatedSinkFilterSelector, isFillLoopConnectedSelector, isOutsideTapRunningSelector, isShowerHeadImmersedSelector, isHydrantUnsafeSelector } from '../recoil/state.recoil';
import { WATER_COLORS, WATER_QUALITY } from '../config/constants';

const useWaterColour = () => {
    const isHouseholdOffscreenRight = useRecoilValue(isHouseholdOffscreenRightSelector);
    const isHouseholdNegativePressure = useRecoilValue(isHouseholdNegativePressureSelector);
    const isHydrantOn = useRecoilValue(isHydrantOnSelector);
    const isHydrantOpen = useRecoilValue(isHydrantOpenSelector);
    const isHydrantUnsafe = useRecoilValue(isHydrantUnsafeSelector);
    const isHouseholdPipeBurst = useRecoilValue(isHouseholdPipeBurstSelector);
    const isHouseholdBoundaryBoxOpen = useRecoilValue(isHouseholdBoundaryBoxOpenSelector);
    const isStopCockOpen = useRecoilValue(isStopCockOpenSelector);
    const isSeepage = useRecoilValue(isSeepageSelector);
    const isSeepageProofPipeMaterial = useRecoilValue(isSeepageProofPipeMaterialSelector);
    const isContaminatedPipe = useRecoilValue(isContaminatedPipeSelector);
    const isFillLoopConnected = useRecoilValue(isFillLoopConnectedSelector);
    const isFillLoopWithoutDCV = useRecoilValue(isFillLoopWithoutDCVSelector);
    const isFillLoopWithDCV = useRecoilValue(isFillLoopWithDCVSelector);
    const isSinkFilterFitted = useRecoilValue(isSinkFilterFittedSelector);
    const isSCVFitted = useRecoilValue(isSCVFittedSelector);
    const isSinkTapRunning = useRecoilValue(isSinkTapRunningSelector);
    const isBathTapRunning = useRecoilValue(isBathTapRunningSelector);
    const isOutsideTapRunning = useRecoilValue(isOutsideTapRunningSelector);
    const isHouseholdIncorrectAirGap = useRecoilValue(isHouseholdIncorrectAirGapSelector);
    const isOutsideTapDCV = useRecoilValue(isOutsideTapDCVSelector);
    const isShowerDCV = useRecoilValue(isShowerDCVSelector);
    const isWashingMachineSCV = useRecoilValue(isWashingMachineSCVSelector);
    const isWashingMachineOn = useRecoilValue(isWashingMachineOnSelector);

    const isAgriculturalOffscreenLeft = useRecoilValue(isAgriculturalOffscreenLeftSelector);
    const isAgriculturalOffscreenRight = useRecoilValue(isAgriculturalOffscreenRightSelector);
    const isAgriculturalNegativePressure = useRecoilValue(isAgriculturalNegativePressureSelector);
    const isAgriculturalPipeBurstOne = useRecoilValue(isAgriculturalPipeBurstOneSelector);
    const isAgriculturalIncorrectAirGap = useRecoilValue(isAgriculturalIncorrectAirGapSelector);
    const isCrossConnection = useRecoilValue(isCrossConnectionSelector);
    const isBoreholePumpActive = useRecoilValue(isBoreholePumpActiveSelector);
    const isBoreholePump8BAR = useRecoilValue(isBoreholePump8BARSelector);
    const isStoptapOn = useRecoilValue(isStoptapOnSelector);

    const isNonHouseholdOffscreenLeft = useRecoilValue(isNonHouseholdOffscreenLeftSelector);
    const isNonHouseholdOffscreenRight = useRecoilValue(isNonHouseholdOffscreenRightSelector);
    const isNonHouseholdBoundaryBoxOpen = useRecoilValue(isNonHouseholdBoundaryBoxOpenSelector);
    const isNonHouseholdPipeBurst = useRecoilValue(isNonHouseholdPipeBurstSelector);
    const isNonHouseholdPipeBurstTwo = useRecoilValue(isNonHouseholdPipeBurstTwoSelector);
    const isNonHouseholdNegativePressure = useRecoilValue(isNonHouseholdNegativePressureSelector);
    const isNonHouseholdSinkTapRunning = useRecoilValue(isNonHouseholdSinkTapRunningSelector);
    const isNonHouseholdIncorrectAirGap = useRecoilValue(isNonHouseholdIncorrectAirGapSelector);
    const isNonHouseholdNoneFitted = useRecoilValue(isNonHouseholdNoneFittedSelector);
    const isNonHouseholdSCVFitted = useRecoilValue(isNonHouseholdSCVFittedSelector);
    const isNonHouseholdRPZFitted = useRecoilValue(isNonHouseholdRPZFittedSelector);
    const isNonHouseholdVanWashConnected = useRecoilValue(isNonHouseholdVanWashConnectedSelector);
    const isWaterOptimiserFitted = useRecoilValue(isWaterOptimiserFittedSelector);
    const isNonHouseholdDCV = useRecoilValue(isNonHouseholdDCVSelector);
    const isNonHouseholdDeadLeg = useRecoilValue(isNonHouseholdDeadLegSelector);

    switch (true) {
        case isHouseholdOffscreenRight:
        case isAgriculturalOffscreenLeft:
        case isAgriculturalOffscreenRight:
        case isNonHouseholdOffscreenLeft:
        case isNonHouseholdOffscreenRight:
            return WATER_COLORS[WATER_QUALITY.TOXIC];
        case isHouseholdIncorrectAirGap && (!isStopCockOpen || !isHouseholdBoundaryBoxOpen) && isSinkTapRunning:
            return WATER_COLORS[WATER_QUALITY.PINK];
        case isBathTapRunning && !isShowerDCV && (!isStopCockOpen || !isHouseholdBoundaryBoxOpen) && isSinkTapRunning:
            return WATER_COLORS[WATER_QUALITY.ORANGE];
        case isOutsideTapRunning && !isOutsideTapDCV && isHouseholdPipeBurst:
        case !isAgriculturalIncorrectAirGap && isAgriculturalPipeBurstOne:
        case isCrossConnection && isStoptapOn:
            return WATER_COLORS[WATER_QUALITY.BROWN];
        case isWashingMachineOn && !isWashingMachineSCV && isSinkTapRunning:
        case isNonHouseholdDeadLeg && isNonHouseholdSinkTapRunning:
            return WATER_COLORS[WATER_QUALITY.YELLOW];
        case !isSinkFilterFitted:
            return WATER_COLORS[WATER_QUALITY.MUSTARD];
        case isFillLoopWithoutDCV && isSinkTapRunning:
        case !isNonHouseholdRPZFitted && isNonHouseholdSinkTapRunning:
        case !isNonHouseholdRPZFitted && isNonHouseholdPipeBurstTwo:
            return WATER_COLORS[WATER_QUALITY.RED];
        case isContaminatedPipe:
            return WATER_COLORS[WATER_QUALITY.GREY];
        case isSeepage && !isSeepageProofPipeMaterial:
            return WATER_COLORS[WATER_QUALITY.BLACK];
        case isHydrantOn:
            return WATER_COLORS[WATER_QUALITY.CREAM];
        case isNonHouseholdIncorrectAirGap && isNonHouseholdSinkTapRunning && !isNonHouseholdPipeBurst && !isNonHouseholdPipeBurstTwo:
        case isNonHouseholdIncorrectAirGap && isNonHouseholdPipeBurstTwo:
        case isNonHouseholdIncorrectAirGap && isNonHouseholdPipeBurst && !isNonHouseholdDCV:
            return WATER_COLORS[WATER_QUALITY.GREEN];
        default:
            return WATER_COLORS[WATER_QUALITY.CLEAN];
    }
};

export default useWaterColour;