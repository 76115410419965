import { createGlobalStyle } from 'styled-components';
import { Layer, ClipPath, Defs } from './svg.component';
import Household from './household.component';
import Agricultural from './agricultural.component';
import NonHousehold from './nonhousehold.component';
import { SCENARIOS, SCENARIO_COLORS } from '../config/constants';

const GlobalStyles = createGlobalStyle`
html,
body {
    background-color: ${p => SCENARIO_COLORS[p.scenario].BACKGROUND} !important;
}
`;

const Backdrop = ({ scenario }) => {
    const getScenario = () => {
        switch (parseInt(scenario)) {
            case SCENARIOS.HOUSEHOLD:
                return <Household />
            case SCENARIOS.AGRICULTURAL:
                return <Agricultural />
            case SCENARIOS.NON_HOUSEHOLD:
                return <NonHousehold />
            default:
                return <></>;
        }
    };
    return <>
        <GlobalStyles scenario={scenario} />
        <Layer>
            <ClipPath>
                {getScenario()}
            </ClipPath>
            <Defs />
        </Layer>
    </>
};
  
export default Backdrop;