import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isStoptapOnSelector, isNonHouseholdDeadLegSelector, isNonHouseholdDCVSelector, isWashingMachineOnSelector, isWashingMachineSCVSelector, isShowerDCVSelector, isOutsideTapDCVSelector, isHydrantOnSelector, isBoreholePump8BARSelector, isHouseholdOffscreenRightSelector, isAgriculturalOffscreenLeftSelector, isAgriculturalOffscreenRightSelector, isNonHouseholdOffscreenLeftSelector, isNonHouseholdOffscreenRightSelector, isFillLoopWithoutDCVSelector, isFillLoopWithDCVSelector, isMaintainedFilterSelector, isNonMaintainedFilterSelector, flowAtom, waterAtom, contaminationAtomFamily, meterAtomFamily, multiChoiceOpenAtom, scenarioHotspotsSelectorFamily, isNonHouseholdBoundaryBoxOpenSelector, isNonHouseholdPipeBurstSelector, isNonHouseholdPipeBurstTwoSelector, isNonHouseholdNegativePressureSelector, isNonHouseholdSinkTapRunningSelector, isNonHouseholdIncorrectAirGapSelector, isNonHouseholdNoneFittedSelector, isNonHouseholdSCVFittedSelector, isNonHouseholdRPZFittedSelector, isNonHouseholdVanWashConnectedSelector, isWaterOptimiserFittedSelector, isAgriculturalNegativePressureSelector, isAgriculturalPipeBurstOneSelector, isAgriculturalIncorrectAirGapSelector, isCrossConnectionSelector, isBoreholePumpActiveSelector, isSinkTapRunningSelector, isHouseholdNegativePressureSelector, isHydrantOpenSelector, isHouseholdPipeBurstSelector, isHouseholdBoundaryBoxOpenSelector, isStopCockOpenSelector, isSeepageSelector, isSeepageProofPipeMaterialSelector, isContaminatedPipeSelector, isBathTapRunningSelector, isHouseholdIncorrectAirGapSelector, isSCVFittedSelector, isSinkFilterFittedSelector, isCleanSinkFilterSelector, isNonCompliantSinkFilterSelector, isContaminatedSinkFilterSelector, isFillLoopConnectedSelector, isOutsideTapRunningSelector, isShowerHeadImmersedSelector, isHydrantUnsafeSelector } from '../recoil/state.recoil';
import { SCENARIOS, HOTSPOTS, FLOW, CONTAMINATION } from '../config/constants';

const getBathTapHFilterFunc               = _ => _.id === 'T11H';
const getAllExceptBathTapHFilterFunc      = _ => _.id !== 'T11H';
const getAllInExlcusiveGroupFilterFunc    = _ => _.exclusive === 1;
const getAllNotInExlcusiveGroupFilterFunc = _ => _.exclusive !== 1;
const getAllTrueFilterFunc                = _ => _ === true;
const resetWaterMapFunc                   = state => state.map(_ => ({ ..._, contaminated: false }));

const useHousehold = scenario => {
    const [ menuActive, setMenuActive ] = useRecoilState(multiChoiceOpenAtom);
    const [ hotspots, setHotspots ] = useRecoilState(scenarioHotspotsSelectorFamily(parseInt(scenario)));
    const setFlow = useSetRecoilState(flowAtom);
    const setWater = useSetRecoilState(waterAtom);
    const setContamination = useSetRecoilState(contaminationAtomFamily(parseInt(scenario)));
    const isHouseholdOffscreenRight = useRecoilValue(isHouseholdOffscreenRightSelector);
    const isHouseholdNegativePressure = useRecoilValue(isHouseholdNegativePressureSelector);
    const isHydrantOn = useRecoilValue(isHydrantOnSelector);
    const isHydrantOpen = useRecoilValue(isHydrantOpenSelector);
    const isHydrantUnsafe = useRecoilValue(isHydrantUnsafeSelector);
    const isHouseholdPipeBurst = useRecoilValue(isHouseholdPipeBurstSelector);
    const isHouseholdBoundaryBoxOpen = useRecoilValue(isHouseholdBoundaryBoxOpenSelector);
    const isStopCockOpen = useRecoilValue(isStopCockOpenSelector);
    const isSeepage = useRecoilValue(isSeepageSelector);
    const isSeepageProofPipeMaterial = useRecoilValue(isSeepageProofPipeMaterialSelector);
    const isContaminatedPipe = useRecoilValue(isContaminatedPipeSelector);

    const isFillLoopConnected = useRecoilValue(isFillLoopConnectedSelector);
    const isFillLoopWithoutDCV = useRecoilValue(isFillLoopWithoutDCVSelector);
    const isFillLoopWithDCV = useRecoilValue(isFillLoopWithDCVSelector);

    const isSinkFilterFitted = useRecoilValue(isSinkFilterFittedSelector);
    
    const isSCVFitted = useRecoilValue(isSCVFittedSelector);
    const isSinkTapRunning = useRecoilValue(isSinkTapRunningSelector);
    const isBathTapRunning = useRecoilValue(isBathTapRunningSelector);
    const isOutsideTapRunning = useRecoilValue(isOutsideTapRunningSelector);
    const isHouseholdIncorrectAirGap = useRecoilValue(isHouseholdIncorrectAirGapSelector);

    const isOutsideTapDCV = useRecoilValue(isOutsideTapDCVSelector);
    const isShowerDCV = useRecoilValue(isShowerDCVSelector);
    const isWashingMachineSCV = useRecoilValue(isWashingMachineSCVSelector);
    const isWashingMachineOn = useRecoilValue(isWashingMachineOnSelector);
    const closeMenuStateFunc = state => {
        if (menuActive) {
            const menuHotspot = state.find(_ => _.id === menuActive);
            return [
                ...state.filter(_ => _.id !== menuActive),
                { ...menuHotspot, selected: menuHotspot.default },
            ];
        }
        return state;
    };

    useEffect(() => {
        if (parseInt(scenario) === SCENARIOS.HOUSEHOLD) {
            const reset = () => {
                setHotspots(
                    HOTSPOTS.filter(_ => _.scenario === SCENARIOS.HOUSEHOLD)
                );
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_NORMAL,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([]);
            };
            reset();
            return () => reset();
        }
    }, [scenario]);

    useEffect(() => {
        if (parseInt(scenario) === SCENARIOS.HOUSEHOLD) {
            if (isHouseholdOffscreenRight) {
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].AGRICULTURAL_TO_BOUNDARY_BOX,
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].BOUNDARY_BOX_TO_STOPCOCK,
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].STOPCOCK_INTO_HOUSE,
                ]);
            }
            else if (isHouseholdIncorrectAirGap && (!isStopCockOpen || !isHouseholdBoundaryBoxOpen) && isSinkTapRunning) {
                setFlow(!isStopCockOpen ? [
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                ] : [
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                ]);
                setWater(resetWaterMapFunc);
                setContamination(!isStopCockOpen ? [
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].CISTERN
                ] : [
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].CISTERN, ...CONTAMINATION[SCENARIOS.HOUSEHOLD].STOPCOCK_TO_BOUNDARY_BOX
                ]);
            }
            else if (isBathTapRunning && !isShowerDCV && (!isStopCockOpen || !isHouseholdBoundaryBoxOpen) && isSinkTapRunning) {
                setFlow(!isStopCockOpen ? [
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                ] : [
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                ]);
                setWater(resetWaterMapFunc);
                // setContamination(!isStopCockOpen ? [
                //     ...CONTAMINATION[SCENARIOS.HOUSEHOLD].SHOWER_HEAD
                // ] : [
                //     ...CONTAMINATION[SCENARIOS.HOUSEHOLD].SHOWER_HEAD, ...CONTAMINATION[SCENARIOS.HOUSEHOLD].STOPCOCK_TO_BOUNDARY_BOX
                // ]);
                setContamination([...CONTAMINATION[SCENARIOS.HOUSEHOLD].SHOWER_HEAD]);
            }
            else if (isOutsideTapRunning && !isOutsideTapDCV && isHouseholdPipeBurst) {
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_REVERSE,
                    ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_REVERSE,
                    ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON_REVERSE,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].OUTSIDE_TAP_FULL,
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].STOPCOCK_TO_BOUNDARY_BOX,
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].BOUNDARY_BOX_TO_MAINS,
                ]);
            }
            else if (isWashingMachineOn && !isWashingMachineSCV && isSinkTapRunning) {
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].INCORRECT_SCV,
                    ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].WASHING_MACHINE_SCV,
                ]);
            }
            else if (!isSinkFilterFitted) {
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].SINK_FILTER,
                ]);
            }
            else if (isFillLoopWithoutDCV && isSinkTapRunning) {
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].FILL_LOOP,
                ]);
            }
            else if (isContaminatedPipe) {
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].CONTAMINATED_PIPE,
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].STOPCOCK_INTO_HOUSE,
                ]);
            }
            else if (isSeepage && !isSeepageProofPipeMaterial) {
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].SEEPAGE,
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].STOPCOCK_INTO_HOUSE,
                ]);
            }
            else if (isHydrantOn) {
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].HYDRANT_TO_STOPCOCK,
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].STOPCOCK_INTO_HOUSE,
                ]);
            }
            // default
            else {
                switch (true) {
                    case !isHouseholdBoundaryBoxOpen:
                        setFlow([
                            ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                        ]);
                        break;
                    case !isStopCockOpen:
                        setFlow([
                            ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                            ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                        ]);
                        break;
                    default:
                        setFlow([
                            ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                            ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                            ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_NORMAL,
                            ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON,
                        ]);
                        break;
                }
                setWater(resetWaterMapFunc);
                setContamination([]);
            }
            
            /*
            // contamination
            const contamination = [];
            const boundaryBoxToMains = () => {
                if (isHouseholdPipeBurst)
                    contamination.push(...CONTAMINATION[SCENARIOS.HOUSEHOLD].BOUNDARY_BOX_TO_PIPE_BURST);
                else if (isHydrantOn)
                    contamination.push(...CONTAMINATION[SCENARIOS.HOUSEHOLD].BOUNDARY_BOX_TO_HYDRANT);
            };
            const outside = arr => {
                contamination.push(...arr);
                boundaryBoxToMains();
                if (isStopCockOpen) {
                    contamination.push(...CONTAMINATION[SCENARIOS.HOUSEHOLD].STOPCOCK_INTO_HOUSE);
                }
            };
            const inside = arr => {
                contamination.push(...arr);
                if (isStopCockOpen) {
                    contamination.push(...CONTAMINATION[SCENARIOS.HOUSEHOLD].STOPCOCK_TO_BOUNDARY_BOX);
                    boundaryBoxToMains();
                }
            };
            */
            /*
            // adjust hotspots state
            const isNewerAction = hotspots.some(_ => {
                if (_.exclusive === 1) {
                    if ((_.reverseActive && !_.value) || (!_.reverseActive && _.value)) {
                        if (_.timestamp < hotspots.find(getBathTapHFilterFunc).timestamp)
                            return true;
                    }
                    else if (_.id === menuActive) {
                        if (_.timestamp < hotspots.find(getBathTapHFilterFunc).timestamp)
                            return true;
                    }
                }
                return false;
            });
            */
            /*
            if (isHouseholdOffscreenRight) {
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].AGRICULTURAL_TO_BOUNDARY_BOX,
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].BOUNDARY_BOX_TO_STOPCOCK,
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].STOPCOCK_INTO_HOUSE,
                ]);
                // setMeter(resetMeterObj);
            }
            else if (isHouseholdNegativePressure) {
                if (isShowerHeadImmersed && !isNewerAction) {
                    // reset bath tap
                    if (hotspots.filter(getAllInExlcusiveGroupFilterFunc).map(_ => _.reverseActive ? !_.value : _.value).some(getAllTrueFilterFunc))
                        setHotspots([
                            ...hotspots.filter(getAllExceptBathTapHFilterFunc),
                            { ...hotspots.find(getBathTapHFilterFunc), value: false }
                        ]);
                }
                else if (isShowerHeadImmersed && isNewerAction) {
                    // reset all in exclusivity group except bath tap
                    setHotspots([
                        ...hotspots.filter(getAllNotInExlcusiveGroupFilterFunc).filter(getAllExceptBathTapHFilterFunc), 
                        ...hotspots.filter(getAllInExlcusiveGroupFilterFunc).filter(getAllExceptBathTapHFilterFunc).map(_ => ({ ..._, value: HOTSPOTS.find(obj => obj.id === _.id).value })),
                        hotspots.find(getBathTapHFilterFunc)
                    ]);
                    if (menuActive) {
                        setHotspots(closeMenuStateFunc);
                        setMenuActive('');
                    }
                }

                // flow
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD][isStopCockOpen ? 'CONNECTION_REVERSE' : 'CONNECTION_NORMAL'],
                    ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_REVERSE,
                    ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON_REVERSE,
                ]);

                // water
                setWater(resetWaterMapFunc);

                switch (true) {
                    // case isSeepage && !isSeepageProofPipeMaterial:
                    //     contamination.push(...CONTAMINATION[SCENARIOS.HOUSEHOLD].T4H_TO_STOPCOCK);
                    //     contamination.push(...CONTAMINATION[SCENARIOS.HOUSEHOLD].STOPCOCK_INTO_HOUSE);
                    //     contamination.push(...CONTAMINATION[SCENARIOS.HOUSEHOLD].T4H_TO_BOUNDARY_BOX);
                    //     boundaryBoxToMains();
                    //     break;
                    case isFillLoopWithoutDCV:
                        inside(CONTAMINATION[SCENARIOS.HOUSEHOLD].FILL_LOOP);
                        // setMeter({ pressure: 0, flow: 0, conductivity: 1 });
                        break;
                    case !isSCVFitted:
                        inside(CONTAMINATION[SCENARIOS.HOUSEHOLD].SCV);
                        break;
                    case isHouseholdIncorrectAirGap:
                        inside(CONTAMINATION[SCENARIOS.HOUSEHOLD].CISTERN);
                        break;
                    case isOutsideTapRunning && !isOutsideTapDCV:
                        inside(CONTAMINATION[SCENARIOS.HOUSEHOLD].OUTSIDE_TAP);
                        break;
                    case isShowerHeadImmersed:
                        if (isShowerDCV)
                            contamination.push(...CONTAMINATION[SCENARIOS.HOUSEHOLD].SHOWER_HEAD_WITH_DCV);
                        else
                            inside(CONTAMINATION[SCENARIOS.HOUSEHOLD].SHOWER_HEAD);
                        // setMeter({ pressure: 0, flow: 0, conductivity: 0.75 });
                        break;
                    // case isNonMaintainedFilter && isSinkTapRunning && isHouseholdPipeBurst:
                    case !isSinkFilterFitted && isSinkTapRunning && isHouseholdPipeBurst:
                        contamination.push(...CONTAMINATION[SCENARIOS.HOUSEHOLD].SINK_FILTER);
                        contamination.push(...CONTAMINATION[SCENARIOS.HOUSEHOLD].SINK_FILTER_TO_STOPCOCK);
                        contamination.push(...CONTAMINATION[SCENARIOS.HOUSEHOLD].STOPCOCK_TO_BOUNDARY_BOX);
                        contamination.push(...CONTAMINATION[SCENARIOS.HOUSEHOLD].BOUNDARY_BOX_TO_PIPE_BURST);
                        // setMeter({ pressure: 0, flow: 0, conductivity: 0.75 });
                        break;
                    default:
                        // setMeter({ pressure: 0.5, flow: 0, conductivity: 1 });
                        break;
                }
                
                setContamination(contamination);
            }
            else if (isSeepage && !isSeepageProofPipeMaterial && isSinkTapRunning) {
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].T4H_TO_STOPCOCK,
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].STOPCOCK_TO_SINK_TAP,
                ]);
                // setMeter({ pressure: 0.5, flow: 0.5, conductivity: 1 });
            }
            else if (isContaminatedPipe && isSinkTapRunning) {
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].T5H_TO_STOPCOCK,
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].STOPCOCK_TO_SINK_TAP,
                ]);
                // setMeter(resetMeterObj);
            }
            else if (!isSinkFilterFitted && isSinkTapRunning) {
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].SINK_FILTER,
                ]);
                // setMeter({ pressure: 0.5, flow: 0.5, conductivity: 0.75 });
            }
            else if (isWashingMachineOn && !isWashingMachineSCV && isSinkTapRunning) {
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].INCORRECT_SCV,
                    ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].WASHING_MACHINE_SCV,
                ]);
                // setMeter(resetMeterObj);
            }
            else if (isHouseholdIncorrectAirGap && isSinkTapRunning) {
                setFlow([
                    ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_NORMAL,
                    ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.HOUSEHOLD].CISTERN_TO_SINK_TAP,
                ]);
                // setMeter({ pressure: 0.5, flow: 0.5, conductivity: 0.75 });
            }
            else {
                switch (true) {
                    case !isHouseholdBoundaryBoxOpen:
                        setFlow([
                            ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                        ]);
                        break;
                    case !isStopCockOpen:
                        setFlow([
                            ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                            ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                        ]);
                        break;
                    default:
                        setFlow([
                            ...FLOW[SCENARIOS.HOUSEHOLD].MAINS,
                            ...FLOW[SCENARIOS.HOUSEHOLD].CONNECTION_NORMAL,
                            ...FLOW[SCENARIOS.HOUSEHOLD].HOUSE_NORMAL,
                            ...FLOW[SCENARIOS.HOUSEHOLD].FILL_LOOP_ON,
                        ]);
                        break;
                }
                setWater(resetWaterMapFunc);
                setContamination([]);
                // setMeter(resetMeterObj);
            }

            // // meter
            // if ((isHouseholdPipeBurst && isHouseholdIncorrectAirGap) || (isHydrantOn && isHouseholdIncorrectAirGap)) {
            //     setMeter({ pressure: 0.0, flow: 0.0, conductivity: 1.0 })
            // }
            // else if ((isHouseholdPipeBurst && isOutsideTapRunning && !isOutsideTapDCV) || (isHydrantOn && isOutsideTapRunning && !isOutsideTapDCV)) {
            //     setMeter({ pressure: 0.0, flow: 0.0, conductivity: 1.0 })
            // }
            // else if (isSeepage && !isSeepageProofPipeMaterial && isSinkTapRunning) {
            //     setMeter({ pressure: 0.5, flow: 0.5, conductivity: 1.0 })
            // }
            // else if (isContaminatedPipe && isSinkTapRunning) {
            //     setMeter({ pressure: 0.5, flow: 0.5, conductivity: 1.0 })
            // }
            // else if (isHouseholdPipeBurst && isFillLoopWithoutDCV) {
            //     setMeter({ pressure: 0.0, flow: 0.0, conductivity: 0.5 })
            // }
            // else if ((isHouseholdPipeBurst && isBathTapRunning && !isShowerDCV) || (isHydrantOn && isBathTapRunning && !isShowerDCV)) {
            //     setMeter({ pressure: 0.0, flow: 0.0, conductivity: 1.0 })
            // }
            // else if (isWashingMachineOn && isSinkTapRunning) {
            //     setMeter({ pressure: 0.0, flow: 0.0, conductivity: 1.0 })
            // }
            // else if ((!isStopCockOpen && isHouseholdIncorrectAirGap && isSinkTapRunning) || (!isHouseholdBoundaryBoxOpen && isHouseholdIncorrectAirGap && isSinkTapRunning)) {
            //     setMeter({ pressure: 0.0, flow: 0.0, conductivity: 1.0 })
            // }
            // else if (isNonMaintainedFilter) {
            //     setMeter({ pressure: 0.0, flow: 0.0, conductivity: 1.0 })
            // }
            // else if ((isHouseholdPipeBurst && isBathTapRunning && !isShowerDCV) || (isHydrantOn && isBathTapRunning && !isShowerDCV)) {
            //     setMeter({ pressure: 0.0, flow: 0.0, conductivity: 1.0 })
            // }
            // else {
            //     setMeter({ pressure: 0.5, flow: 0.5, conductivity: 0.5 })
            // }
            */
        }
    }, [isWashingMachineOn, isWashingMachineSCV, isShowerDCV, isOutsideTapDCV, isHydrantOn, isHouseholdOffscreenRight, isFillLoopWithoutDCV, isFillLoopWithDCV, isHouseholdNegativePressure, isHydrantOpen, isHydrantUnsafe, isHouseholdPipeBurst, isHouseholdBoundaryBoxOpen, isStopCockOpen, isSeepage, isSeepageProofPipeMaterial, isContaminatedPipe, isFillLoopConnected, isSinkFilterFitted, isSCVFitted, isSinkTapRunning, isBathTapRunning, isOutsideTapRunning, isHouseholdIncorrectAirGap]);
};

const useAgricultural = scenario => {
    const [ menuActive, setMenuActive ] = useRecoilState(multiChoiceOpenAtom);
    const setHotspots = useSetRecoilState(scenarioHotspotsSelectorFamily(parseInt(scenario)));
    const setFlow = useSetRecoilState(flowAtom);
    const setWater = useSetRecoilState(waterAtom);
    const setContamination = useSetRecoilState(contaminationAtomFamily(parseInt(scenario)));
    const setMeter = useSetRecoilState(meterAtomFamily(parseInt(scenario)));
    const isAgriculturalOffscreenLeft = useRecoilValue(isAgriculturalOffscreenLeftSelector);
    const isAgriculturalOffscreenRight = useRecoilValue(isAgriculturalOffscreenRightSelector);
    const isAgriculturalNegativePressure = useRecoilValue(isAgriculturalNegativePressureSelector);
    const isAgriculturalPipeBurstOne = useRecoilValue(isAgriculturalPipeBurstOneSelector);
    // const isAgriculturalPipeBurstTwo = useRecoilValue(isAgriculturalPipeBurstTwoSelector);
    // const isAgriculturalBoundaryBoxOpen = useRecoilValue(isAgriculturalBoundaryBoxOpenSelector);
    const isAgriculturalIncorrectAirGap = useRecoilValue(isAgriculturalIncorrectAirGapSelector);
    const isCrossConnection = useRecoilValue(isCrossConnectionSelector);
    const isBoreholePumpActive = useRecoilValue(isBoreholePumpActiveSelector);
    const isBoreholePump8BAR = useRecoilValue(isBoreholePump8BARSelector);
    const isStoptapOn = useRecoilValue(isStoptapOnSelector);
    const closeMenuStateFunc = state => {
        if (menuActive) {
            const menuHotspot = state.find(_ => _.id === menuActive);
            return [
                ...state.filter(_ => _.id !== menuActive),
                { ...menuHotspot, selected: menuHotspot.default },
            ];
        }
        return state;
    };
    // const resetMeterObj = { boreholePressure: 0.5, pressure: 0.5, flow: 0.5, conductivity: 0.5 };
    // const resetMeterObj = useMemo(() => ({ boreholePressure: isBoreholePumpActive ? 1 : 0.5, pressure: 0.5, flow: 0.5, conductivity: 0.5 }), [isBoreholePumpActive]);
    const resetMeterObj = useMemo(() => ({ boreholePressure: isBoreholePump8BAR ? 1 : 0.5, pressure: 0.5, flow: 0.5, conductivity: 0.5 }), [isBoreholePump8BAR]);
    
    useEffect(() => {
        if (parseInt(scenario) === SCENARIOS.AGRICULTURAL) {
            const reset = () => {
                setHotspots(
                    HOTSPOTS.filter(_ => _.scenario === SCENARIOS.AGRICULTURAL)
                );
                setFlow([
                    ...FLOW[SCENARIOS.AGRICULTURAL].MAINS,
                    ...FLOW[SCENARIOS.AGRICULTURAL].FARM_NORMAL,
                    ...FLOW[SCENARIOS.AGRICULTURAL][isAgriculturalIncorrectAirGap ? 'TROUGH_NORMAL_HIGH' : 'TROUGH_NORMAL_LOW'],
                    ...FLOW[SCENARIOS.AGRICULTURAL].BOREHOLE,
                    ...FLOW[SCENARIOS.AGRICULTURAL][isCrossConnection ? 'CROSS_CONNECTION_ACTIVE' : 'CROSS_CONNECTION_INACTIVE'],
                ]);
                setMeter(resetMeterObj);
            };
            reset();
            return () => reset();
        }
    }, [scenario]);

    useEffect(() => {
        if (parseInt(scenario) === SCENARIOS.AGRICULTURAL) {
            if (isAgriculturalOffscreenLeft) {
                setFlow([
                    ...FLOW[SCENARIOS.AGRICULTURAL].MAINS,
                    ...FLOW[SCENARIOS.AGRICULTURAL].FARM_NORMAL,
                    ...FLOW[SCENARIOS.AGRICULTURAL].TROUGH_NORMAL_HIGH,
                    ...FLOW[SCENARIOS.AGRICULTURAL].BOREHOLE,
                    ...FLOW[SCENARIOS.AGRICULTURAL][isCrossConnection ? 'CROSS_CONNECTION_ACTIVE' : 'CROSS_CONNECTION_INACTIVE'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.AGRICULTURAL].HOUSEHOLD_OFFSCREEN,
                ]);
                setMeter({ boreholePressure: isBoreholePump8BAR ? 1 : 0.5, pressure: 0.5, flow: 0.5, conductivity: 0.5 })
            }
            else if (isAgriculturalOffscreenRight) {
                setFlow([
                    ...FLOW[SCENARIOS.AGRICULTURAL].MAINS,
                    ...FLOW[SCENARIOS.AGRICULTURAL].FARM_NORMAL,
                    ...FLOW[SCENARIOS.AGRICULTURAL].TROUGH_NORMAL_HIGH,
                    ...FLOW[SCENARIOS.AGRICULTURAL].BOREHOLE,
                    ...FLOW[SCENARIOS.AGRICULTURAL][isCrossConnection ? 'CROSS_CONNECTION_ACTIVE' : 'CROSS_CONNECTION_INACTIVE'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.AGRICULTURAL].NON_HOUSEHOLD_OFFSCREEN,
                ]);
                setMeter({ boreholePressure: isBoreholePump8BAR ? 1 : 0.5, pressure: 0.5, flow: 0.5, conductivity: 0.5 })
            }
            else if (!isAgriculturalIncorrectAirGap && isAgriculturalPipeBurstOne) {
                setFlow([
                    ...FLOW[SCENARIOS.AGRICULTURAL].MAINS,
                    ...FLOW[SCENARIOS.AGRICULTURAL].FARM_REVERSE,
                    ...FLOW[SCENARIOS.AGRICULTURAL].TROUGH_REVERSE_LOW,
                    ...FLOW[SCENARIOS.AGRICULTURAL].BOREHOLE,
                    ...FLOW[SCENARIOS.AGRICULTURAL][isCrossConnection ? 'CROSS_CONNECTION_ACTIVE' : 'CROSS_CONNECTION_INACTIVE'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.AGRICULTURAL].AIRGAP_TO_ALL,
                ]);
                setMeter({ boreholePressure: isBoreholePump8BAR ? 1 : 0.5, pressure: 0.0, flow: 0.0, conductivity: 1.0 })
            }
            else if (isBoreholePump8BAR && isCrossConnection && isStoptapOn) {
                setFlow([
                    ...FLOW[SCENARIOS.AGRICULTURAL].MAINS,
                    ...FLOW[SCENARIOS.AGRICULTURAL].FARM_NORMAL,
                    ...FLOW[SCENARIOS.AGRICULTURAL].TROUGH_NORMAL_HIGH,
                    ...FLOW[SCENARIOS.AGRICULTURAL].BOREHOLE,
                    ...FLOW[SCENARIOS.AGRICULTURAL].CROSS_CONNECTION_ACTIVE,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.AGRICULTURAL].STOPTAP_TO_ALL,
                    ...CONTAMINATION[SCENARIOS.AGRICULTURAL][!isAgriculturalIncorrectAirGap ? 'AIRGAP_LOW' : 'AIRGAP_HIGH'],
                ]);
                setMeter({ boreholePressure: 1.0, pressure: 1.0, flow: 0.0, conductivity: 1.0 })
            }
            else if (isCrossConnection && isStoptapOn) {
                setFlow([
                    ...FLOW[SCENARIOS.AGRICULTURAL].MAINS,
                    ...FLOW[SCENARIOS.AGRICULTURAL].FARM_NORMAL,
                    ...FLOW[SCENARIOS.AGRICULTURAL].TROUGH_NORMAL_HIGH,
                    ...FLOW[SCENARIOS.AGRICULTURAL].BOREHOLE,
                    ...FLOW[SCENARIOS.AGRICULTURAL].CROSS_CONNECTION_ACTIVE,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.AGRICULTURAL].STOPTAP_TO_PARLOUR,
                ]);
                setMeter({ boreholePressure: 0.5, pressure: 0.5, flow: 0.5, conductivity: 0.5 })
            }
            else {
                setFlow([
                    ...FLOW[SCENARIOS.AGRICULTURAL].MAINS,
                    ...FLOW[SCENARIOS.AGRICULTURAL].FARM_NORMAL,
                    ...FLOW[SCENARIOS.AGRICULTURAL][isAgriculturalIncorrectAirGap ? 'TROUGH_NORMAL_HIGH' : 'TROUGH_NORMAL_LOW'],
                    ...FLOW[SCENARIOS.AGRICULTURAL].BOREHOLE,
                    ...FLOW[SCENARIOS.AGRICULTURAL][isCrossConnection ? 'CROSS_CONNECTION_ACTIVE' : 'CROSS_CONNECTION_INACTIVE'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([]);
                setMeter({ boreholePressure: isBoreholePump8BAR ? 1 : 0.5, pressure: 0.5, flow: 0.5, conductivity: 0.5 })
            }

            // if (!isAgriculturalIncorrectAirGap && isAgriculturalPipeBurstOne) {
            // }
            // else {
            //     setMeter({ boreholePressure: isBoreholePump8BAR ? 1 : 0.5, pressure: 0.5, flow: 0.5, conductivity: 0.5 })
            // }

            /*
            const contamination = [];
            
            // if (!isAgriculturalBoundaryBoxOpen) {
            //     setFlow([
            //         ...FLOW[SCENARIOS.AGRICULTURAL].MAINS,
            //         ...FLOW[SCENARIOS.AGRICULTURAL].STATIC,
            //         ...FLOW[SCENARIOS.AGRICULTURAL][isAgriculturalIncorrectAirGap ? 'TROUGH_REVERSE_HIGH' : 'TROUGH_REVERSE_LOW'],
            //         ...FLOW[SCENARIOS.AGRICULTURAL].BOREHOLE,
            //         ...FLOW[SCENARIOS.AGRICULTURAL][isCrossConnection ? 'CROSS_CONNECTION_ACTIVE' : 'CROSS_CONNECTION_INACTIVE'],
            //     ]);
            // }
            */
            /*
            if (isAgriculturalOffscreenLeft) {

                // console.log('isAgriculturalOffscreenLeft');

                setFlow([
                    ...FLOW[SCENARIOS.AGRICULTURAL].MAINS,
                    ...FLOW[SCENARIOS.AGRICULTURAL].FARM_NORMAL,
                    ...FLOW[SCENARIOS.AGRICULTURAL].TROUGH_NORMAL_HIGH,
                    ...FLOW[SCENARIOS.AGRICULTURAL].BOREHOLE,
                    ...FLOW[SCENARIOS.AGRICULTURAL][isCrossConnection ? 'CROSS_CONNECTION_ACTIVE' : 'CROSS_CONNECTION_INACTIVE'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.AGRICULTURAL].HOUSEHOLD_OFFSCREEN,
                ]);
            }
            else if (isAgriculturalOffscreenRight) {
                setFlow([
                    ...FLOW[SCENARIOS.AGRICULTURAL].MAINS,
                    ...FLOW[SCENARIOS.AGRICULTURAL].FARM_NORMAL,
                    ...FLOW[SCENARIOS.AGRICULTURAL].TROUGH_NORMAL_HIGH,
                    ...FLOW[SCENARIOS.AGRICULTURAL].BOREHOLE,
                    ...FLOW[SCENARIOS.AGRICULTURAL][isCrossConnection ? 'CROSS_CONNECTION_ACTIVE' : 'CROSS_CONNECTION_INACTIVE'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.AGRICULTURAL].NON_HOUSEHOLD_OFFSCREEN,
                ]);
            }
            else if (isCrossConnection && isStoptapOn) {
                setFlow([
                    ...FLOW[SCENARIOS.AGRICULTURAL].MAINS,
                    ...FLOW[SCENARIOS.AGRICULTURAL].FARM_NORMAL,
                    ...FLOW[SCENARIOS.AGRICULTURAL][isAgriculturalIncorrectAirGap ? 'TROUGH_NORMAL_HIGH' : 'TROUGH_NORMAL_LOW'],
                    ...FLOW[SCENARIOS.AGRICULTURAL].BOREHOLE,
                    ...FLOW[SCENARIOS.AGRICULTURAL][isCrossConnection ? 'CROSS_CONNECTION_ACTIVE' : 'CROSS_CONNECTION_INACTIVE'],
                ]);
                setWater(resetWaterMapFunc);

                if (!isBoreholePump8BAR) {
                    setContamination([
                        ...CONTAMINATION[SCENARIOS.AGRICULTURAL].CROSS_CONNECTION_TO_BOUNDARY_BOX,
                        ...CONTAMINATION[SCENARIOS.AGRICULTURAL].CROSS_CONNECTION_TO_PARLOUR,
                        ...CONTAMINATION[SCENARIOS.AGRICULTURAL].BOUNDARY_BOX_JUNCTION_TO_FARM_HOUSE,
                    ]);
                }
                else {
                    setContamination([
                        ...CONTAMINATION[SCENARIOS.AGRICULTURAL].CROSS_CONNECTION_TO_BOUNDARY_BOX,
                        ...CONTAMINATION[SCENARIOS.AGRICULTURAL].CROSS_CONNECTION_TO_PARLOUR,
                        ...CONTAMINATION[SCENARIOS.AGRICULTURAL].BOUNDARY_BOX_JUNCTION_TO_FARM_HOUSE,
                        ...CONTAMINATION[SCENARIOS.AGRICULTURAL].BOUNDARY_BOX_JUNCTION_TO_BOUNDARY_BOX,
                        ...CONTAMINATION[SCENARIOS.AGRICULTURAL].BOUNDARY_BOX_TO_ALL_MAINS,
                        ...CONTAMINATION[SCENARIOS.AGRICULTURAL][isAgriculturalIncorrectAirGap ? 'AIRGAP_HIGH' : 'AIRGAP_LOW'],
                    ]);
                }
            }
            else if (isAgriculturalPipeBurstOne && !isAgriculturalIncorrectAirGap) {

                console.log('isAgriculturalPipeBurstOne', isAgriculturalPipeBurstOne);
                console.log('!isAgriculturalIncorrectAirGap', !isAgriculturalIncorrectAirGap);

                setFlow([
                    ...FLOW[SCENARIOS.AGRICULTURAL].MAINS,
                    // ...FLOW[SCENARIOS.AGRICULTURAL][!isAgriculturalBoundaryBoxOpen ? 'STATIC' : 'FARM_REVERSE'],
                    ...FLOW[SCENARIOS.AGRICULTURAL]['FARM_REVERSE'],
                    ...FLOW[SCENARIOS.AGRICULTURAL][isAgriculturalIncorrectAirGap ? 'TROUGH_REVERSE_HIGH' : 'TROUGH_REVERSE_LOW'],
                    ...FLOW[SCENARIOS.AGRICULTURAL].BOREHOLE,
                    ...FLOW[SCENARIOS.AGRICULTURAL][isCrossConnection ? 'CROSS_CONNECTION_ACTIVE' : 'CROSS_CONNECTION_INACTIVE'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination( CONTAMINATION[SCENARIOS.AGRICULTURAL].AIRGAP_TO_BURST_1 );
            }
            else {
                setFlow([
                    ...FLOW[SCENARIOS.AGRICULTURAL].MAINS,
                    // ...FLOW[SCENARIOS.AGRICULTURAL][!isAgriculturalBoundaryBoxOpen ? 'STATIC' : 'FARM_NORMAL'],
                    ...FLOW[SCENARIOS.AGRICULTURAL]['FARM_NORMAL'],
                    ...FLOW[SCENARIOS.AGRICULTURAL][isAgriculturalIncorrectAirGap ? 'TROUGH_NORMAL_HIGH' : 'TROUGH_NORMAL_LOW'],
                    ...FLOW[SCENARIOS.AGRICULTURAL].BOREHOLE,
                    ...FLOW[SCENARIOS.AGRICULTURAL][isCrossConnection ? 'CROSS_CONNECTION_ACTIVE' : 'CROSS_CONNECTION_INACTIVE'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([]);
            }
            */
            /*
            // meter
            if (!isAgriculturalIncorrectAirGap) {
                setMeter({ boreholePressure: isBoreholePump8BAR ? 1 : 0.5, pressure: 0.0, flow: 0.5, conductivity: 1.0 })
            }
            else if ((isBoreholePumpActive && !isBoreholePump8BAR && !isStoptapOn) || isBoreholePumpActive && isBoreholePump8BAR && !isStoptapOn) {
                setMeter({ boreholePressure: isBoreholePump8BAR ? 1 : 0.5, pressure: 0.5, flow: 0.5, conductivity: 0.5 })
            }
            else if (isBoreholePumpActive && !isBoreholePump8BAR && isStoptapOn) {
                setMeter({ boreholePressure: isBoreholePump8BAR ? 1 : 0.5, pressure: 0.5, flow: 0.5, conductivity: 1.0 })
            }
            else if (isBoreholePumpActive && isBoreholePump8BAR && isStoptapOn) {
                setMeter({ boreholePressure: isBoreholePump8BAR ? 1 : 0.5, pressure: 1.0, flow: 1.0, conductivity: 1.0 })
            }
            else if (isAgriculturalPipeBurstOne && isBoreholePumpActive && !isBoreholePump8BAR && !isStoptapOn) {
                setMeter({ boreholePressure: isBoreholePump8BAR ? 1 : 0.5, pressure: 0.5, flow: 0.5, conductivity: 0.5 })
            }
            else if (isAgriculturalPipeBurstOne && isBoreholePumpActive && !isBoreholePump8BAR && !isStoptapOn) {
                setMeter({ boreholePressure: isBoreholePump8BAR ? 1 : 0.5, pressure: 0.5, flow: 0.5, conductivity: 1.0 })
            }
            else if (isAgriculturalPipeBurstOne && isBoreholePumpActive && isBoreholePump8BAR && !isStoptapOn) {
                setMeter({ boreholePressure: isBoreholePump8BAR ? 1 : 0.5, pressure: 0.5, flow: 0.5, conductivity: 0.5 })
            }
            else if (isAgriculturalPipeBurstOne && isBoreholePumpActive && isBoreholePump8BAR && !isStoptapOn) {
                setMeter({ boreholePressure: isBoreholePump8BAR ? 1 : 0.5, pressure: 1.0, flow: 1.0, conductivity: 1.0 })
            }
            else {
                setMeter({ boreholePressure: isBoreholePump8BAR ? 1 : 0.5, pressure: 0.5, flow: 0.5, conductivity: 0.5 })
            }
            */
        }
    }, [isStoptapOn, isBoreholePump8BAR, isAgriculturalOffscreenLeft, isAgriculturalOffscreenRight, isAgriculturalNegativePressure, isAgriculturalPipeBurstOne, isAgriculturalIncorrectAirGap, isCrossConnection, isBoreholePumpActive]);
};

const useNonHousehold = scenario => {
    const [ menuActive, setMenuActive ] = useRecoilState(multiChoiceOpenAtom);
    const setHotspots = useSetRecoilState(scenarioHotspotsSelectorFamily(parseInt(scenario)));
    const setFlow = useSetRecoilState(flowAtom);
    const setWater = useSetRecoilState(waterAtom);
    const setContamination = useSetRecoilState(contaminationAtomFamily(parseInt(scenario)));
    const setMeter = useSetRecoilState(meterAtomFamily(parseInt(scenario)));
    const isNonHouseholdOffscreenLeft = useRecoilValue(isNonHouseholdOffscreenLeftSelector);
    const isNonHouseholdOffscreenRight = useRecoilValue(isNonHouseholdOffscreenRightSelector);
    const isNonHouseholdBoundaryBoxOpen = useRecoilValue(isNonHouseholdBoundaryBoxOpenSelector);
    const isNonHouseholdPipeBurst = useRecoilValue(isNonHouseholdPipeBurstSelector);
    const isNonHouseholdPipeBurstTwo = useRecoilValue(isNonHouseholdPipeBurstTwoSelector);
    const isNonHouseholdNegativePressure = useRecoilValue(isNonHouseholdNegativePressureSelector);
    const isNonHouseholdSinkTapRunning = useRecoilValue(isNonHouseholdSinkTapRunningSelector);
    const isNonHouseholdIncorrectAirGap = useRecoilValue(isNonHouseholdIncorrectAirGapSelector);
    const isNonHouseholdNoneFitted = useRecoilValue(isNonHouseholdNoneFittedSelector);
    const isNonHouseholdSCVFitted = useRecoilValue(isNonHouseholdSCVFittedSelector);
    const isNonHouseholdRPZFitted = useRecoilValue(isNonHouseholdRPZFittedSelector);
    const isNonHouseholdVanWashConnected = useRecoilValue(isNonHouseholdVanWashConnectedSelector);
    const isWaterOptimiserFitted = useRecoilValue(isWaterOptimiserFittedSelector);
    
    // const isNonHouseholdSeepage = useRecoilValue(isNonHouseholdSeepageSelector);
    // const isNonHouseholdMdpePipe = useRecoilValue(isNonHouseholdMdpePipeSelector);
    // const isNonHouseholdCopperPipe = useRecoilValue(isNonHouseholdCopperPipeSelector);
    // const isNonHouseholdBarrierPipe = useRecoilValue(isNonHouseholdBarrierPipeSelector);
    const isNonHouseholdDCV = useRecoilValue(isNonHouseholdDCVSelector);
    const isNonHouseholdDeadLeg = useRecoilValue(isNonHouseholdDeadLegSelector);
    const closeMenuStateFunc = state => {
        if (menuActive) {
            const menuHotspot = state.find(_ => _.id === menuActive);
            return [
                ...state.filter(_ => _.id !== menuActive),
                { ...menuHotspot, selected: menuHotspot.default },
            ];
        }
        return state;
    };
    const resetMeterObj = { pressure: 0.5, flow: isWaterOptimiserFitted ? 0.0 : 1.0 };
    
    useEffect(() => {
        if (parseInt(scenario) === SCENARIOS.NON_HOUSEHOLD) {
            const reset = () => {
                setHotspots(
                    HOTSPOTS.filter(_ => _.scenario === SCENARIOS.NON_HOUSEHOLD)
                );
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].FACTORY_NORMAL,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][isNonHouseholdRPZFitted ? 'RPZ_ON' : 'RPZ_OFF_NORMAL'],
                ]);
                setMeter(resetMeterObj);
            };
            reset();
            return () => reset();
        }
    }, [scenario]);

    useEffect(() => {
        if (parseInt(scenario) === SCENARIOS.NON_HOUSEHOLD) {
            if (isNonHouseholdOffscreenLeft) {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].FACTORY_NORMAL,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].AGRICULTURAL_OFFSCREEN,
                ]);
                setMeter({ pressure: 0.5, flow: isWaterOptimiserFitted ? 0.0 : 1.0 })
            }
            else if (!isNonHouseholdRPZFitted && isNonHouseholdSinkTapRunning && !isNonHouseholdPipeBurstTwo) {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][!isNonHouseholdBoundaryBoxOpen ? 'FACTORY_STATIC' : 'FACTORY_NORMAL'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination(isNonHouseholdDCV ? [
                    ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].RPZ_TO_DCV,
                ] : [
                    ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].RPZ_TO_ALL,
                ]);
                setMeter({ pressure: 0.5, flow: isWaterOptimiserFitted ? 0.0 : 1.0 })
            }
            else if (!isNonHouseholdRPZFitted && isNonHouseholdPipeBurstTwo) {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][!isNonHouseholdBoundaryBoxOpen ? 'FACTORY_STATIC' : 'FACTORY_NORMAL'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].RPZ_TO_BURST_TWO
                ]);
                setMeter({ pressure: 0.5, flow: isWaterOptimiserFitted ? 0.0 : 1.0 })
            }
            else if (isNonHouseholdDeadLeg && isNonHouseholdSinkTapRunning) {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][!isNonHouseholdBoundaryBoxOpen ? 'FACTORY_STATIC' : 'FACTORY_NORMAL'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].DEAD_LEG_TO_ALL,
                ]);
                setMeter({ pressure: 0.5, flow: isWaterOptimiserFitted ? 0.0 : 1.0 })
            }
            else if (isNonHouseholdVanWashConnected) {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][!isNonHouseholdBoundaryBoxOpen ? 'FACTORY_STATIC' : 'FACTORY_NORMAL'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([]);
                setMeter({ pressure: 0.5, flow: 0.0 })
            }
            else if (isNonHouseholdIncorrectAirGap && isNonHouseholdSinkTapRunning && !isNonHouseholdPipeBurst && !isNonHouseholdPipeBurstTwo) {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][!isNonHouseholdBoundaryBoxOpen ? 'FACTORY_STATIC' : 'FACTORY_NORMAL'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].TANK_TO_SINK_TAP,
                ]);
                setMeter({ pressure: 0.0, flow: 0.0 })
            }
            else if (isNonHouseholdIncorrectAirGap && isNonHouseholdPipeBurst && !isNonHouseholdDCV) {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][!isNonHouseholdBoundaryBoxOpen ? 'FACTORY_STATIC' : 'FACTORY_NORMAL'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].TANK_TO_ALL,
                ]);
                setMeter({ pressure: 0.0, flow: 0.0 })
            }
            else if (isNonHouseholdIncorrectAirGap && isNonHouseholdPipeBurstTwo) {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][!isNonHouseholdBoundaryBoxOpen ? 'FACTORY_STATIC' : 'FACTORY_NORMAL'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination(isNonHouseholdDCV ? [
                    ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].TANK_TO_SCV,
                ] : [
                    ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].TANK_TO_BOUNDARY_BOX,
                ]);
                setMeter({ pressure: 0.0, flow: 0.0 })
            }
            else {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][!isNonHouseholdBoundaryBoxOpen ? 'FACTORY_STATIC' : 'FACTORY_NORMAL'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([]);
                setMeter({ pressure: 0.5, flow: isWaterOptimiserFitted ? 0.0 : 1.0 })
            }
            
            /*
            const contamination = [];

            if (isNonHouseholdOffscreenLeft) {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].FACTORY_NORMAL,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].AGRICULTURAL_OFFSCREEN,
                ]);
            }
            else if (isNonHouseholdOffscreenRight) {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].FACTORY_NORMAL,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].OFFSCREEN,
                ]);
            }
            // else if (isNonHouseholdSeepage && isNonHouseholdMdpePipe && isNonHouseholdSinkTapRunning) {
            //     setFlow([
            //         ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
            //         ...FLOW[SCENARIOS.NON_HOUSEHOLD].FACTORY_NORMAL,
            //     ]);
            //     setWater(resetWaterMapFunc);
            //     setContamination([
            //         ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].SEEPAGE,
            //     ]);
            // }
            else if (isNonHouseholdPipeBurst || isNonHouseholdPipeBurstTwo) {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].FACTORY_REVERSE,
                ]);
                setWater(resetWaterMapFunc);

                switch (true) {
                    case isNonHouseholdIncorrectAirGap && isNonHouseholdPipeBurst:
                        contamination.push(...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].AIRGAP_TO_BURST_TWO);
                        contamination.push(...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].BURST_TWO_TO_DCV);
                        if (!isNonHouseholdDCV) {
                            contamination.push(...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].DCV_TO_BOUNDARY_BOX);
                            contamination.push(...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].BOUNDARY_BOX_TO_BURST_ONE);
                        }
                        setContamination(contamination);
                        break;
                    case isNonHouseholdIncorrectAirGap && isNonHouseholdPipeBurstTwo:
                        setContamination([
                            ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].AIRGAP_TO_BURST_TWO,
                        ]);
                        break;
                    case isNonHouseholdNoneFitted && isNonHouseholdPipeBurst:
                        contamination.push(...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].RPZ_TO_BURST_TWO);
                        contamination.push(...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].BURST_TWO_TO_DCV);
                        if (!isNonHouseholdDCV) {
                            contamination.push(...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].DCV_TO_BOUNDARY_BOX);
                            contamination.push(...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].BOUNDARY_BOX_TO_BURST_ONE);
                        }
                        setContamination(contamination);
                        break;
                    case isNonHouseholdNoneFitted && isNonHouseholdPipeBurstTwo:
                        setContamination([
                            ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].RPZ_TO_BURST_TWO,
                        ]);
                        break;
                    case isNonHouseholdDeadLeg && isNonHouseholdPipeBurst:
                        contamination.push(...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].DEAD_LEG_TO_BURST_TWO);
                        contamination.push(...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].BURST_TWO_TO_DCV);
                        if (!isNonHouseholdDCV) {
                            contamination.push(...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].DCV_TO_BOUNDARY_BOX);
                            contamination.push(...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].BOUNDARY_BOX_TO_BURST_ONE);
                        }
                        setContamination(contamination);
                        break;
                    case isNonHouseholdDeadLeg && isNonHouseholdPipeBurstTwo:
                        setContamination([
                            ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].DEAD_LEG_TO_BURST_TWO,
                        ]);
                        break;
                    default:
                        setContamination([]);
                        break;
                }
            }
            else if (isNonHouseholdDeadLeg && isNonHouseholdSinkTapRunning) {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].FACTORY_NORMAL,
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].DEAD_LEG_TO_SINK_TAP,
                ]);
            }
            else {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][!isNonHouseholdBoundaryBoxOpen ? 'FACTORY_STATIC' : 'FACTORY_NORMAL'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([]);
            }

            // meter
            if ((isNonHouseholdPipeBurstTwo && isNonHouseholdIncorrectAirGap) || (isNonHouseholdPipeBurstTwo && isNonHouseholdDeadLeg) || (isNonHouseholdPipeBurstTwo && !isNonHouseholdRPZFitted)) {
                setMeter({ pressure: 0.5, flow: 1.0, conductivity: 1.0 })
            }
            else if (!isNonHouseholdRPZFitted && isNonHouseholdSinkTapRunning) {
                setMeter({ pressure: 0.5, flow: 0.5, conductivity: 1.0 })
            }
            // else if (isNonHouseholdSeepage && isNonHouseholdMdpePipe) {
            //     setMeter({ pressure: 0.5, flow: 0.5, conductivity: 1.0 })
            // }
            // else if (isNonHouseholdPipeBurstTwo && isNonHouseholdSeepage && isNonHouseholdMdpePipe) {
            //     setMeter({ pressure: 0.5, flow: 1.0, conductivity: 1.0 })
            // }
            // else if (isNonHouseholdPipeBurst && isNonHouseholdSeepage && isNonHouseholdMdpePipe) {
            //     setMeter({ pressure: 0.5, flow: 0.5, conductivity: 1.0 })
            // }
            else if ((isNonHouseholdPipeBurst && isNonHouseholdBoundaryBoxOpen && isNonHouseholdIncorrectAirGap) || (isNonHouseholdPipeBurst && isNonHouseholdBoundaryBoxOpen && !isNonHouseholdRPZFitted) || (isNonHouseholdPipeBurst && isNonHouseholdBoundaryBoxOpen && isNonHouseholdDeadLeg)) {
                setMeter({ pressure: 0.5, flow: 0.5, conductivity: 1.0 })
            }
            else if (isNonHouseholdVanWashConnected) {
                setMeter({ pressure: 0.5, flow: 0.0, conductivity: 0.5 })
            }
            else if (isWaterOptimiserFitted) {
                setMeter({ pressure: 0.5, flow: 0.0, conductivity: 0.5 })
            }
            else if (!isWaterOptimiserFitted) {
                setMeter({ pressure: 0.5, flow: 1.0, conductivity: 0.5 })
            }
            else {
                setMeter({ pressure: 0.5, flow: 0.5, conductivity: 0.5 })
            }
            */
        }
    }, [isNonHouseholdRPZFitted, isNonHouseholdVanWashConnected, isNonHouseholdSinkTapRunning, isWaterOptimiserFitted, isNonHouseholdDeadLeg, isNonHouseholdNoneFitted, isNonHouseholdDCV, isNonHouseholdPipeBurst, isNonHouseholdPipeBurstTwo, isNonHouseholdIncorrectAirGap, isNonHouseholdOffscreenLeft, isNonHouseholdOffscreenRight, isNonHouseholdBoundaryBoxOpen]);

    /*
    useEffect(() => {
        if (parseInt(scenario) === SCENARIOS.NON_HOUSEHOLD) {
            
            if (isNonHouseholdOffscreenLeft) {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].FACTORY_NORMAL,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][isNonHouseholdRPZFitted ? 'RPZ_ON' : 'RPZ_OFF_NORMAL'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].AGRICULTURAL_OFFSCREEN,
                ]);
            }
            else if (isNonHouseholdOffscreenRight) {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].FACTORY_NORMAL,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][isNonHouseholdRPZFitted ? 'RPZ_ON' : 'RPZ_OFF_NORMAL'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([
                    ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].OFFSCREEN,
                ]);
            }
            else if (isNonHouseholdNegativePressure) {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][!isNonHouseholdBoundaryBoxOpen ? 'FACTORY_STATIC' : 'FACTORY_REVERSE'],
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][isNonHouseholdRPZFitted || !isNonHouseholdBoundaryBoxOpen ? 'RPZ_ON' : 'RPZ_OFF_REVERSE'],
                ]);
                setWater(resetWaterMapFunc);

                if (isNonHouseholdIncorrectAirGap) {
                    if (isNonHouseholdPipeBurst)
                        setContamination([
                            ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].TANK_TO_TANK_JUNCTION,
                            ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].TANK_JUNCTION_TO_PIPE_BURST,
                            ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].PIPE_BURST,
                        ]);
                    else if (!isNonHouseholdBoundaryBoxOpen)
                        setContamination([
                            ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].TANK_TO_TANK_JUNCTION,
                            ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].TANK_JUNCTION_TO_PIPE_BURST,
                            ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].PIPE_BURST_TO_BOUNDARY_BOX,
                        ]);
                }
                else if (isNonHouseholdNoneFitted || isNonHouseholdSCVFitted) {
                    if (isNonHouseholdPipeBurst)
                        setContamination([
                            ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].RPZ_TO_TANK_JUNCTION,
                            ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].TANK_JUNCTION_TO_PIPE_BURST,
                            ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].PIPE_BURST,
                        ]);
                    else if (!isNonHouseholdBoundaryBoxOpen)
                        setContamination([
                            ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].RPZ_TO_TANK_JUNCTION,
                            ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].TANK_JUNCTION_TO_PIPE_BURST,
                            ...CONTAMINATION[SCENARIOS.NON_HOUSEHOLD].PIPE_BURST_TO_BOUNDARY_BOX,
                        ]);
                }
                else {
                    setContamination([]);
                }
            }
            else {
                setFlow([
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD].MAINS,
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][!isNonHouseholdBoundaryBoxOpen ? 'FACTORY_STATIC' : 'FACTORY_NORMAL'],
                    ...FLOW[SCENARIOS.NON_HOUSEHOLD][isNonHouseholdRPZFitted ? 'RPZ_ON' : 'RPZ_OFF_NORMAL'],
                ]);
                setWater(resetWaterMapFunc);
                setContamination([]);
            }

            // meter
            if (isNonHouseholdNegativePressure) {
                if (isNonHouseholdIncorrectAirGap) {
                    setMeter({ pressure: 0, flow: 0, conductivity: 1 });
                }
                else if (isNonHouseholdNoneFitted || isNonHouseholdSCVFitted) {
                    setMeter({ pressure: 0, flow: 0, conductivity: 1 });
                }
                else {
                    setMeter({ pressure: 0, flow: 0, conductivity: 0.5 });
                }
            }
            else {
                if (isNonHouseholdVanWashConnected) {
                    setMeter({ pressure: 0.5, flow: 1, conductivity: 0.5 });
                }
                else if (isWaterOptimiserFitted) {
                    setMeter({ pressure: 0.5, flow: 0, conductivity: 0.5 });
                }
                else {
                    setMeter(resetMeterObj);
                }
            }
        }
    }, [isNonHouseholdOffscreenLeft, isNonHouseholdOffscreenRight, isNonHouseholdBoundaryBoxOpen, isNonHouseholdPipeBurst, isNonHouseholdNegativePressure, isNonHouseholdSinkTapRunning, isNonHouseholdIncorrectAirGap, isNonHouseholdNoneFitted, isNonHouseholdSCVFitted, isNonHouseholdRPZFitted, isNonHouseholdVanWashConnected, isWaterOptimiserFitted]);
    */
};

const StateObserver = ({ scenario }) => {
    useHousehold(scenario);
    useAgricultural(scenario);
    useNonHousehold(scenario);
    return null;
};

export default StateObserver;