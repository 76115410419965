import { useEffect, useRef, useState, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useSpring, animated, config } from 'react-spring';
import styled from 'styled-components';
import Portal from './portal.component';
import MultiChoice from './multichoice.component';
import { Layer, Defs } from './svg.component';
import { hotspotSelectorFamily } from '../recoil/state.recoil';
import useHotspots from '../hooks/useHotspots.hook';

const LinkStyles = styled(animated.a)`
position: absolute;
z-index: 2;
top: ${p => p.top}px;
left: ${p => p.left}px;
width: ${p => p.height}px;
height: ${p => p.height}px;
border-radius: 50%;
background-color: ${p => p.theme === 'light' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'};
cursor: pointer;
${p => p.isvisible ? `display: block;` : `display: none;`}
`;

const springInitial = {
    from: {
        opacity: 1,
        transform: 'scale3d(1, 1, 1)',
    },
    config: config.default,
};
const springDefault = {
    to: {
        opacity: 1,
        transform: 'scale3d(1, 1, 1)',
    },
    config: config.default,
};
const springActive = {
    to: {
        opacity: 0.5,
        transform: 'scale3d(1.4, 1.4, 1.4)',
    },
    config: { ...config.default, friction: 0, mass: 10 },
};

const Hotspot = ({ scenario, id, active, clickHandler }) => {
    const { reverseActive, multiChoice, selected, correct, options, theme, displayToggle, displayToggleState, ...item } = useRecoilValue(hotspotSelectorFamily(id));
    const [ spring, setSpring ] = useSpring(() => springInitial);
    const [ position, setPosition ] = useState({ top: 0, left: 0, width: 0, height: 0 });

    const toggle = useRecoilValue(hotspotSelectorFamily(displayToggle));
    const isVisible = useMemo(() => {
        if (!toggle)
            return true;
        else
            return toggle.value === displayToggleState;
    }, [toggle]);
    const pathRef = useRef();

    const updatePosition = () => {
        const { top: offsetY } = document.getElementById('root').getBoundingClientRect();
        const { top, left, width, height } = pathRef.current.getBoundingClientRect();
        setPosition({ top: top - offsetY, left: left - ((height - width) / 2), width, height });
    };

    useEffect(() => {
        updatePosition();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', updatePosition);
        return () => window.removeEventListener('resize', updatePosition);
    }, []);

    useEffect(() => {
        if (active)
            setSpring(!reverseActive ? springActive : springDefault);
        else
            setSpring(!reverseActive ? springDefault : springActive);
    }, [active]);

    return <>
        <g clipPath='url(#clip0)'>
            <path 
                ref={pathRef} 
                opacity={0} 
                {...item}
            />
        </g>
        <Portal id='root'>
            <LinkStyles 
                onClick={clickHandler} 
                theme={theme} 
                style={spring} 
                isvisible={isVisible}
                {...position}
            />
            {multiChoice && 
                <MultiChoice
                    scenario={scenario}
                    id={id}
                    active={active}
                    reverseActive={reverseActive}
                    selected={selected}
                    correct={correct}
                    options={options}
                    {...position}
                />}
        </Portal>
    </>
};

const Hotspots = ({ scenario }) => {
    const { hotspotIds, isActive, clickHandlers } = useHotspots(scenario);
    return <Layer>
        {hotspotIds.map((id, idx) =>
            <Hotspot 
                key={`Hotspots/${id}`} 
                scenario={scenario}
                id={id} 
                active={isActive(id)} 
                clickHandler={clickHandlers[idx]}
        />)}
        <Defs />
    </Layer>
};

export default Hotspots;