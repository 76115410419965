import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { SCENARIOS, SLIDES } from '../config/constants';

const Container = styled.div`
position: absolute;
z-index: 999999;
top: 0;
right: 7vw;
width: 25%;

display: flex;
flex-wrap: wrap;
flex-direction: row;
justify-content: space-between;
align-items: center;
column-gap: 0.5rem;
`;

const Examples = styled.p`
width: 100%;
margin: 0 0 0.5rem 0;
padding: 1rem;
color: white;
background-color: #0b3647;
font-size: 1.4rem;
text-align: center;
`;

const SlideLink = styled.a`
width: calc(25% - 0.5rem);
padding: 1.5rem 1rem;
color: white;
background-color: #0b3647;
text-decoration: none;
font-size: 1.4rem;
display: flex;
justify-content: center;
align-items: center;
`;

const SlideContainer = styled.div`
position: absolute;
top: 3.9rem;
right: 0;
width: 50vw;
height: calc(50vw * (708 / 958));
color: white;
background-color: #1a1a1a;
box-shadow: 0 0 1rem 1rem rgba(0,0,0, 0.2);
transition: transform 0.3s ease-in-out;
transform-origin: 100% 0%;
// transform: scale3d(0, 0, 0);
transform: scale3d(1, 1, 1);

img {
    width: 100%;
    height: auto;
}
`;

const CloseButtonStyles = styled.a`
position: absolute;
top: 1rem;
right: 1rem;
width: 3rem;
height: 3rem;
border: 1px solid #0b3647;
border-radius: 50%;
color: #0b3647;
background-color: transparent;
font-size: 3rem;
text-decoration: none;
display: flex;
justify-content: center;
align-items: center;
`;

const Link = ({ idx, clickHandler }) => {
    return <SlideLink href='#' onClick={e => clickHandler(e, idx)}>
        {idx + 1}
    </SlideLink>
};

const CloseButton = ({ clickHandler }) => {
    return <CloseButtonStyles href='#' onClick={clickHandler}>
        &times;
    </CloseButtonStyles>
};

const Slide = ({ src, closeHandler }) => {
    return <SlideContainer>
        <CloseButton clickHandler={closeHandler} />
        <img src={src} alt='' />
    </SlideContainer>
};

const Slides = ({ scenario }) => {
    const [ current, setCurrent ] = useState(null);
    const slides = useMemo(() => SLIDES[parseInt(scenario)], [scenario]);
    const show = useMemo(() => {
        if (current === null)
            return false;
        return true;
    }, [current]);
    const clickHandler = (e, idx) => {
        e.preventDefault();
        setCurrent(idx);
    };
    const closeHandler = e => {
        e.preventDefault();
        setCurrent(null);
    };
    return <Container>
        <Examples>Examples</Examples>
        {slides.map((slide, idx) =>
            <Link key={`SlideLink/${idx}`} clickHandler={clickHandler} idx={idx} />)}
        {show &&
            <Slide src={slides[current]} closeHandler={closeHandler} />}
    </Container>
};

export default Slides;