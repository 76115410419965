import { Layer, Defs } from './svg.component';
import Pipes from './pipes.component';
import Flow from './flow.component';
import Contamination from './contamination.component';

const PipeLayers = props => {
    return <>
        <Layer>
            <Pipes {...props} />
            <Contamination {...props} />
            <Flow {...props} />
            <Defs />
        </Layer>
    </>
};

export default PipeLayers;