import { useParams } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { useRecoilValue } from 'recoil';
import StateObserver from './stateobserver.component';
import ResetButton from './resetbutton.component';
import Slides from './slides.component';
import { InfoLink, InfoVideo } from './infolink.component';
import Backdrop from './backdrop.component';
import PipeLayers from './pipelayers.component';
import Animations from './animations.component';
import LottieAnimations from './lottie.component';
import Widgets from './widgets.component';
import Hotspots from './hotspots.component';
import Meter from './meter.component';
import Info from './info.component';
import { Layer, Defs } from './svg.component';
import { Fish, Hose } from './household.component';
import { Foreground } from './nonhousehold.component';
import { SCENARIOS } from '../config/constants';
import { overlayVisibleAtom } from '../recoil/state.recoil';

const ScenariosGlobalStyles = createGlobalStyle`
html, body {
    background-color: #7CB544;
}
#root {
    position: relative;
    width: 100%;
}
`;

const RootContainer = styled.div`
position: relative;
// overflow: hidden;
${p => !p.screen ? `
width: 100%;
height: calc(100vw * 0.5625);
` : `
width: 1280px;
height: 616px;
`}
`;

const OverlayContainer = styled.div`
position: absolute;
z-index: 9999999;
top: 0;
right: 0;
bottom: 0;
left: 0;
color: white;
background-color: rgba(0,0,0, 0.9);
`

const Overlay = () => {
    const show = useRecoilValue(overlayVisibleAtom);
    if (!show)
        return null;
    else
        return <OverlayContainer />
};

const Scenario = props => {
    const { scenario } = props;
    const show = useRecoilValue(overlayVisibleAtom);
    if (show)
        return <>
            <Overlay />
            <InfoVideo />
        </>
    else
        return <>
            <RootContainer>
                <StateObserver {...props} />
                <ResetButton {...props} />
                <Slides {...props} />
                <InfoLink {...props} />
                <Backdrop {...props} />
                <Animations {...props} />
                <PipeLayers {...props} />
                {parseInt(scenario) === SCENARIOS.NON_HOUSEHOLD && 
                    <Layer>
                        <Foreground />
                        <Defs />
                    </Layer>}
                <LottieAnimations {...props} />
                <Widgets {...props} />
                {/* <Info {...props} /> */}
                {parseInt(scenario) === SCENARIOS.HOUSEHOLD && 
                    <Layer>
                        <Fish />
                        {/* <Hose /> */}
                        <Defs />
                    </Layer>}
                <Hotspots {...props} />
                <Meter {...props} />
            </RootContainer>
        </>
};

const Scenarios = () => {
    const params = useParams();
    return <>
        <ScenariosGlobalStyles {...params} />
        <Scenario {...params} />
    </>
};

export default Scenarios;