import { useRecoilValue } from 'recoil';
import { animated } from 'react-spring';
import { pipeSelectorFamily } from '../recoil/state.recoil';
import useContamination from '../hooks/useContamination.hook';

const ContaminationSegment = ({ id, spring }) => {
    const pipe = useRecoilValue(pipeSelectorFamily(id));
    const { d, strokeWidth, length } = pipe;
    return <animated.g clipPath='url(#clip0)' style={spring} className='contamination-segment'>
        <path 
            id={`${id}-contamination`} 
            d={d} 
            strokeWidth={strokeWidth - 8} 
            strokeDasharray={length}
        />
    </animated.g>
};

const Contamination = ({ scenario }) => {
    const { pipeSegmentIds, springs } = useContamination(parseInt(scenario));
    return pipeSegmentIds.map((id, idx) => 
        <ContaminationSegment 
            key={`Contamination/${id}`} 
            id={id} 
            spring={springs[idx]}
        />);
};

export default Contamination;