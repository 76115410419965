import './App.css';

import Routes from './components/routes.component';
// import Switcher from './components/switcher.component';
import Scenarios from './components/scenarios.component';

const App = () => <Routes>
    {/* <Switcher /> */}
    <Scenarios />
</Routes>

export default App;
