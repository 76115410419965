import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Svg = styled.svg.attrs({ 
    version: '1.1', 
    xmlns: 'http://www.w3.org/2000/svg', 
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``;

const LayerStyles = styled(Svg)`
position: absolute;
top: 0;
left: 0;
${p => !p.screen ? `
width: 100vw;
height: calc(100vw * 0.5625);
` : `
width: 100%;
height: 100%;
`}
`;

export const Layer = ({ children }) => {
    const params = useParams();
    return <LayerStyles width='5120' height='2880' viewBox='0 0 5120 2880' fill='none' xmlns='http://www.w3.org/2000/svg' {...params}>{children}</LayerStyles>
};

export const ClipPath = ({ children, ...props }) => <g {...props}>{children}</g>

export const Path = ({ d, attr }) => <path d={d} {...attr} />

export const Defs = () => {
    return <defs>
        
    </defs>
};