import { useRecoilValue, useResetRecoilState } from 'recoil';
import styled from 'styled-components';

import { hotspotsAtom, multiChoiceOpenAtom, showResetButtonAtom, currentRouteAtom, validRoutesAtom } from '../recoil/state.recoil';

const ButtonStyles = styled.button`
position: absolute;
z-index: 999999;
top: 19vh;
right: 7vw;
margin: 0;
padding: 0.4rem 0.8rem;
border: 0;
color: white;
background-color: rgba(0,0,0, 0.2);
font-size: 1.5rem;
font-family: 'dinregularwebfont', sans-serif;
cursor: pointer;
transition: opacity 0.3s ease-in-out;
opacity: 0;
${p => p.visible ? `opacity: 1;` : ``}
`;

const ResetButton = () => {
    const visible = useRecoilValue(showResetButtonAtom);
    const resetHotspots = useResetRecoilState(hotspotsAtom);
    const resetMultichoice = useResetRecoilState(multiChoiceOpenAtom);
    const resetVisible = useResetRecoilState(showResetButtonAtom);
    const resetCurrentRoute = useResetRecoilState(currentRouteAtom);
    const resetValidRoutes = useResetRecoilState(validRoutesAtom);

    const clickHandler = e => {
        e.preventDefault();
        resetHotspots();
        resetMultichoice();
        resetVisible();
        resetCurrentRoute();
        resetValidRoutes();
    }

    return <ButtonStyles visible={visible} onClick={clickHandler}>
        Reset
    </ButtonStyles>
};

export default ResetButton;