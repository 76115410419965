import { useRef, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSpring, animated, config } from 'react-spring';
import styled from 'styled-components';
import { multiChoiceOpenAtom, hotspotSelectorFamily } from '../recoil/state.recoil';
import { useActionRoutes } from '../hooks/useHotspots.hook';

const MultiChoiceStyles = styled(animated.div).withConfig({
    shouldForwardProp: p => p !== 'isVisible',
})`
position: absolute;
z-index: ${p => p.isVisible ? 3 : 1};
top: ${p => p.top - 40}px;
left: ${p => p.left + p.height + 5 + 10}px;
padding: 0.8rem;
color: white;
background-color: black;
display: flex;
justify-content: space-between;
align-items: center;
transform: translateX(0vw);
opacity: 0;
`;

const ButtonStyles = styled.button`
margin: 0 0.4rem;
padding: 0.6rem;
border: 0;
color: white;
background-color: rgba(255,255,255,0.1);
font-size: 0.9rem;
${p => p.menuActive ? `
cursor: pointer;
` : ``}
outline: 0;
&:first-child {
    margin-left: 0;
}
&:last-child {
    margin-right: 0;
}
${p => p.children === p.selected ? `
background-color: rgba(255,255,255,0.3);
` : ``}
`;

const Button = ({ scenario, option, menuActive, id, ...props }) => {
    const setHotspot = useSetRecoilState(hotspotSelectorFamily(id));
    const { routeClickHandler } = useActionRoutes(scenario);
    const ref = useRef();
    const clickHandler = () => {
        ref.current.blur();
        setHotspot(state => ({ ...state, selected: option }));
        routeClickHandler(id, option);
    };
    return <ButtonStyles ref={ref} onClick={clickHandler} menuActive={menuActive} {...props}>
        {option}
    </ButtonStyles>
};

const MultiChoice = ({ scenario, active, reverseActive, options, ...props }) => {
    const menuActive = useRecoilValue(multiChoiceOpenAtom);
    const [ spring, setSpring ] = useSpring(() => ({
        from: { opacity: 0, transform: `translateX(5vw)` },
        config: config.default,
    }));

    useEffect(() => {
        if (menuActive !== props.id)
            setSpring({ to: { opacity: 0, transform: `translateX(5vw)` }});
        else if (menuActive === props.id)
            setSpring({ to: { opacity: 1, transform: `translateX(0vw)` }});
    }, [menuActive, setSpring]);
    
    return <MultiChoiceStyles style={spring} isVisible={menuActive === props.id} {...props}>
        {options.map(option =>
            <Button key={`Multichoice/${option}`} scenario={scenario} option={option} menuActive={menuActive} {...props}>
                {option}
            </Button>)}
    </MultiChoiceStyles>
};

export default MultiChoice;