import { useRecoilValue } from 'recoil';
import styled, { createGlobalStyle } from 'styled-components';
import { ClipPath } from './svg.component';
import { scenarioPipeIdsSelectorFamily, pipeSelectorFamily, pipeFlowDirectionSelectorFamily } from '../recoil/state.recoil';
import { FLOW_SPEED } from '../config/constants';

const getStrokeWidth = p => p.strokeWidth - 16;
const getStrokeDasharray = p => p.mains ? `${FLOW_SPEED * 10} ${FLOW_SPEED}` : `${FLOW_SPEED * 3} ${FLOW_SPEED}`;
const getStrokeDashoffset = p => p.mains ? `${FLOW_SPEED * 10 + FLOW_SPEED}` : `${FLOW_SPEED * 3 + FLOW_SPEED}`;

const FlowGlobalStyles = createGlobalStyle`
@keyframes flow {
    to {
        stroke-dashoffset: 0;
    }
}
`;

const FlowSegmentStyles = styled.path`
stroke-width: ${p => getStrokeWidth(p)};
stroke-dasharray: ${p => getStrokeDasharray(p)};
stroke-dashoffset: ${p => getStrokeDashoffset(p)};
stroke: rgba(255,255,255,0.2);
${p => p.dir !== 0 ? `
animation: flow 1s linear;
animation-iteration-count: infinite;
animation-direction: ${p.dir === -1 ? 'reverse' : 'normal'};
` : `
stroke: rgba(0,0,0,0);
`}
`;

const FlowSegment = ({ id }) => {
    const pipe = useRecoilValue(pipeSelectorFamily(id));
    const dir = useRecoilValue(pipeFlowDirectionSelectorFamily(id));
    return <ClipPath>
        <FlowSegmentStyles id={`${id}-flow`} segmentId={id} dir={dir} {...pipe} />
    </ClipPath>
};

const Flow = ({ scenario }) => {
    const scenarioPipeIds = useRecoilValue(scenarioPipeIdsSelectorFamily(parseInt(scenario)));
    return <>
        <FlowGlobalStyles />
        {scenarioPipeIds.map((id) => 
            <FlowSegment key={`Flow/${id}`} id={id} />)}
    </>
};

export default Flow;