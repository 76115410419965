import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { conductivitySelectorFamily, pressureSelectorFamily, flowSelectorFamily, boreholePressureSelectorFamily } from '../recoil/state.recoil';
import { Layer, ClipPath, Defs } from './svg.component';
import { SCENARIOS } from '../config/constants';

const getOriginX = x => x / 5120 * 100;
const getOriginY = y => y / 2880 * 100;

const DialStyles = styled(ClipPath).withConfig({
    shouldForwardProp: p => p !== 'rotation' && p !== 'originX' && p !== 'originY',
})`
transform: rotateZ(${p => p.rotation}deg);
transform-origin: ${p => p.originX}% ${p => p.originY}%;
transition: all 0.3s ease-in-out;
`

// const HouseholdPressure = ({ scenario }) => {
//     const value = useRecoilValue(pressureSelectorFamily(SCENARIOS.HOUSEHOLD));
//     const rotation = useMemo(() => {
//         if (value === 0)   return -80;
//         if (value === 0.5) return 0;
//         if (value === 1)   return 80;
//     }, [value]);
//     // 4685     0.9150390625
//     // 1968     0.68333333333
//     return <DialStyles rotation={rotation} originX={getOriginX(4674)} originY={getOriginY(1809)}>
//         <path d="M4680.56 1804.66C4680.5 1802.6 4668.09 1720 4668.09 1720L4657 1805.41V1805.42C4656.94 1814.87 4662.17 1822.57 4668.7 1822.62C4675.22 1822.66 4680.55 1815.03 4680.62 1805.57C4680.62 1805.4 4680.59 1805.24 4680.59 1805.07L4680.62 1805.07L4680.56 1804.66Z" fill="#009FE3"/>
//         <path d="M4680.56 1804.66C4680.5 1802.6 4668.09 1720 4668.09 1720L4657 1805.41V1805.42C4656.94 1814.87 4662.17 1822.57 4668.7 1822.62C4675.22 1822.66 4680.55 1815.03 4680.62 1805.57C4680.62 1805.4 4680.59 1805.24 4680.59 1805.07L4680.62 1805.07L4680.56 1804.66Z" stroke="white" strokeWidth="1.80267"/>
//         <path d="M4668.54 1820.65L4668.21 1720.48C4668.21 1720.48 4679.67 1800.24 4680.39 1803.97C4681.11 1807.7 4676.91 1822.2 4668.54 1820.65Z" fill="#003E52"/>
//     </DialStyles>
// };

// const HouseholdFlow = ({ scenario }) => {
//     const value = useRecoilValue(flowSelectorFamily(SCENARIOS.HOUSEHOLD));
//     const rotation = useMemo(() => {
//         if (value === 0)   return -80;
//         if (value === 0.5) return 0;
//         if (value === 1)   return 80;
//     }, [value]);
//     // 4680     0.9140625
//     // 2154     0.74791666666
//     return <DialStyles rotation={rotation} originX={getOriginX(4674)} originY={getOriginY(1994)}>
//         <path d="M4680.63 1990.89C4680.56 1988.82 4668.16 1906.23 4668.16 1906.23L4657.07 1991.64V1991.64C4657 2001.1 4662.24 2008.8 4668.76 2008.85C4675.28 2008.89 4680.62 2001.26 4680.68 1991.8C4680.68 1991.63 4680.65 1991.47 4680.65 1991.3L4680.69 1991.3L4680.63 1990.89Z" fill="#009FE3"/>
//         <path d="M4680.63 1990.89C4680.56 1988.82 4668.16 1906.23 4668.16 1906.23L4657.07 1991.64V1991.64C4657 2001.1 4662.24 2008.8 4668.76 2008.85C4675.28 2008.89 4680.62 2001.26 4680.68 1991.8C4680.68 1991.63 4680.65 1991.47 4680.65 1991.3L4680.69 1991.3L4680.63 1990.89Z" stroke="white" strokeWidth="1.80267"/>
//         <path d="M4668.61 2006.88L4668.28 1906.71C4668.28 1906.71 4679.73 1986.47 4680.45 1990.2C4681.17 1993.93 4676.98 2008.42 4668.61 2006.88Z" fill="#003E52"/>
//     </DialStyles>
// };

// const HouseholdConductivity = ({ scenario }) => {
//     const value = useRecoilValue(conductivitySelectorFamily(SCENARIOS.HOUSEHOLD));
//     const rotation = useMemo(() => {
//         if (value === 0)   return -80;
//         if (value === 0.5) return 0;
//         if (value === 0.75) return 40;
//         if (value === 1)   return 80;
//     }, [value]);
//     // 4685     0.9150390625
//     // 1968     0.68333333333
//     return <DialStyles rotation={rotation} originX={getOriginX(4674)} originY={getOriginY(2183)}>
//         <path d="M4680.63 2179.89C4680.56 2177.82 4668.16 2095.23 4668.16 2095.23L4657.07 2180.64V2180.64C4657 2190.1 4662.24 2197.8 4668.76 2197.85C4675.28 2197.89 4680.62 2190.26 4680.68 2180.8C4680.68 2180.63 4680.65 2180.47 4680.65 2180.3L4680.69 2180.3L4680.63 2179.89Z" fill="#009FE3"/>
//         <path d="M4680.63 2179.89C4680.56 2177.82 4668.16 2095.23 4668.16 2095.23L4657.07 2180.64V2180.64C4657 2190.1 4662.24 2197.8 4668.76 2197.85C4675.28 2197.89 4680.62 2190.26 4680.68 2180.8C4680.68 2180.63 4680.65 2180.47 4680.65 2180.3L4680.69 2180.3L4680.63 2179.89Z" stroke="white" strokeWidth="1.80267"/>
//         <path d="M4668.61 2195.88L4668.28 2095.71C4668.28 2095.71 4679.73 2175.47 4680.45 2179.2C4681.17 2182.93 4676.98 2197.42 4668.61 2195.88Z" fill="#003E52"/>
//     </DialStyles>
// };

const AgriculturalBoreholePressure = () => {
    const value = useRecoilValue(boreholePressureSelectorFamily(SCENARIOS.AGRICULTURAL));
    const rotation = useMemo(() => {
        if (value === 0)   return -80;
        if (value === 0.5) return 0;
        if (value === 1)   return 80;
    }, [value]);
    return <DialStyles rotation={rotation} originX={getOriginX(564)} originY={getOriginY(2305)}>
        <path d="M580.337 2300.89C580.276 2298.82 567.872 2216.23 567.872 2216.23L556.778 2301.64V2301.64C556.714 2311.1 561.95 2318.8 568.473 2318.85C574.994 2318.89 580.332 2311.26 580.394 2301.8C580.396 2301.63 580.365 2301.47 580.364 2301.3L580.398 2301.3L580.337 2300.89Z" fill="#009FE3"/>
        <path d="M580.337 2300.89C580.276 2298.82 567.872 2216.23 567.872 2216.23L556.778 2301.64V2301.64C556.714 2311.1 561.95 2318.8 568.473 2318.85C574.994 2318.89 580.332 2311.26 580.394 2301.8C580.396 2301.63 580.365 2301.47 580.364 2301.3L580.398 2301.3L580.337 2300.89Z" stroke="white" strokeWidth="1.80267"/>
        <path d="M568.322 2316.88L567.988 2216.71C567.988 2216.71 579.444 2296.47 580.164 2300.2C580.884 2303.93 576.691 2318.42 568.322 2316.88Z" fill="#003E52"/>
    </DialStyles>
};

const AgriculturalPressure = () => {
    const value = useRecoilValue(pressureSelectorFamily(SCENARIOS.AGRICULTURAL));
    const rotation = useMemo(() => {
        if (value === 0)   return -80;
        if (value === 0.5) return 0;
        if (value === 1)   return 80;
    }, [value]);
    return <DialStyles rotation={rotation} originX={getOriginX(4706)} originY={getOriginY(1950)}>
        <path d="M4716.56 1946.66C4716.5 1944.6 4704.09 1862 4704.09 1862L4693 1947.41V1947.42C4692.94 1956.87 4698.17 1964.57 4704.7 1964.62C4711.22 1964.66 4716.55 1957.03 4716.62 1947.57C4716.62 1947.4 4716.59 1947.24 4716.59 1947.07L4716.62 1947.07L4716.56 1946.66Z" fill="#009FE3"/>
        <path d="M4716.56 1946.66C4716.5 1944.6 4704.09 1862 4704.09 1862L4693 1947.41V1947.42C4692.94 1956.87 4698.17 1964.57 4704.7 1964.62C4711.22 1964.66 4716.55 1957.03 4716.62 1947.57C4716.62 1947.4 4716.59 1947.24 4716.59 1947.07L4716.62 1947.07L4716.56 1946.66Z" stroke="white" strokeWidth="1.80267"/>
        <path d="M4704.54 1962.65L4704.21 1862.48C4704.21 1862.48 4715.67 1942.24 4716.39 1945.97C4717.11 1949.7 4712.91 1964.2 4704.54 1962.65Z" fill="#003E52"/>
    </DialStyles>
};

const AgriculturalFlow = () => {
    const value = useRecoilValue(flowSelectorFamily(SCENARIOS.AGRICULTURAL));
    const rotation = useMemo(() => {
        if (value === 0)   return -80;
        if (value === 0.5) return 0;
        if (value === 1)   return 80;
    }, [value]);
    return <DialStyles rotation={rotation} originX={getOriginX(4706)} originY={getOriginY(2136)}>
        <path d="M4716.63 2132.89C4716.56 2130.82 4704.16 2048.23 4704.16 2048.23L4693.07 2133.64V2133.64C4693 2143.1 4698.24 2150.8 4704.76 2150.85C4711.28 2150.89 4716.62 2143.26 4716.68 2133.8C4716.68 2133.63 4716.65 2133.47 4716.65 2133.3L4716.69 2133.3L4716.63 2132.89Z" fill="#009FE3"/>
        <path d="M4716.63 2132.89C4716.56 2130.82 4704.16 2048.23 4704.16 2048.23L4693.07 2133.64V2133.64C4693 2143.1 4698.24 2150.8 4704.76 2150.85C4711.28 2150.89 4716.62 2143.26 4716.68 2133.8C4716.68 2133.63 4716.65 2133.47 4716.65 2133.3L4716.69 2133.3L4716.63 2132.89Z" stroke="white" strokeWidth="1.80267"/>
        <path d="M4704.61 2148.88L4704.28 2048.71C4704.28 2048.71 4715.73 2128.47 4716.45 2132.2C4717.17 2135.93 4712.98 2150.42 4704.61 2148.88Z" fill="#003E52"/>
    </DialStyles>
};

const AgriculturalConductivity = () => {
    const value = useRecoilValue(conductivitySelectorFamily(SCENARIOS.AGRICULTURAL));
    const rotation = useMemo(() => {
        if (value === 0)   return -80;
        if (value === 0.5) return 0;
        if (value === 1)   return 80;
    }, [value]);
    return <DialStyles rotation={rotation} originX={getOriginX(4706)} originY={getOriginY(2325)}>
        <path d="M4716.63 2321.89C4716.56 2319.82 4704.16 2237.23 4704.16 2237.23L4693.07 2322.64V2322.64C4693 2332.1 4698.24 2339.8 4704.76 2339.85C4711.28 2339.89 4716.62 2332.26 4716.68 2322.8C4716.68 2322.63 4716.65 2322.47 4716.65 2322.3L4716.69 2322.3L4716.63 2321.89Z" fill="#009FE3"/>
        <path d="M4716.63 2321.89C4716.56 2319.82 4704.16 2237.23 4704.16 2237.23L4693.07 2322.64V2322.64C4693 2332.1 4698.24 2339.8 4704.76 2339.85C4711.28 2339.89 4716.62 2332.26 4716.68 2322.8C4716.68 2322.63 4716.65 2322.47 4716.65 2322.3L4716.69 2322.3L4716.63 2321.89Z" stroke="white" strokeWidth="1.80267"/>
        <path d="M4704.61 2337.88L4704.28 2237.71C4704.28 2237.71 4715.73 2317.47 4716.45 2321.2C4717.17 2324.93 4712.98 2339.42 4704.61 2337.88Z" fill="#003E52"/>
    </DialStyles>
};

const NonHouseholdPressure = () => {
    const value = useRecoilValue(pressureSelectorFamily(SCENARIOS.NON_HOUSEHOLD));
    const rotation = useMemo(() => {
        if (value === 0)   return -80;
        if (value === 0.5) return 0;
        if (value === 1)   return 80;
    }, [value]);
    return <DialStyles rotation={rotation} originX={getOriginX(4445)} originY={getOriginY(1799)}>
        <path d="M4451.56 1800.66C4451.5 1798.6 4439.09 1716 4439.09 1716L4428 1801.41V1801.42C4427.94 1810.87 4433.17 1818.57 4439.7 1818.62C4446.22 1818.66 4451.55 1811.03 4451.62 1801.57C4451.62 1801.4 4451.59 1801.24 4451.59 1801.07L4451.62 1801.07L4451.56 1800.66Z" fill="#009FE3"/>
        <path d="M4451.56 1800.66C4451.5 1798.6 4439.09 1716 4439.09 1716L4428 1801.41V1801.42C4427.94 1810.87 4433.17 1818.57 4439.7 1818.62C4446.22 1818.66 4451.55 1811.03 4451.62 1801.57C4451.62 1801.4 4451.59 1801.24 4451.59 1801.07L4451.62 1801.07L4451.56 1800.66Z" stroke="white" strokeWidth="1.80267"/>
        <path d="M4439.54 1816.65L4439.21 1716.48C4439.21 1716.48 4450.67 1796.24 4451.39 1799.97C4452.11 1803.7 4447.91 1818.2 4439.54 1816.65Z" fill="#003E52"/>
    </DialStyles>
};

const NonHouseholdFlow = () => {
    const value = useRecoilValue(flowSelectorFamily(SCENARIOS.NON_HOUSEHOLD));
    const rotation = useMemo(() => {
        if (value === 0)   return -80;
        if (value === 0.5) return 0;
        if (value === 1)   return 80;
    }, [value]);
    return <DialStyles rotation={rotation} originX={getOriginX(4445)} originY={getOriginY(1990)}>
        <path d="M4451.63 1986.89C4451.56 1984.82 4439.16 1902.23 4439.16 1902.23L4428.07 1987.64V1987.64C4428 1997.1 4433.24 2004.8 4439.76 2004.85C4446.28 2004.89 4451.62 1997.26 4451.68 1987.8C4451.68 1987.63 4451.65 1987.47 4451.65 1987.3L4451.69 1987.3L4451.63 1986.89Z" fill="#009FE3"/>
        <path d="M4451.63 1986.89C4451.56 1984.82 4439.16 1902.23 4439.16 1902.23L4428.07 1987.64V1987.64C4428 1997.1 4433.24 2004.8 4439.76 2004.85C4446.28 2004.89 4451.62 1997.26 4451.68 1987.8C4451.68 1987.63 4451.65 1987.47 4451.65 1987.3L4451.69 1987.3L4451.63 1986.89Z" stroke="white" strokeWidth="1.80267"/>
        <path d="M4439.61 2002.88L4439.28 1902.71C4439.28 1902.71 4450.73 1982.47 4451.45 1986.2C4452.17 1989.93 4447.98 2004.42 4439.61 2002.88Z" fill="#003E52"/>
    </DialStyles>
};

// const NonHouseholdConductivity = () => {
//     const value = useRecoilValue(conductivitySelectorFamily(SCENARIOS.NON_HOUSEHOLD));
//     const rotation = useMemo(() => {
//         if (value === 0)   return -80;
//         if (value === 0.5) return 0;
//         if (value === 1)   return 80;
//     }, [value]);
//     return <DialStyles rotation={rotation} originX={getOriginX(4445)} originY={getOriginY(2179)}>
//         <path d="M4451.63 2175.89C4451.56 2173.82 4439.16 2091.23 4439.16 2091.23L4428.07 2176.64V2176.64C4428 2186.1 4433.24 2193.8 4439.76 2193.85C4446.28 2193.89 4451.62 2186.26 4451.68 2176.8C4451.68 2176.63 4451.65 2176.47 4451.65 2176.3L4451.69 2176.3L4451.63 2175.89Z" fill="#009FE3"/>
//         <path d="M4451.63 2175.89C4451.56 2173.82 4439.16 2091.23 4439.16 2091.23L4428.07 2176.64V2176.64C4428 2186.1 4433.24 2193.8 4439.76 2193.85C4446.28 2193.89 4451.62 2186.26 4451.68 2176.8C4451.68 2176.63 4451.65 2176.47 4451.65 2176.3L4451.69 2176.3L4451.63 2175.89Z" stroke="white" strokeWidth="1.80267"/>
//         <path d="M4439.61 2191.88L4439.28 2091.71C4439.28 2091.71 4450.73 2171.47 4451.45 2175.2C4452.17 2178.93 4447.98 2193.42 4439.61 2191.88Z" fill="#003E52"/>
//     </DialStyles>
// };

const Meter = ({ scenario }) => {
    // if (parseInt(scenario) === SCENARIOS.HOUSEHOLD)
    //     return <Layer>
    //         <HouseholdPressure />
    //         <HouseholdFlow />
    //         <HouseholdConductivity />
    //         <Defs />
    //     </Layer>
    // else if (parseInt(scenario) === SCENARIOS.AGRICULTURAL)
    if (parseInt(scenario) === SCENARIOS.AGRICULTURAL)
        return <Layer>
            <AgriculturalBoreholePressure />
            <AgriculturalPressure />
            <AgriculturalFlow />
            <AgriculturalConductivity />
            <Defs />
        </Layer>
    else if (parseInt(scenario) === SCENARIOS.NON_HOUSEHOLD)
        return <Layer>
            <NonHouseholdPressure />
            <NonHouseholdFlow />
            {/* <NonHouseholdConductivity /> */}
            <Defs />
        </Layer>
    else
        return null;
};

export default Meter;