import { useRecoilValue } from 'recoil';
import { config, animated, useTrail } from 'react-spring';
import styled from 'styled-components';
import { isHouseholdNegativePressureSelector, isHydrantOpenSelector, isHouseholdPipeBurstSelector, isHoseSubmergedInPondSelector, isHouseholdBoundaryBoxOpenSelector, isStopCockOpenSelector, isSeepageSelector, isContaminatedPipeSelector, isFillLoopConnectedSelector, isSinkFilterFittedSelector, isSCVFittedSelector, isHouseholdIncorrectAirGapSelector, isOutsideTapRunningSelector, isShowerHeadImmersedSelector, contaminationAtomFamily } from '../recoil/state.recoil';
import { SCENARIOS } from '../config/constants';
import { useEffect } from 'react';

import Portal from './portal.component';

const ContainerStyles = styled.div`
position: absolute;
top: 50px;
right: 50px;
color: white;
`;

const PanelStyles = styled.div`
width: 30vw;
padding: 1rem;
`;

const PanelHeading = styled(animated.h1)`
margin: 0 0 0.5rem 0;
font-size: 1.2rem;
font-size: 1.8vw;
font-family: 'dinregularwebfont', sans-serif;
`;

const PanelDescription = styled(animated.p)`
margin: 0;
font-size: 1rem;
font-size: 1.4vw;
font-family: 'dinregularwebfont', sans-serif;
line-height: 1.5;
`;

const NegativePressure = ({ scenario }) => {
    const [ spring, setSpring ] = useTrail(2, () => ({
        from: {
            opacity: 0,
            transform: 'translateY(3vw)',
        },
        config: config.default,
    }));
    const isHouseholdNegativePressure = useRecoilValue(isHouseholdNegativePressureSelector);
    const isHydrantOpen = useRecoilValue(isHydrantOpenSelector);
    const isHouseholdPipeBurst = useRecoilValue(isHouseholdPipeBurstSelector);
    const isHoseSubmergedInPond = useRecoilValue(isHoseSubmergedInPondSelector);
    const isHouseholdBoundaryBoxOpen = useRecoilValue(isHouseholdBoundaryBoxOpenSelector);
    const isStopCockOpen = useRecoilValue(isStopCockOpenSelector);

    const panelDescription = () => {
        switch (true) {
            case isHydrantOpen:
                return 'Open hydrant creates negative pressure triggering backflow from house.';
            case isHouseholdPipeBurst:
                return 'Burst pipe creates negative pressure triggering backflow from house.';
            case isHoseSubmergedInPond:
                return 'Submerged hose creates negative pressure triggering backflow from house.';
            case !isHouseholdBoundaryBoxOpen:
                return 'Closed boundary box creates negative pressure triggering backflow from house.';
            case !isStopCockOpen:
                return 'Closed stopcock creates negative pressure triggering backflow from house.';
            default:
                // return 'State not recognised.';
                return '';
        }
    };

    useEffect(() => {
        setSpring({ opacity: 0, transform: 'translateY(3vw)', config: { immediate: true } });
        if (isHouseholdNegativePressure)
            setSpring({
                opacity: 1,
                transform: 'translateY(0vw)',
                config: { immediate: false },
            });
    }, [isHouseholdNegativePressure]);

    return <>
        {parseInt(scenario) === SCENARIOS.HOUSEHOLD && 
            <PanelStyles visible={isHouseholdNegativePressure}>
                <PanelHeading style={spring[isHouseholdNegativePressure ? 0 : 1]}>Negative Pressure</PanelHeading>
                <PanelDescription style={spring[isHouseholdNegativePressure ? 1 : 0]}>{panelDescription()}</PanelDescription>
            </PanelStyles>}
    </>
};

const Contamination = ({ scenario }) => {
    const [ spring, setSpring ] = useTrail(2, () => ({
        from: {
            opacity: 0,
            transform: 'translateY(3vw)',
        },
        config: config.default,
    }));
    const isContamination = useRecoilValue(contaminationAtomFamily(parseInt(scenario))).length ? true : false;
    const isSeepage = useRecoilValue(isSeepageSelector);
    const isContaminatedPipe = useRecoilValue(isContaminatedPipeSelector);
    const isFillLoopConnected = useRecoilValue(isFillLoopConnectedSelector);
    const isSinkFilterFitted = useRecoilValue(isSinkFilterFittedSelector);
    const isSCVFitted = useRecoilValue(isSCVFittedSelector);
    const isHouseholdIncorrectAirGap = useRecoilValue(isHouseholdIncorrectAirGapSelector);
    const isOutsideTapRunning = useRecoilValue(isOutsideTapRunningSelector);
    const isShowerHeadImmersed = useRecoilValue(isShowerHeadImmersedSelector);

    const panelDescription = () => {
        switch (true) {
            // case !isSeepage:
            //     return 'Contamination from seepage.';
            case isContaminatedPipe:
                return 'Contamination from lead pipe.';
            case !isFillLoopConnected:
                return 'Contamination from heating system.';
            case !isSinkFilterFitted:
                return 'Contamination of kitchen supply.';
            case !isSCVFitted:
                return 'Incorrect Washing machine connection.';
            case isHouseholdIncorrectAirGap:
                return 'Contamination from toilet cistern (sanitary disinfectant block).';
            case isOutsideTapRunning:
                return 'Syphon effect from submerged hose pipe.';
            case isShowerHeadImmersed:
                return 'Syphon effect from submerged shower head.';
            default:
                // return 'State not recognised.';
                return '';
        }
    };

    useEffect(() => {
        setSpring({ opacity: 0, transform: 'translateY(3vw)', config: { immediate: true } });
        if (isContamination)
            setSpring({
                opacity: 1,
                transform: 'translateY(0vw)',
                config: { immediate: false },
            });
    }, [isContamination]);

    return <PanelStyles visible={isContamination}>
        <PanelHeading style={spring[isContamination ? 0 : 1]}>Contamination</PanelHeading>
        <PanelDescription style={spring[isContamination ? 1 : 0]}>{panelDescription()}</PanelDescription>
    </PanelStyles>
};

const Info = ({ scenario }) => {
    return <Portal id='info-container'>
        <ContainerStyles>
            <NegativePressure scenario={scenario} />
            <Contamination scenario={scenario} />
        </ContainerStyles>
    </Portal>
};

export default Info;