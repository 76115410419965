import HouseholdSlide1 from '../img/slides/household-1.jpg';
import HouseholdSlide2 from '../img/slides/household-2.jpg';
import HouseholdSlide3 from '../img/slides/household-3.jpg';
import HouseholdSlide4 from '../img/slides/household-4.jpg';

import AgriculturalSlide1 from '../img/slides/agricultural-1.jpg';
import AgriculturalSlide2 from '../img/slides/agricultural-2.jpg';
import AgriculturalSlide3 from '../img/slides/agricultural-3.jpg';
import AgriculturalSlide4 from '../img/slides/agricultural-4.jpg';

import NonHouseholdSlide1 from '../img/slides/non-household-1.jpg';
import NonHouseholdSlide2 from '../img/slides/non-household-2.jpg';
import NonHouseholdSlide3 from '../img/slides/non-household-3.jpg';
import NonHouseholdSlide4 from '../img/slides/non-household-4.jpg';

export const WATER_QUALITY = {
    // legacy
    CLEAN:   1,
    TAINTED: 2,
    TOXIC:   3,
    // new
    PINK:    4,
    ORANGE:  5,
    BROWN:   6,
    YELLOW:  7,
    MUSTARD: 8,
    RED:     9,
    GREY:    10,
    BLACK:   11,
    CREAM:   12,
    GREEN:   13,
};

export const WATER_COLORS = {
    // legacy
    [WATER_QUALITY.CLEAN]:   '#005FE7',
    [WATER_QUALITY.TAINTED]: '#CCCC00',
    [WATER_QUALITY.TOXIC]:   '#FF9999',
    // new
    [WATER_QUALITY.PINK]:    '#ec96c0',
    [WATER_QUALITY.ORANGE]:  '#f8aa14',
    [WATER_QUALITY.BROWN]:   '#bf5d17',
    [WATER_QUALITY.YELLOW]:  '#f6f096',
    [WATER_QUALITY.MUSTARD]: '#d1b62e',
    [WATER_QUALITY.RED]:     '#e62a34',
    [WATER_QUALITY.GREY]:    '#c6c6c6',
    [WATER_QUALITY.BLACK]:   '#575756',
    [WATER_QUALITY.CREAM]:   '#fffbdc',
    [WATER_QUALITY.GREEN]:   '#a7c838',
};

export const SCENARIOS = {
    HOUSEHOLD:     1,
    AGRICULTURAL:  2,
    NON_HOUSEHOLD: 3,
};

export const SCENARIO_NAMES = {
    [SCENARIOS.HOUSEHOLD]:     'Household',
    [SCENARIOS.AGRICULTURAL]:  'Agricultual',
    [SCENARIOS.NON_HOUSEHOLD]: 'Non household',
};

export const SCENARIO_COLORS = {
    [SCENARIOS.HOUSEHOLD]: {
        BACKGROUND: '#87189D',
        ROOMS:      '#003E51',
        ITEMS:      '#003E51',
        WIDGETS:    '#003E51',
        OCCUPANTS:  '#D0D0CE',
    },
    [SCENARIOS.AGRICULTURAL]: {
        BACKGROUND: '#7CB544',
        WIDGETS:    '#003E51',
    },
    [SCENARIOS.NON_HOUSEHOLD]: {
        BACKGROUND: '#009FFF', // #3796C3
        WIDGETS:    '#003E51',
    },
};

export const DEFAULT_SCENARIO = SCENARIOS.HOUSEHOLD;

export const SLIDES = {
    [SCENARIOS.HOUSEHOLD]: [
        HouseholdSlide1,
        HouseholdSlide2,
        HouseholdSlide3,
        HouseholdSlide4,
    ],
    [SCENARIOS.AGRICULTURAL]: [
        AgriculturalSlide1,
        AgriculturalSlide2,
        AgriculturalSlide3,
        AgriculturalSlide4,
    ],
    [SCENARIOS.NON_HOUSEHOLD]: [
        NonHouseholdSlide1,
        NonHouseholdSlide2,
        NonHouseholdSlide3,
        NonHouseholdSlide4,
    ],
};

const PIPE_SEGMENTS = {
    // household
    H:          { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: true,  strokeWidth: 120, d: 'M 546  2674 H -1000' }, // -1000 was 0
    MA:         { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: true,  strokeWidth: 120, d: 'M 1705 2674 H 546' },
    MB:         { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: true,  strokeWidth: 120, d: 'M 3937 2674 H 1705' },
    MC:         { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: true,  strokeWidth: 120, d: 'M 6120 2674 H 3937' }, // 6120 was 5120
    H1:         [
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M3936 2511V2424C3936 2371 3885 2371 3885 2371C3885 2371 3755.67 2371 3706 2371' },
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M3936 2614V2511' },
    ],
    H2:         { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M3706.5 2371H3334.5' },
    H4:         { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M3334.5 2371C3334.5 2371 2882 2371 2835.5 2371C2789 2371 2786.5 2322 2786.5 2322V2232.5' },
    H5:         [
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M2228 1377C2228 1377 2276 1377 2318.5 1377C2361 1377 2361 1334.5 2361 1334.5V1313.5' },
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M 1265 1377 H 2228' },
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M1263 1377V1118' },
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M1263 1826V1376' },
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M1998 2230C1998 2230 1341 2230 1304 2230C1267 2230 1263 2189 1263 2189V1826' },
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M1998 2230L1998 2068' },
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M2530 2230H1998' },
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M2791 2230H2530' },
    ],
    H6:         [
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M 1998 1787 L 1998 1702' },
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M 1998 2068 L 1998 1787' },
    ],
    H8:         { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M1998.5 1787H1822.5' },
    H9:         { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M1822.5 1787C1802.5 1787 1777 1781.5 1760.5 1799C1744 1816.5 1754 1849 1724 1849C1686.5 1849 1695 1797.5 1687 1783C1678.85 1768.23 1668 1755 1653 1755' },
    H6a:         [
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M2560 2216.5V2059C2559.67 2047.17 2566.3 2023.5 2595.5 2023.5C2624.7 2023.5 2629.33 2023.5 2628 2023.5' },
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M2628 2023.5H2956.5' },
    ],
    H7:         [
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M2629 2021V1935L2629 1862' },
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M2631.19 1935.5L2631.19 1862.5C2629.85 1850.67 2635.09 1827 2666.69 1827H2867.19' },
    ],
    H10:        { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M1263.5 1826H1040' },
    T13H_hose:  [
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 20,  displayToggle: 'T13H', displayToggleState: true,  strokeMiterlimit: 10, strokeLinecap: 'round', isHose: true, d: 'M699.328 1906.89C705.625 1906.49 712.116 1906.29 718.746 1906.29C777.877 1906.29 825.813 1922.63 825.813 1942.79C825.813 1962.95 777.877 1979.3 718.746 1979.3C718.746 1979.3 441.4 1995.39 717.132 2035.49' },
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 20,  displayToggle: 'T13H', displayToggleState: true,  strokeMiterlimit: 10, strokeLinecap: 'round', isHose: true, d: 'M686.967 1923.93C686.967 1944.09 734.901 1960.44 794.032 1960.44C853.163 1960.44 901.099 1944.09 901.099 1923.93C901.099 1903.77 853.163 1887.43 794.032 1887.43C734.901 1887.43 686.967 1903.77 686.967 1923.93Z' },
                { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 20,  displayToggle: 'T13H', displayToggleState: true,  strokeMiterlimit: 10, strokeLinecap: 'round', isHose: true, d: 'M870.972 1758.15V1866.45C870.972 1866.45 879.591 1912.58 839.5 1938.63L772.673 1959.3' },
    ],
    H12:         [
        { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M2233 1393V1109.5' },
        { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M2233 1109.5V826C2233 795.5 2221.9 771.5 2177.5 771.5H2158' },
    ],
    H12S:       { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 16, strokeMiterlimit: 10, strokeLinecap: 'round', d: 'M1976.76 1091.46C1982.71 1086.09 1990.22 1082.87 1998.37 1082.87C2017.5 1082.87 2033.15 1100.62 2033.15 1122.32L2033.47 1145.54V1182.36C2033.47 1204.06 2049.12 1221.81 2068.25 1221.81C2087.39 1221.81 2103.04 1204.06 2103.04 1182.36V1137.72V1091.48V897.486' },
    H13:        { type: 'pipeSegment', scenario: SCENARIOS.HOUSEHOLD,     mains: false, strokeWidth: 31,  d: 'M2485.67 1016.02V1014.96C2485.67 1038.99 2466.01 1058.66 2441.98 1058.66H2437.51H2414.03H2404.23C2380.2 1058.66 2360.54 1078.32 2360.54 1102.35V1325.4' },
    // agricultural
    A:          { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: true,  strokeWidth: 120, d: 'M 2156 2673 H -1000' },
    MD:         { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: true,  strokeWidth: 120, d: 'M2513 2673H2156' },
    ME:         { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: true,  strokeWidth: 120, d: 'M3664 2673H2513' },
    MF:         { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: true,  strokeWidth: 120, d: 'M4230 2673H3664' },
    MG:         { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: true,  strokeWidth: 120, d: 'M 6120 2673 H 4230' },
    A3_0:       { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: false, strokeWidth: 31,  d: 'M 2513.5 2622 V 2404.5' },
    A3_1:       { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: false, strokeWidth: 31,  d: 'M2513.5 2404.5V2104.5' },
    A4:         { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: false, strokeWidth: 31,  d: 'M 4228 2624 V 2142' },
    A3a:        { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: false, strokeWidth: 31,  d: 'M2513.5 2104.5H2558.5C2582.83 2105.17 2631.5 2091.5 2631.5 2031.5C2631.5 1971.5 2631.5 938.5 2631.5 429.5C2631.5 392.5 2633.5 371.5 2689.5 371.5C2745.5 371.5 2800.5 371.5 2821 371.5' },
    A3b_0:      { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: false, strokeWidth: 31,  d: 'M2513.5 2104.5H2467C2444.67 2103.67 2397 2089.1 2397 2037.5C2397 1985.9 2397 1929.33 2397 1907.5' },
    A3b_1:      { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: false, strokeWidth: 31,  d: 'M2397 1907.5V536C2397 504.5 2394.5 483.5 2344.5 483.5C2294.5 483.5 1704.33 483.5 1415.5 483.5' },
    A4Low:      { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: false, strokeWidth: 31,  displayToggle: 'T4A', displayToggleState: false, d: 'M4228 2144V2110C4228 2081 4227.1 2061.5 4179.5 2061.5C4131.9 2061.5 4134 2061.5 4141 2061.5' },
    A4High:     { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: false, strokeWidth: 31,  displayToggle: 'T4A', displayToggleState: true,  d: 'M4227.99 2144L4227.99 2015C4227.99 1986 4237 1936.5 4181 1936.5C4133.4 1936.5 4141 1936.5 4148 1936.5' },
    // A5:         { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: false, strokeWidth: 31,  displayToggle: 'T5A', displayToggleState: true,  d: 'M2397 1905.5H921.5' },
    A5: [
        { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: false, strokeWidth: 31,  displayToggle: 'T5A', displayToggleState: true,                                               d: 'M 2384 1901 H 1207.5' },          // large part
        { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: false, strokeWidth: 31,  displayToggle: 'T5A', displayToggleState: true, defaultContamination: WATER_QUALITY.BROWN,  d: 'M 1217.5 1901 H 1076.25 H 945' }, // small part
    ],
    A6_0:       { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: false, strokeWidth: 31,  defaultContamination: WATER_QUALITY.BROWN, d: 'M920.5 2071.5V1906' },
    A6_1:       { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: false, strokeWidth: 31,  defaultContamination: WATER_QUALITY.BROWN, d: 'M920.5 1906V1531.5' },
    A6_2:       { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: false, strokeWidth: 31,  defaultContamination: WATER_QUALITY.BROWN, d: 'M920.5 1531.5H1064' },
    A6_3:       { type: 'pipeSegment', scenario: SCENARIOS.AGRICULTURAL,  mains: false, strokeWidth: 31,  defaultContamination: WATER_QUALITY.BROWN, d: 'M920.5 1531.5V564.5C920.833 549.333 930.4 519 966 519C1001.6 519 1001.5 519 997 519' },
    // non household
    N:          { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: true,  strokeWidth: 120, d: 'M574.5 2655H2' },
    MH:         { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: true,  strokeWidth: 120, d: 'M922 2655H572.5' },
    MI:         { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: true,  strokeWidth: 120, d: 'M2587 2655H921.5' },
    MJ:         { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: true,  strokeWidth: 120, d: 'M4870.5 2V2496C4870.5 2549.67 4838.3 2657 4709.5 2657L2587 2655' },

    N1_0:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M574.5 2480V2655' },
    N1_1:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M574.5 2480V2262' },
    N1_2:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M574.5 2262V2102' },
    N1_3:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M574.5 2102V894C574.5 878 584.1 846 622.5 846C670.5 846 683.5 876 683.5 907' },

    N2_0:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M574.5 2102H778.5C796.167 2100 831.5 2107.8 831.5 2155C831.5 2202.2 831.5 2291.33 831.5 2330C831.5 2344.5 840.2 2373.5 875 2373.5C909.8 2373.5 1155.83 2373.5 1274.5 2373.5' },
    N2_1:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M1274.5 2373.5H1864.5' },
    N2_2:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M1864.5 2373.5H3310.5' },
    N2_3:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M3310.5 2373.5H3922.5C3951.83 2377.33 4010.5 2365.1 4010.5 2285.5C4010.5 2205.9 4010.5 1947.33 4010.5 1828C4007.83 1804.67 3990.1 1758 3940.5 1758C3890.9 1758 3882.17 1758 3884 1758' },

    N3_0:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M1864.5 2373.5V2098' },
    N3_1:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M1864.5 2098V1751' },
    N3_2:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M1864.5 1751V1506' },
    N3_3:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M1864.5 1506V1334' },
    N3_4:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M1864.5 1334V1174C1863.67 1157.33 1872.5 1124 1914.5 1124C1956.5 1124 2014.67 1124 2038.5 1124' },

    N4:         { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M1864.5 2098H2107C2131.17 2098.33 2179.5 2084.3 2179.5 2025.5V2016' },
    N5:         { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M1864.5 1751H1822' },

    N6_0:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M1864.5 1506 H 1564' },
    N6_1:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M1581.5 1506V1378.5V1357.5' },
    N6_2:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M1581.5 1378.5V1357.5C1585 1333.17 1575.3 1284.5 1508.5 1284.5C1441.7 1284.5 1373.33 1284.5 1347.5 1284.5' },
    
    N7:         { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M2217 1245.5V1506' },
    N7_a:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M2217 1506H2101C2086.5 1504.83 2057.5 1511.9 2057.5 1549.5C2057.5 1587.1 2057.5 1672.17 2057.5 1710' },
    N7_b:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M2217 1506V1661' },
    N7_c:       { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M2217 1506H2331C2346.83 1505 2378.5 1513.1 2378.5 1553.5C2378.5 1593.9 2378.5 1674.67 2378.5 1710' },

    NDeadLeg:   { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  displayToggle: 'NDeadLeg', displayToggleState: true,  defaultContamination: WATER_QUALITY.TAINTED,  d: 'M1561.5 1506 H 1347.5' },

    NFPS_Feed:  { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  d: 'M2584 2595.5V2563.5' },
    
    NStoreFeed: { type: 'pipeSegment', scenario: SCENARIOS.NON_HOUSEHOLD, mains: false, strokeWidth: 31,  defaultContamination: WATER_QUALITY.TAINTED, d: 'M1013.06 1790.15H984.68C963 1790.15 944.2 1772.81 944.2 1751.62V1655.99C944.2 1634.79 925.4 1617.46 902.42 1617.46H833.36' },
};

export const PIPES = Object.entries(PIPE_SEGMENTS).reduce((arr, [ id, segment ]) => {
    if (Array.isArray(segment)) {
        segment.map(({ type, ...segment }, idx) => arr.push({ id: `${id}_${idx}`, ...segment }));
    }
    else {
        const { type, ...props } = segment;
        arr.push({ id, ...props });
    }
    return arr;
}, []);

export const HYDRANT_STATES = ['Safe','Unsafe','High Pressure'];

export const PIPE_MATERIALS = ['MDPE','Copper','Barrier'];

export const DCV_STATES = ['Without DCV','With DCV'];

export const SCV_STATES = ['Without SCV','With SCV'];

// export const SINK_FILTERS = ['Non-Maintained','Maintained'];

export const RPZ_STATES = ['None','SCV','RPZ'];

const ACTIONS = {
    // household
    T1H_OnOff:   { type: 'hotspot', description: 'Hydrant On / Off',         scenario: SCENARIOS.HOUSEHOLD, theme: 'light', value: false, exclusive: 0,     reverseActive: false, multiChoice: false,                                                                                                d: 'M510.5 2366C531.763 2366 549 2348.76 549 2327.5C549 2306.24 531.763 2289 510.5 2289C489.237 2289 472 2306.24 472 2327.5C472 2348.76 489.237 2366 510.5 2366Z' },
    // T1H_DCV:     { type: 'hotspot', description: 'Hydrant DCV',              scenario: SCENARIOS.HOUSEHOLD, theme: 'light', value: true,  exclusive: 1,     reverseActive: true,  multiChoice: false,  selected: 'With DCV', default: 'With DCV', correct: 'With DCV', options: DCV_STATES,           d: 'M613.31 2550.01C668.71 2550.01 713.62 2505.1 713.62 2449.7C713.62 2394.3 668.71 2349.39 613.31 2349.39C557.91 2349.39 513 2394.3 513 2449.7C513 2505.1 557.91 2550.01 613.31 2550.01Z' },
    T13H_DCV:    { type: 'hotspot', description: 'Outside Tap DCV',          scenario: SCENARIOS.HOUSEHOLD, theme: 'dark',  value: false,  exclusive: 1,     reverseActive: false,  multiChoice: false,  selected: 'With DCV', default: 'With DCV', correct: 'With DCV', options: DCV_STATES,           d: 'M1104.31 1923.62C1159.71 1923.62 1204.62 1878.71 1204.62 1823.31C1204.62 1767.91 1159.71 1723 1104.31 1723C1048.91 1723 1004 1767.91 1004 1823.31C1004 1878.71 1048.91 1923.62 1104.31 1923.62Z' },
    T9H_OnOff:   { type: 'hotspot', description: 'Washing Machine On / Off', scenario: SCENARIOS.HOUSEHOLD, theme: 'dark',  value: false, exclusive: null,  reverseActive: false, multiChoice: false,                                                                                                d: 'M1541.31 1784.62C1596.71 1784.62 1641.62 1739.71 1641.62 1684.31C1641.62 1628.91 1596.71 1584 1541.31 1584C1485.91 1584 1441 1628.91 1441 1684.31C1441 1739.71 1485.91 1784.62 1541.31 1784.62Z' },
    T9H:         { type: 'hotspot', description: 'Washing Machine SCV',      scenario: SCENARIOS.HOUSEHOLD, theme: 'dark',  value: false,  exclusive: 0,     reverseActive: false,  multiChoice: false,  selected: 'With SCV', default: 'With SCV', correct: 'With SCV', options: SCV_STATES,           d: 'M1869.31 1885.62C1924.71 1885.62 1969.62 1840.71 1969.62 1785.31C1969.62 1729.91 1924.71 1685 1869.31 1685C1813.91 1685 1769 1729.91 1769 1785.31C1769 1840.71 1813.91 1885.62 1869.31 1885.62Z' },
    T11H_DCV:    { type: 'hotspot', description: 'Shower DCV',               scenario: SCENARIOS.HOUSEHOLD, theme: 'dark',  value: false,  exclusive: null,  reverseActive: false,  multiChoice: false,  selected: 'With DCV', default: 'With DCV', correct: 'With DCV', options: DCV_STATES,           d: 'M2224.31 1197.62C2279.71 1197.62 2324.62 1152.71 2324.62 1097.31C2324.62 1041.91 2279.71 997 2224.31 997C2168.91 997 2124 1041.91 2124 1097.31C2124 1152.71 2168.91 1197.62 2224.31 1197.62Z' },

    T1H:         { type: 'hotspot', description: 'Hydrant',             scenario: SCENARIOS.HOUSEHOLD,     theme: 'light', value: false, exclusive: 0,     reverseActive: false, multiChoice: true,  selected: 'Safe', default: 'Safe', correct: 'Safe', options: HYDRANT_STATES,                   d: '' },
    T2H:         { type: 'hotspot', description: 'Pipe burst',          scenario: SCENARIOS.HOUSEHOLD,     theme: 'light', value: false, exclusive: 0,     reverseActive: false, multiChoice: false,                                                                                                d: 'M1615 2724.86V2620.95L1652.09 2599.54L1704.99 2569L1794.98 2620.95V2724.86L1704.99 2776.82L1615 2724.86Z' },
    T3H:         { type: 'hotspot', description: 'Boundary box',        scenario: SCENARIOS.HOUSEHOLD,     theme: 'light', value: true,  exclusive: 0,     reverseActive: true,  multiChoice: false,                                                                                                d: 'M3847 2562.86V2458.95L3884.09 2437.54L3936.99 2407L4026.98 2458.95V2562.86L3936.99 2614.82L3847 2562.86Z' },
    T4HSeepage:  { type: 'hotspot', description: 'Seepage',             scenario: SCENARIOS.HOUSEHOLD,     theme: 'light', value: false, exclusive: null,  reverseActive: false, multiChoice: false,                                                                                                d: 'M3617 2200.86V2115.95L3654.09 2094.54L3706.99 2064L3796.98 2115.95V2200.86L3706.99 2271.82L3617 2200.86Z' },
    T4H:         { type: 'hotspot', description: 'Pipe material',       scenario: SCENARIOS.HOUSEHOLD,     theme: 'light', value: true,  exclusive: 1,     reverseActive: true,  multiChoice: true,  selected: 'Barrier', default: 'Barrier', correct: 'Barrier', options: PIPE_MATERIALS,          d: 'M3617 2419.86V2315.95L3654.09 2294.54L3706.99 2264L3796.98 2315.95V2419.86L3706.99 2471.82L3617 2419.86Z' },
    T5H:         { type: 'hotspot', description: 'Contaminated pipe',   scenario: SCENARIOS.HOUSEHOLD,     theme: 'light', value: false, exclusive: 0,     reverseActive: false, multiChoice: false,                                                                                                d: 'M3245 2419.86V2315.95L3282.09 2294.54L3334.99 2264L3424.98 2315.95V2419.86L3334.99 2471.82L3245 2419.86Z' },
    T13H:        { type: 'hotspot', description: 'Submerged hose',      scenario: SCENARIOS.HOUSEHOLD,     theme: 'light', value: false, exclusive: 3,     reverseActive: false, multiChoice: false,                                                                                                d: 'M300 1975 V1871 L886 2050 L938 1820 L1028 2071 V1875 L938 2027 L300 1975 Z' },
    T6H:         { type: 'hotspot', description: 'Stopcock',            scenario: SCENARIOS.HOUSEHOLD,     theme: 'dark',  value: true,  exclusive: 0,     reverseActive: true,  multiChoice: false,                                                                                                d: 'M2698 2276.86V2172.95L2735.09 2151.54L2787.99 2121L2877.98 2172.95V2276.86L2787.99 2328.82L2698 2276.86Z' },
    T7H:         { type: 'hotspot', description: 'Fill loop',           scenario: SCENARIOS.HOUSEHOLD,     theme: 'dark',  value: false, exclusive: 1,     reverseActive: false, multiChoice: false,  selected: 'With DCV', default: 'With DCV', correct: 'With DCV', options: DCV_STATES,          d: 'M2627.31 2037.62C2682.71 2037.62 2727.62 1992.71 2727.62 1937.31C2727.62 1881.91 2682.71 1837 2627.31 1837C2571.91 1837 2527 1881.91 2527 1937.31C2527 1992.71 2571.91 2037.62 2627.31 2037.62Z' },
    // T8H:         { type: 'hotspot', description: 'Sink filter',         scenario: SCENARIOS.HOUSEHOLD,     theme: 'dark',  value: false, exclusive: null,  reverseActive: false, multiChoice: true,  selected: 'Maintained', default: 'Maintained', correct: 'Maintained', options: SINK_FILTERS,   d: 'M1908 2120.86V2016.95L1945.09 1995.54L1997.99 1965L2087.98 2016.95V2120.86L1997.99 2172.82L1908 2120.86Z' },
    T8H:         { type: 'hotspot', description: 'Sink filter',         scenario: SCENARIOS.HOUSEHOLD,     theme: 'dark',  value: true, exclusive: null,  reverseActive: true, multiChoice: false,                                                                                                d: 'M1908 2120.86V2016.95L1945.09 1995.54L1997.99 1965L2087.98 2016.95V2120.86L1997.99 2172.82L1908 2120.86Z' },
    // T9H:         { type: 'hotspot', description: 'SCV',                 scenario: SCENARIOS.HOUSEHOLD,     theme: 'dark',  value: true,  exclusive: 0,     reverseActive: true,  multiChoice: false,                                                                                                d: 'M1738 1839.86V1735.95L1775.09 1714.54L1827.99 1684L1917.98 1735.95V1839.86L1827.99 1891.82L1738 1839.86Z' },
    T10H:        { type: 'hotspot', description: 'Sink tap',            scenario: SCENARIOS.HOUSEHOLD,     theme: 'dark',  value: false, exclusive: null,  reverseActive: false, multiChoice: false,                                                                                                d: 'M1950 1634.86V1530.95L1987.09 1509.54L2039.99 1479L2129.98 1530.95V1634.86L2039.99 1686.82L1950 1634.86Z' },
    T11H:        { type: 'hotspot', description: 'Bath tap',            scenario: SCENARIOS.HOUSEHOLD,     theme: 'dark',  value: false, exclusive: null,  reverseActive: false, multiChoice: false,                                                                                                d: 'M1204 1107.86V1003.95L1241.09 982.539L1293.99 952L1383.98 1003.95V1107.86L1293.99 1159.82L1204 1107.86Z'  },
    T12HCistern: { type: 'hotspot', description: 'Air gap',             scenario: SCENARIOS.HOUSEHOLD,     theme: 'dark',  value: false, exclusive: 1,     reverseActive: false, multiChoice: false,                                                                                                d: 'M2354 840.864V736.955L2541.09 715.539L2593.99 685L2683.98 736.955V840.864L2593.99 892.819L2354 840.864Z'  },
    // HOffLeft:    { type: 'hotspot', description: 'Offscreen Left',      scenario: SCENARIOS.HOUSEHOLD,     theme: 'light', value: false, exclusive: 'all', reverseActive: false, multiChoice: false,                                                                                                d: 'M149.31 2619.62C204.71 2619.62 249.62 2574.71 249.62 2519.31C249.62 2463.91 204.71 2419 149.31 2419C93.9103 2419 49 2463.91 49 2519.31C49 2574.71 93.9103 2619.62 149.31 2619.62Z' },
    HOffRight:   { type: 'hotspot', description: 'Offscreen Right',     scenario: SCENARIOS.HOUSEHOLD,     theme: 'light', value: false, exclusive: 'all', reverseActive: false, multiChoice: false,                                                                                                d: 'M4996.31 2619.62C5051.71 2619.62 5096.62 2574.71 5096.62 2519.31C5096.62 2463.91 5051.71 2419 4996.31 2419C4940.91 2419 4896 2463.91 4896 2519.31C4896 2574.71 4940.91 2619.62 4996.31 2619.62Z' },
    // HOffRight:   { type: 'hotspot', description: 'Offscreen Right',     scenario: SCENARIOS.HOUSEHOLD,     theme: 'light', value: false, exclusive: 'all', reverseActive: false, multiChoice: false,                                                                                                d: '' },
    
    
    // agricultural
    // T6A_2Bar:    { type: 'hotspot', description: 'Borehole Pump 2 BAR',      scenario: SCENARIOS.AGRICULTURAL,  theme: 'light', value: false, exclusive: 5, reverseActive: false, multiChoice: false, d: 'M303.5 2115C324.763 2115 342 2097.76 342 2076.5C342 2055.24 324.763 2038 303.5 2038C282.237 2038 265 2055.24 265 2076.5C265 2097.76 282.237 2115 303.5 2115Z' },
    T6A_8Bar:    { type: 'hotspot', description: 'Borehole Pump 8 BAR',      scenario: SCENARIOS.AGRICULTURAL,  theme: 'light', value: false, exclusive: 5, reverseActive: false, multiChoice: false, d: 'M560.5 2116C581.763 2116 599 2098.76 599 2077.5C599 2056.24 581.763 2039 560.5 2039C539.237 2039 522 2056.24 522 2077.5C522 2098.76 539.237 2116 560.5 2116Z' },
    T6A_Stoptap: { type: 'hotspot', description: 'Cross Connection Stoptap', scenario: SCENARIOS.AGRICULTURAL,  theme: 'light', value: false, exclusive: 4, reverseActive: false, multiChoice: false, displayToggle: 'T5A', displayToggleState: true, d: 'M1203.94 1993.4C1259.34 1993.4 1304.25 1948.49 1304.25 1893.09C1304.25 1837.69 1259.34 1792.78 1203.94 1792.78C1148.54 1792.78 1103.63 1837.69 1103.63 1893.09C1103.63 1948.49 1148.54 1993.4 1203.94 1993.4Z' },

    T1A:         { type: 'hotspot', description: 'Pipe burst 1',        scenario: SCENARIOS.AGRICULTURAL,  theme: 'light', value: false, exclusive: 2,     reverseActive: false, multiChoice: false,                                                                                              d: 'M2258.05 2673.2C2258.05 2730.02 2211.98 2776.09 2155.16 2776.09C2098.34 2776.09 2052.28 2730.02 2052.28 2673.2C2052.28 2616.38 2098.34 2570.32 2155.16 2570.32C2211.98 2570.32 2258.05 2616.38 2258.05 2673.2Z' },
    // T2A:         { type: 'hotspot', description: 'Pipe burst 2',        scenario: SCENARIOS.AGRICULTURAL,  theme: 'light', value: false, exclusive: 2,     reverseActive: false, multiChoice: false,                                                                                              d: 'M3765.22 2669.04C3765.22 2725.86 3719.16 2771.93 3662.34 2771.93C3605.52 2771.93 3559.45 2725.86 3559.45 2669.04C3559.45 2612.22 3605.52 2566.15 3662.34 2566.15C3719.16 2566.15 3765.22 2612.22 3765.22 2669.04Z' },
    // T3A:         { type: 'hotspot', description: 'Boundary box',        scenario: SCENARIOS.AGRICULTURAL,  theme: 'light', value: true,  exclusive: 2,     reverseActive: true,  multiChoice: false,                                                                                              d: 'M2607.56 2388.48C2607.56 2445.31 2561.5 2491.37 2504.68 2491.37C2447.86 2491.37 2401.79 2445.31 2401.79 2388.48C2401.79 2331.66 2447.86 2285.6 2504.68 2285.6C2561.5 2285.6 2607.56 2331.66 2607.56 2388.48Z' },
    T4A:         { type: 'hotspot', description: 'Air gap',             scenario: SCENARIOS.AGRICULTURAL,  theme: 'light', value: true,  exclusive: 3,     reverseActive: true,  multiChoice: false,                                                                                              d: 'M4331.12 2066.38C4331.12 2123.21 4285.06 2169.27 4228.23 2169.27C4171.41 2169.27 4125.35 2123.21 4125.35 2066.38C4125.35 2009.56 4171.41 1963.5 4228.23 1963.5C4285.06 1963.5 4331.12 2009.56 4331.12 2066.38Z' },
    T5A:         { type: 'hotspot', description: 'Cross connection',    scenario: SCENARIOS.AGRICULTURAL,  theme: 'light', value: false, exclusive: 3,     reverseActive: false, multiChoice: false,                                                                                              d: 'M1023.72 1905.3C1023.72 1962.12 977.656 2008.19 920.833 2008.19C864.011 2008.19 817.948 1962.12 817.948 1905.3C817.948 1848.48 864.011 1802.42 920.833 1802.42C977.656 1802.42 1023.72 1848.48 1023.72 1905.3Z' },
    T6A:         { type: 'hotspot', description: 'Borehole pump',       scenario: SCENARIOS.AGRICULTURAL,  theme: 'light', value: false, exclusive: 4,     reverseActive: false, multiChoice: false,                                                                                              d: '' },
    AOffLeft:    { type: 'hotspot', description: 'Offscreen Left',      scenario: SCENARIOS.AGRICULTURAL,  theme: 'light', value: false, exclusive: 'all', reverseActive: false, multiChoice: false,                                                                                              d: 'M149.31 2619.62C204.71 2619.62 249.62 2574.71 249.62 2519.31C249.62 2463.91 204.71 2419 149.31 2419C93.9103 2419 49 2463.91 49 2519.31C49 2574.71 93.9103 2619.62 149.31 2619.62Z' },
    AOffRight:   { type: 'hotspot', description: 'Offscreen Right',     scenario: SCENARIOS.AGRICULTURAL,  theme: 'light', value: false, exclusive: 'all', reverseActive: false, multiChoice: false,                                                                                              d: 'M4996.31 2619.62C5051.71 2619.62 5096.62 2574.71 5096.62 2519.31C5096.62 2463.91 5051.71 2419 4996.31 2419C4940.91 2419 4896 2463.91 4896 2519.31C4896 2574.71 4940.91 2619.62 4996.31 2619.62Z' },
    // AOffLeft:    { type: 'hotspot', description: 'Offscreen Left',      scenario: SCENARIOS.HOUSEHOLD,     theme: 'light', value: false, exclusive: 'all', reverseActive: false, multiChoice: false,                                                                                                d: '' },
    // AOffRight:   { type: 'hotspot', description: 'Offscreen Right',     scenario: SCENARIOS.HOUSEHOLD,     theme: 'light', value: false, exclusive: 'all', reverseActive: false, multiChoice: false,                                                                                                d: '' },
    
    
    // non household
    T1N:           { type: 'hotspot', description: 'Pipe burst 1',        scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'light', value: false, exclusive: 5,     reverseActive: false, multiChoice: false,                                                                                              d: 'M922.98 2783.19C978.38 2783.19 1023.29 2738.28 1023.29 2682.88C1023.29 2627.48 978.38 2582.57 922.98 2582.57C867.58 2582.57 822.67 2627.48 822.67 2682.88C822.67 2738.28 867.58 2783.19 922.98 2783.19Z' },
    T1N_2:         { type: 'hotspot', description: 'Pipe burst 2',        scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'light', value: false, exclusive: 5,     reverseActive: false, multiChoice: false,                                                                                              d: 'M1277.27 2478.97C1332.67 2478.97 1377.58 2434.06 1377.58 2378.66C1377.58 2323.26 1332.67 2278.35 1277.27 2278.35C1221.87 2278.35 1176.96 2323.26 1176.96 2378.66C1176.96 2434.06 1221.87 2478.97 1277.27 2478.97Z' },
    T2N:           { type: 'hotspot', description: 'Boundary box',        scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'light', value: true,  exclusive: 13,     reverseActive: true,  multiChoice: false,                                                                                              d: 'M567.09 2579.99C622.49 2579.99 667.4 2535.08 667.4 2479.68C667.4 2424.28 622.49 2379.37 567.09 2379.37C511.69 2379.37 466.78 2424.28 466.78 2479.68C466.78 2535.08 511.69 2579.99 567.09 2579.99Z' },
    T2N_DCV:       { type: 'hotspot', description: 'DCV',                 scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'light', value: true,  exclusive: 9,     reverseActive: true,  multiChoice: false,                                                                                              d: 'M566.65 2360.08C622.05 2360.08 666.96 2315.17 666.96 2259.77C666.96 2204.37 622.05 2159.46 566.65 2159.46C511.25 2159.46 466.34 2204.37 466.34 2259.77C466.34 2315.17 511.25 2360.08 566.65 2360.08Z' },
    T3N:           { type: 'hotspot', description: 'Van wash',            scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'light', value: false, exclusive: 5,     reverseActive: false, multiChoice: false,                                                                                              d: 'M2876.77 1646.89C2876.77 1703.71 2830.71 1749.77 2773.89 1749.77C2717.06 1749.77 2671 1703.71 2671 1646.89C2671 1590.06 2717.06 1544 2773.89 1544C2830.71 1544 2876.77 1590.06 2876.77 1646.89Z' },
    T4N:           { type: 'hotspot', description: 'Sink tap',            scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'dark',  value: false, exclusive: 6,     reverseActive: false, multiChoice: false,                                                                                              d: 'M2306.77 1884.89C2306.77 1941.71 2260.71 1987.77 2203.89 1987.77C2147.06 1987.77 2101 1941.71 2101 1884.89C2101 1828.06 2147.06 1782 2203.89 1782C2260.71 1782 2306.77 1828.06 2306.77 1884.89Z' },
    T5N:           { type: 'hotspot', description: 'Air gap',             scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'dark',  value: false, exclusive: 7,     reverseActive: false, multiChoice: false,                                                                                              d: 'M1664.77 1735.89C1664.77 1792.71 1618.71 1838.77 1561.89 1838.77C1505.06 1838.77 1459 1792.71 1459 1735.89C1459 1679.06 1505.06 1633 1561.89 1633C1618.71 1633 1664.77 1679.06 1664.77 1735.89Z' },
    // T6N:           { type: 'hotspot', description: 'RPZ',                 scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'dark',  value: true,  exclusive: 7,     reverseActive: true,  multiChoice: true,  selected: 'RPZ', default: 'RPZ', correct: 'RPZ', options: RPZ_STATES,                        d: 'M1575.99 1472.71C1631.39 1472.71 1676.3 1427.8 1676.3 1372.4C1676.3 1317 1631.39 1272.09 1575.99 1272.09C1520.59 1272.09 1475.68 1317 1475.68 1372.4C1475.68 1427.8 1520.59 1472.71 1575.99 1472.71Z' },
    T6N:           { type: 'hotspot', description: 'RPZ',                 scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'dark',  value: true,  exclusive: 7,     reverseActive: true,  multiChoice: false,                                                                                              d: 'M1575.99 1472.71C1631.39 1472.71 1676.3 1427.8 1676.3 1372.4C1676.3 1317 1631.39 1272.09 1575.99 1272.09C1520.59 1272.09 1475.68 1317 1475.68 1372.4C1475.68 1427.8 1520.59 1472.71 1575.99 1472.71Z' },
    T7N:           { type: 'hotspot', description: 'Water optimiser',     scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'dark',  value: false,  exclusive: 8,     reverseActive: false,  multiChoice: false,                                                                                              d: 'M1867.39 1437.4C1922.79 1437.4 1967.7 1392.49 1967.7 1337.09C1967.7 1281.69 1922.79 1236.78 1867.39 1236.78C1811.99 1236.78 1767.08 1281.69 1767.08 1337.09C1767.08 1392.49 1811.99 1437.4 1867.39 1437.4Z' },
    NDeadLeg:      { type: 'hotspot', description: 'Dead Leg',            scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'dark',  value: false, exclusive: 7,    reverseActive: false, multiChoice: false,                                                                                              d: 'M1416.77 1575.99C1455.55 1575.99 1486.99 1544.55 1486.99 1505.77C1486.99 1466.99 1455.55 1435.55 1416.77 1435.55C1377.99 1435.55 1346.55 1466.99 1346.55 1505.77C1346.55 1544.55 1377.99 1575.99 1416.77 1575.99Z' },
    // NSeepage:      { type: 'hotspot', description: 'Seepage',             scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'light', value: false, exclusive: 10,    reverseActive: false, multiChoice: false,                                                                                              d: 'M3309.22 2270.54C3364.62 2270.54 3409.53 2225.63 3409.53 2170.23C3409.53 2114.83 3364.62 2069.92 3309.22 2069.92C3253.82 2069.92 3208.91 2114.83 3208.91 2170.23C3208.91 2225.63 3253.82 2270.54 3309.22 2270.54Z' },
    // NPipeMaterial: { type: 'hotspot', description: 'Pipe Material',       scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'light', value: false, exclusive: 11,    reverseActive: false, multiChoice: false,                                                                                              d: 'M3309.22 2474.68C3364.62 2474.68 3409.53 2429.77 3409.53 2374.37C3409.53 2318.97 3364.62 2274.06 3309.22 2274.06C3253.82 2274.06 3208.91 2318.97 3208.91 2374.37C3208.91 2429.77 3253.82 2474.68 3309.22 2474.68Z' },
    // NPipeMaterial: { type: 'hotspot', description: 'Pipe material',       scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'light', value: true,  exclusive: 1,     reverseActive: true,  multiChoice: true,  selected: 'Barrier', default: 'Barrier', correct: 'Barrier', options: PIPE_MATERIALS,          d: 'M3309.22 2474.68C3364.62 2474.68 3409.53 2429.77 3409.53 2374.37C3409.53 2318.97 3364.62 2274.06 3309.22 2274.06C3253.82 2274.06 3208.91 2318.97 3208.91 2374.37C3208.91 2429.77 3253.82 2474.68 3309.22 2474.68Z' },
    NOffLeft:      { type: 'hotspot', description: 'Offscreen Left',      scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'light', value: false, exclusive: 'all', reverseActive: false, multiChoice: false,                                                                                              d: 'M149.31 2619.62C204.71 2619.62 249.62 2574.71 249.62 2519.31C249.62 2463.91 204.71 2419 149.31 2419C93.9103 2419 49 2463.91 49 2519.31C49 2574.71 93.9103 2619.62 149.31 2619.62Z' },
    // NOffRight:     { type: 'hotspot', description: 'Offscreen Right',     scenario: SCENARIOS.NON_HOUSEHOLD, theme: 'light', value: false, exclusive: 'all', reverseActive: false, multiChoice: false,                                                                                              d: 'M4996.31 2619.62C5051.71 2619.62 5096.62 2574.71 5096.62 2519.31C5096.62 2463.91 5051.71 2419 4996.31 2419C4940.91 2419 4896 2463.91 4896 2519.31C4896 2574.71 4940.91 2619.62 4996.31 2619.62Z' },
    // NOffLeft:    { type: 'hotspot', description: 'Offscreen Left',      scenario: SCENARIOS.HOUSEHOLD,     theme: 'light', value: false, exclusive: 'all', reverseActive: false, multiChoice: false,                                                                                                d: '' },
    NOffRight:   { type: 'hotspot', description: 'Offscreen Right',     scenario: SCENARIOS.HOUSEHOLD,     theme: 'light', value: false, exclusive: 'all', reverseActive: false, multiChoice: false,                                                                                                d: '' },
};

export const HOTSPOTS = Object.entries(ACTIONS).reduce((arr, [ id, { type, ...props } ]) => {
    arr.push({ id, ...props });
    return arr;
}, []);

export const TERMINALS = {
    // household
    T1H:          { scenario: SCENARIOS.HOUSEHOLD,     contaminated: false, off: '', on: '', },
    T2H:          { scenario: SCENARIOS.HOUSEHOLD,     contaminated: false, off: '', on: '', },
    T10H:         { scenario: SCENARIOS.HOUSEHOLD,     contaminated: false, off: '', on: 'M2087.96 1589.2V1755.23', },
    T11H:         { scenario: SCENARIOS.HOUSEHOLD,     contaminated: false, off: '', on: 'M1336.9 1094.41V1225.58', },
    H11_H11a:     { scenario: SCENARIOS.HOUSEHOLD,     contaminated: false, off: 'M1854.8 1284.6H1345.24C1337.66 1285.23 1322.5 1276.5 1322.5 1236.5V1224H1951C1949.2 1235.33 1928.5 1257.5 1908 1269C1884 1285.1 1854.8 1284.6 1854.8 1284.6Z', on: 'M1854.8 1284.6H1345.24C1337.66 1285.23 1322.5 1276.5 1322.5 1236.5V1160H1970C1968.2 1171.33 1961.95 1200 1951.3 1224C1921.76 1282.27 1854.8 1284.6 1854.8 1284.6Z', },
    H14_H15:      { scenario: SCENARIOS.HOUSEHOLD,     contaminated: false, off: 'M2594.08 796.448L2594.07 973.549C2594.07 983.014 2584.74 990.758 2573.32 990.758H2471.59C2460.17 990.758 2450.83 983.014 2450.83 973.549V796.454L2594.08 796.448Z', on: 'M2594.08 736.448L2594.07 973.549C2594.07 983.014 2584.74 990.758 2573.32 990.758H2471.59C2460.17 990.758 2450.83 983.014 2450.83 973.549V736.454L2594.08 736.448Z', },
    NULL_H7:      { scenario: SCENARIOS.HOUSEHOLD,     contaminated: false, off: '', on: 'M2239.44 1770.21C2239.44 1775.85 2235.58 1780.47 2230.87 1780.47H2074.44C2069.72 1780.47 2065.87 1775.85 2065.87 1770.21V1723.77H2239.44V1770.21Z', },
    T13H_Puddle:  { scenario: SCENARIOS.HOUSEHOLD,     contaminated: false, off: '', on: '', },
    T13HLow_High: { scenario: SCENARIOS.HOUSEHOLD,     contaminated: false, off: 'M320.24 2095.96C379.831 2122.5 399.196 2120.57 399.196 2120.57L434.033 2142.78L478.188 2153.08C478.188 2153.08 489.176 2153.15 504.844 2153.08C520.511 2153.02 548.999 2153.08 548.999 2153.08L603.065 2156.6C603.065 2156.6 632.025 2147.15 642.588 2145C653.152 2142.85 688.989 2133.24 688.989 2133.24C688.989 2133.24 728.619 2131.54 739.119 2129.56C749.619 2127.59 734.977 2136.62 766.415 2129.56C797.852 2122.5 789.928 2118.39 807.957 2112.82C825.988 2107.24 839.916 2106.01 851.039 2091.36C862.163 2076.71 872.764 2092.33 883.541 2059.09C886.392 2050.29 889.038 2043.44 891.5 2037.88C891.943 2036.88 892.38 2035.92 892.812 2035H256C256.501 2035.83 257.013 2036.66 257.536 2037.5C260.562 2042.35 263.943 2047.34 267.707 2052.3C280.597 2069.3 297.893 2086.01 320.24 2095.96Z', on: 'M320.24 2095.96C379.831 2122.5 399.196 2120.57 399.196 2120.57L434.033 2142.78L478.188 2153.08C478.188 2153.08 489.176 2153.15 504.844 2153.08C520.511 2153.02 548.999 2153.08 548.999 2153.08L603.065 2156.6C603.065 2156.6 632.025 2147.15 642.588 2145C653.152 2142.85 688.989 2133.24 688.989 2133.24C688.989 2133.24 728.619 2131.54 739.119 2129.56C749.619 2127.59 734.977 2136.62 766.415 2129.56C797.852 2122.5 789.928 2118.39 807.957 2112.82C825.988 2107.24 839.916 2106.01 851.039 2091.36C862.163 2076.71 872.764 2092.33 883.541 2059.09C886.392 2050.29 889.038 2043.44 891.5 2037.88C907.5 2007 914.069 1990.92 914.5 1990H237C237.501 1990.83 242.5 2011 257.536 2037.5C260.562 2042.35 263.943 2047.34 267.707 2052.3C280.597 2069.3 297.893 2086.01 320.24 2095.96Z', },
    // agricultural
    T1A:          { scenario: SCENARIOS.AGRICULTURAL,  contaminated: false, off: '', on: '', },
    // T2A:          { scenario: SCENARIOS.AGRICULTURAL,  contaminated: false, off: '', on: '', },
    T4ALow_High:  { scenario: SCENARIOS.AGRICULTURAL,  contaminated: false, off: 'M3586 2080L3621.38 2167.18H4061.85L4099.75 2080', on: 'M3566 2027L3621.38 2167.18H4061.85L4122 2027', },
    // non-household
    T1N:          { scenario: SCENARIOS.NON_HOUSEHOLD, contaminated: false, off: '', on: '', },
    T1N_2:        { scenario: SCENARIOS.NON_HOUSEHOLD, contaminated: false, off: '', on: '', },
    NCistern:     { scenario: SCENARIOS.NON_HOUSEHOLD, contaminated: false, off: '', on: '', },
    T4N:          { scenario: SCENARIOS.NON_HOUSEHOLD, contaminated: false, off: '', on: 'M2267 1911L2269 2036', },
    NULL_N4:      { scenario: SCENARIOS.NON_HOUSEHOLD, contaminated: false, off: '', on: 'M2424.62 2028L2243 2028.92V2071.64C2243 2089.23 2250.28 2103.64 2269.18 2103.64H2328.09C2346.79 2103.64 2389.74 2103.64 2410.09 2080.3C2410.06 2080.29 2424.62 2070.59 2424.62 2028Z', },
    T5NLow_High:  { scenario: SCENARIOS.NON_HOUSEHOLD, contaminated: false, off: '', on: '', },
    NStorage:     { scenario: SCENARIOS.NON_HOUSEHOLD, contaminated: false, off: '', on: '', },
};

export const WATER = Object.entries(TERMINALS).reduce((arr, [ id, props ]) => {
    arr.push({ id, ...props });
    return arr;
}, []);

export const FLOW = {
    [SCENARIOS.HOUSEHOLD]: {
        MAINS:  [
            { id: 'MC',    dir:  1 },
            { id: 'MB',    dir:  1 },
            { id: 'MA',    dir:  1 },
            { id: 'H',     dir:  1 },
        ],
        CONNECTION_NORMAL:  [
            { id: 'H1_0',  dir:  1 },
            { id: 'H1_1',  dir:  1 },
            { id: 'H2',    dir:  1 },
            { id: 'H4',    dir:  1 },
        ],
        CONNECTION_REVERSE: [
            { id: 'H1_0',  dir: -1 },
            { id: 'H1_1',  dir: -1 },
            { id: 'H2',    dir: -1 },
            { id: 'H4',    dir: -1 },
        ],
        HOUSE_NORMAL: [
            { id: 'H5_0',  dir:  1 },
            { id: 'H5_1',  dir:  1 },
            { id: 'H5_2',  dir:  1 },
            { id: 'H5_3',  dir:  1 },
            { id: 'H5_4',  dir:  1 },
            { id: 'H5_5',  dir:  1 },
            { id: 'H5_6',  dir:  1 },
            { id: 'H5_7',  dir:  1 },
            { id: 'H6_0',  dir:  1 },
            { id: 'H6_1',  dir:  1 },
            { id: 'H8',    dir:  1 },
            { id: 'H9',    dir:  1 },
            { id: 'H6a_0', dir:  1 },
            { id: 'H6a_1', dir:  1 },
            { id: 'H7_0',  dir:  1 },
            { id: 'H10',   dir:  1 },
            { id: 'H12_0', dir:  1 },
            { id: 'H12_1', dir:  1 },
            { id: 'H13',   dir: -1 },
        ],
        HOUSE_REVERSE: [
            { id: 'H5_0',  dir: -1 },
            { id: 'H5_1',  dir: -1 },
            { id: 'H5_2',  dir: -1 },
            { id: 'H5_3',  dir: -1 },
            { id: 'H5_4',  dir: -1 },
            { id: 'H5_5',  dir: -1 },
            { id: 'H5_6',  dir: -1 },
            { id: 'H5_7',  dir: -1 },
            { id: 'H6_0',  dir: -1 },
            { id: 'H6_1',  dir: -1 },
            { id: 'H8',    dir: -1 },
            { id: 'H9',    dir: -1 },
            { id: 'H6a_0', dir: -1 },
            { id: 'H6a_1', dir: -1 },
            { id: 'H7_0',  dir: -1 },
            { id: 'H10',   dir: -1 },
            { id: 'H12_0', dir: -1 },
            { id: 'H12_1', dir: -1 },
            { id: 'H13',   dir:  1 },
        ],
        INCORRECT_SCV: [
            { id: 'H5_0',  dir:  1 },
            { id: 'H5_1',  dir:  1 },
            { id: 'H5_2',  dir:  1 },
            { id: 'H5_3',  dir:  1 },
            { id: 'H5_4',  dir:  1 },
            { id: 'H5_5',  dir:  1 },
            { id: 'H5_6',  dir:  1 },
            { id: 'H5_7',  dir:  1 },
            { id: 'H6_0',  dir:  1 },
            { id: 'H6_1',  dir:  1 },
            { id: 'H8',    dir: -1 },
            { id: 'H9',    dir: -1 },
            { id: 'H6a_0', dir:  1 },
            { id: 'H6a_1', dir:  1 },
            { id: 'H7_0',  dir:  1 },
            { id: 'H10',   dir:  1 },
            { id: 'H12_0', dir:  1 },
            { id: 'H12_1', dir:  1 },
            { id: 'H13',   dir: -1 },
        ],
        FILL_LOOP_OFF: [
            { id: 'H7_1',  dir:  0 },
        ],
        FILL_LOOP_ON: [
            { id: 'H7_1',  dir:  1 },
        ],
        FILL_LOOP_ON_REVERSE: [
            { id: 'H7_1',  dir: -1 },
        ],
        STATIC_BBOX_TO_STOPCOCK: [
            { id: 'H1_0',  dir:  0 },
            { id: 'H1_1',  dir:  0 },
            { id: 'H2',    dir:  0 },
            { id: 'H4',    dir:  0 },
        ],
        STATIC_STOPCOCK_TO_INSIDE: [
            { id: 'H5_0',  dir:  0 },
            { id: 'H5_1',  dir:  0 },
            { id: 'H5_2',  dir:  0 },
            { id: 'H5_3',  dir:  0 },
            { id: 'H5_4',  dir:  0 },
            { id: 'H5_5',  dir:  0 },
            { id: 'H5_6',  dir:  0 },
            { id: 'H5_7',  dir:  0 },
            { id: 'H6_0',  dir:  0 },
            { id: 'H6_1',  dir:  0 },
            { id: 'H8',    dir:  0 },
            { id: 'H9',    dir:  0 },
            { id: 'H6a_0', dir:  0 },
            { id: 'H6a_1', dir:  0 },
            { id: 'H7_0',  dir:  0 },
            { id: 'H10',   dir:  0 },
            { id: 'H12_0', dir:  0 },
            { id: 'H12_1', dir:  0 },
            { id: 'H13',   dir:  0 },
        ],
    },
    [SCENARIOS.AGRICULTURAL]: {
        MAINS:  [
            { id: 'MG',    dir:  1 },
            { id: 'MF',    dir:  1 },
            { id: 'ME',    dir:  1 },
            { id: 'MD',    dir:  1 },
            { id: 'A',     dir:  1 },
        ],
        FARM_NORMAL:   [
            { id: 'A3_0',  dir:  1 },
            { id: 'A3_1',  dir:  1 },
            { id: 'A3a',   dir:  1 },
            { id: 'A3b_0', dir:  1 },
            { id: 'A3b_1', dir:  1 },
        ],
        FARM_REVERSE:   [
            { id: 'A3_0',  dir: -1 },
            { id: 'A3_1',  dir: -1 },
            { id: 'A3a',   dir: -1 },
            { id: 'A3b_0', dir: -1 },
            { id: 'A3b_1', dir: -1 },
        ],
        TROUGH_NORMAL_LOW:   [
            { id: 'A4',    dir:  1 },
            { id: 'A4Low', dir:  1 },
        ],
        TROUGH_NORMAL_HIGH:   [
            { id: 'A4',    dir:  1 },
            { id: 'A4High',dir:  1 },
        ],
        TROUGH_REVERSE_LOW:   [
            { id: 'A4',    dir: -1 },
            { id: 'A4Low', dir: -1 },
        ],
        TROUGH_REVERSE_HIGH:   [
            { id: 'A4',    dir: -1 },
            { id: 'A4High',dir: -1 },
        ],
        BOREHOLE:   [
            { id: 'A6_0',  dir:  1 },
            { id: 'A6_1',  dir:  1 },
            { id: 'A6_2',  dir:  1 },
            { id: 'A6_3',  dir:  1 },
        ],
        CROSS_CONNECTION_INACTIVE:   [
            { id: 'A5_0',    dir:  0 },
            { id: 'A5_1',    dir:  0 },
        ],
        CROSS_CONNECTION_ACTIVE:   [
            { id: 'A5_0',    dir: -1 },
            { id: 'A5_1',    dir: -1 },
        ],
        STATIC: [
            { id: 'A3_0',  dir: -1 },
            { id: 'A3_1',  dir:  0 },
            { id: 'A3a',   dir:  0 },
            { id: 'A3b_0', dir:  0 },
            { id: 'A3b_1', dir:  0 },
        ],
    },
    [SCENARIOS.NON_HOUSEHOLD]: {
        MAINS:  [
            { id: 'MH',      dir:  1 },
            { id: 'MI',      dir:  1 },
            { id: 'MJ',      dir:  1 },
            { id: 'N',       dir:  1 },
        ],
        FACTORY_NORMAL:[
            { id: 'NFPS_Feed', dir:  1 },  
            { id: 'N1_0',      dir: -1 },   
            { id: 'N1_1',      dir:  1 },   
            { id: 'N1_2',      dir:  1 },   
            { id: 'N1_3',      dir:  1 },   
            { id: 'N2_0',      dir:  1 },   
            { id: 'N2_1',      dir:  1 },   
            { id: 'N2_2',      dir:  1 },   
            { id: 'N2_3',      dir:  1 },   
            { id: 'N3_0',      dir:  1 },   
            { id: 'N3_1',      dir:  1 },   
            { id: 'N3_2',      dir:  1 },   
            { id: 'N3_3',      dir:  1 },   
            { id: 'N3_4',      dir:  1 },   
            { id: 'N4',        dir:  1 },   
            { id: 'N5',        dir:  1 },   
            { id: 'N6_0',      dir:  1 },   
            { id: 'N6_1',      dir:  1 },   
            { id: 'N6_2',      dir:  1 },   
            // { id: 'N7',        dir:  1 },   
            // { id: 'N7_a',      dir:  1 },   
            // { id: 'N7_b',      dir:  1 },   
            // { id: 'N7_c',      dir:  1 },   
            { id: 'NDeadLeg',  dir:  0 },   
        ],
        FACTORY_REVERSE:[
            { id: 'NFPS_Feed', dir: -1 },  
            { id: 'N1_0',      dir:  1 },   
            { id: 'N1_1',      dir: -1 },   
            { id: 'N1_2',      dir: -1 },   
            { id: 'N1_3',      dir: -1 },   
            { id: 'N2_0',      dir: -1 },   
            { id: 'N2_1',      dir: -1 },   
            { id: 'N2_2',      dir: -1 },   
            { id: 'N2_3',      dir: -1 },   
            { id: 'N3_0',      dir: -1 },   
            { id: 'N3_1',      dir: -1 },   
            { id: 'N3_2',      dir: -1 },   
            { id: 'N3_3',      dir: -1 },   
            { id: 'N3_4',      dir: -1 },   
            { id: 'N4',        dir: -1 },   
            { id: 'N5',        dir: -1 },   
            { id: 'N6_0',      dir: -1 },   
            { id: 'N6_1',      dir: -1 },   
            { id: 'N6_2',      dir: -1 },   
            // { id: 'N7',        dir: -1 },   
            // { id: 'N7_a',      dir: -1 },   
            // { id: 'N7_b',      dir: -1 },   
            // { id: 'N7_c',      dir: -1 },   
            { id: 'NDeadLeg',  dir:  0 },   
        ],
        FACTORY_STATIC:[
            { id: 'NFPS_Feed', dir:  0 },  
            { id: 'N1_0',      dir:  0 },   
            { id: 'N1_1',      dir:  0 },   
            { id: 'N1_2',      dir:  0 },   
            { id: 'N1_3',      dir:  0 },   
            { id: 'N2_0',      dir:  0 },   
            { id: 'N2_1',      dir:  0 },   
            { id: 'N2_2',      dir:  0 },   
            { id: 'N2_3',      dir:  0 },   
            { id: 'N3_0',      dir:  0 },   
            { id: 'N3_1',      dir:  0 },   
            { id: 'N3_2',      dir:  0 },   
            { id: 'N3_3',      dir:  0 },   
            { id: 'N3_4',      dir:  0 },   
            { id: 'N4',        dir:  0 },   
            { id: 'N5',        dir:  0 },   
            { id: 'N6_0',      dir:  0 },   
            { id: 'N6_1',      dir:  0 },   
            { id: 'N6_2',      dir:  0 },   
            // { id: 'N7',        dir:  0 },   
            // { id: 'N7_a',      dir:  0 },   
            // { id: 'N7_b',      dir:  0 },   
            // { id: 'N7_c',      dir:  0 },   
            { id: 'NDeadLeg',  dir:  0 },   
        ],
        RPZ_OFF_NORMAL:[
            { id: 'N6_1',    dir:  1 },   
        ],
        RPZ_OFF_REVERSE:[
            { id: 'N6_1',    dir: -1 },   
        ],
        RPZ_ON:[
            { id: 'N6_1',    dir:  0 },   
        ],
        RPZ_STATIC:[
            { id: 'N6_1',    dir:  0 },   
        ],
    },
};

export const FLOW_SPEED = 15;

export const CONTAMINATION = {
    [SCENARIOS.HOUSEHOLD]: {
        AGRICULTURAL_TO_BOUNDARY_BOX: [
            { node: 'MC',     prevNode: null,    dir:  1 },
            { node: 'MB',     prevNode: 'MC',    dir:  1 },
            { node: 'MA',     prevNode: 'MB',    dir:  1 },
            { node: 'H',      prevNode: 'MA',    dir:  1 },
        ],
        HYDRANT_TO_STOPCOCK: [
            { node: 'T1H',    prevNode: null,    dir: -1, water: true },
            { node: 'H',      prevNode: 'T1H',   dir:  1 },
            { node: 'MA',     prevNode: 'T1H',   dir: -1 },
            { node: 'MB',     prevNode: 'MA',    dir: -1 },
            { node: 'MC',     prevNode: 'MB',    dir: -1 },
            { node: 'H1_1',   prevNode: 'MB',    dir: -1 },
            { node: 'H1_0',   prevNode: 'H1_1',  dir:  1 },
            { node: 'H2',     prevNode: 'H1_0',  dir:  1 },
            { node: 'H4',     prevNode: 'H2',    dir:  1 },
        ],
        STOPCOCK_TO_BOUNDARY_BOX: [
            { node: 'H4',     prevNode: 'H5_7',  dir: -1 },
            { node: 'H2',     prevNode: 'H4',    dir: -1 },
            { node: 'H1_0',   prevNode: 'H2',    dir: -1 },
        ],
        BOUNDARY_BOX_TO_PIPE_BURST: [
            { node: 'H1_1',   prevNode: 'H1_0',  dir: -1 },
            { node: 'MB',     prevNode: 'H1_1',  dir:  1 },
            { node: 'T2H',    prevNode: 'MB',    dir:  1, water: true },
        ],
        BOUNDARY_BOX_TO_HYDRANT: [
            { node: 'H1_1',        prevNode: 'H1_0',  dir: -1 },
            { node: 'MB',          prevNode: 'H1_1',  dir:  1 },
            { node: 'MA',          prevNode: 'MB',    dir:  1 },
            { node: 'T13H_Puddle', prevNode: 'MA',    dir:  1, water: true },
        ],
        BOUNDARY_BOX_TO_STOPCOCK: [
            { node: 'H1_1',   prevNode: 'MC',    dir:  1 },
            { node: 'H1_0',   prevNode: 'H1_1',  dir:  1 },
            { node: 'H2',     prevNode: 'H1_0',  dir:  1 },
            { node: 'H4',     prevNode: 'H2',    dir:  1 },
        ],
        STOPCOCK_INTO_HOUSE: [
            { node: 'H5_7',     prevNode: 'H4',    dir:  1 },
            { node: 'H6a_0',    prevNode: 'H5_7',  dir:  1 },
            { node: 'H6a_1',    prevNode: 'H6a_0', dir:  1 },
            { node: 'H7_0',     prevNode: 'H6a_0', dir:  1 },
            { node: 'H7_1',     prevNode: 'H7_0',  dir:  1 },
            { node: 'H5_6',     prevNode: 'H5_7',  dir:  1 },
            { node: 'H5_5',     prevNode: 'H5_6',  dir:  1 },
            { node: 'H5_4',     prevNode: 'H5_6',  dir:  1 },
            { node: 'H6_1',     prevNode: 'H5_4',  dir:  1 },
            { node: 'H6_0',     prevNode: 'H6_1',  dir:  1 },
            { node: 'H8',       prevNode: 'H6_1',  dir:  1 },
            { node: 'H9',       prevNode: 'H8',    dir:  1 },
            { node: 'H10',      prevNode: 'H5_4',  dir:  1 },
            { node: 'H5_3',     prevNode: 'H5_4',  dir:  1 },
            { node: 'H5_2',     prevNode: 'H5_3',  dir:  1 },
            { node: 'H5_1',     prevNode: 'H5_3',  dir:  1 },
            { node: 'H12_0',    prevNode: 'H5_1',  dir:  1 },
            { node: 'H12_1',    prevNode: 'H12_0', dir:  1 },
            { node: 'H5_0',     prevNode: 'H5_1',  dir:  1 },
            { node: 'H13',      prevNode: 'H5_0',  dir: -1 },
            { node: 'T10H',     prevNode: 'H6_0',  dir:  1, water: true },
            { node: 'NULL_H7',  prevNode: 'T10H',  dir:  1, water: true },
            { node: 'T11H',     prevNode: 'H5_2',  dir:  1, water: true },
            { node: 'H11_H11a', prevNode: 'T11H',  dir:  1, water: true },
            { node: 'H14_H15',  prevNode: 'H13',   dir:  1, water: true },
        ],
        STOPCOCK_TO_SINK_TAP: [
            { node: 'H5_7',     prevNode: 'H4',    dir:  1 },
            { node: 'H7_1',     prevNode: 'H7_0',  dir:  1 },
            { node: 'H5_6',     prevNode: 'H5_7',  dir:  1 },
            { node: 'H5_5',     prevNode: 'H5_6',  dir:  1 },
            { node: 'H6_1',     prevNode: 'H5_5',  dir:  1 },
            { node: 'H6_0',     prevNode: 'H6_1',  dir:  1 },
            { node: 'T10H',     prevNode: 'H6_0',  dir:  1, water: true },
            { node: 'NULL_H7',  prevNode: 'T10H',  dir:  1, water: true },
        ],
        T4H_TO_STOPCOCK: [
            { node: 'H2',    prevNode: null,    dir:  1 },
            { node: 'H4',    prevNode: 'H2',    dir:  1 },
        ],
        T4H_TO_BOUNDARY_BOX: [
            { node: 'H1_0',   prevNode: null,    dir: -1 },
        ],
        T5H_TO_STOPCOCK: [
            { node: 'H4',    prevNode: null,    dir:  1 },
        ],
        T5H_TO_BOUNDARY_BOX: [
            { node: 'H2',    prevNode: null,    dir: -1 },
            { node: 'H1_0',  prevNode: 'H2',    dir: -1 },
        ],
        SEEPAGE: [
            { node: 'H2',    prevNode: null,    dir:  1 },
            { node: 'H4',    prevNode: 'H2',    dir:  1 },
            // { node: 'H1_0',  prevNode: null,    dir: -1 },
        ],
        CONTAMINATED_PIPE: [
            { node: 'H4',     prevNode: null,    dir:  1 },
            // { node: 'H2',     prevNode: null,    dir: -1 },
            // { node: 'H1_0',   prevNode: 'H2',    dir: -1 },
        ],
        CISTERN: [
            { node: 'H13',      prevNode: null,    dir:  1 },
            { node: 'H5_0',     prevNode: 'H13',   dir: -1 },
            { node: 'H5_1',     prevNode: 'H5_0',  dir: -1 },
            { node: 'H5_3',     prevNode: 'H5_1',  dir: -1 },
            { node: 'H5_4',     prevNode: 'H5_3',  dir: -1 },
            { node: 'H5_5',     prevNode: 'H5_4',  dir:  1 },
            { node: 'H6_1',     prevNode: 'H5_5',  dir:  1 },
            { node: 'H6_0',     prevNode: 'H6_1',  dir:  1 },
            { node: 'T10H',     prevNode: 'H6_0',  dir:  1, water: true },
            { node: 'NULL_H7',  prevNode: 'T10H',  dir:  1, water: true },
            // { node: 'H5_6',     prevNode: 'H5_4',  dir: -1 },
            // { node: 'H5_7',     prevNode: 'H5_6',  dir: -1 },
        ],
        SHOWER_HEAD: [
            { node: 'H12S',     prevNode: null,    dir:  1 },
            { node: 'H12_1',    prevNode: 'H12S',  dir: -1 },
            { node: 'H12_0',    prevNode: 'H12_1', dir: -1 },
            { node: 'H5_1',     prevNode: 'H12_0', dir: -1 },
            { node: 'H5_3',     prevNode: 'H5_1',  dir: -1 },
            { node: 'H5_4',     prevNode: 'H5_3',  dir: -1 },

            { node: 'H5_5',     prevNode: 'H5_4',  dir:  1 },
            { node: 'H6_1',     prevNode: 'H5_5',  dir:  1 },
            { node: 'H6_0',     prevNode: 'H6_1',  dir:  1 },
            { node: 'T10H',     prevNode: 'H6_0',  dir:  1, water: true },
            { node: 'NULL_H7',  prevNode: 'T10H',  dir:  1, water: true },
            // { node: 'H5_6',     prevNode: 'H5_4',  dir: -1 },
            // { node: 'H5_7',     prevNode: 'H5_6',  dir: -1 },

            // { node: 'H5_6',     prevNode: 'H5_4',  dir: -1 },
            // { node: 'H5_7',     prevNode: 'H5_6',  dir: -1 },
        ],
        SHOWER_HEAD_WITH_DCV: [
            { node: 'H12S',     prevNode: null,    dir:  1 },
            { node: 'H12_1',    prevNode: 'H12S',  dir: -1 },
        ],
        OUTSIDE_TAP: [
            { node: 'T13H_hose_0', prevNode: null,          dir: -1 },
            { node: 'T13H_hose_1', prevNode: 'T13H_hose_0', dir:  1 },
            { node: 'T13H_hose_2', prevNode: 'T13H_hose_1', dir: -1 },
            { node: 'H10',         prevNode: 'T13H_hose_2', dir: -1 },
            { node: 'H5_4',        prevNode: 'H10',         dir: -1 },
            { node: 'H5_6',        prevNode: 'H5_4',        dir: -1 },
            { node: 'H5_7',        prevNode: 'H5_6',        dir: -1 },
        ],
        OUTSIDE_TAP_FULL: [
            { node: 'T13H_hose_0', prevNode: null,          dir: -1 },
            { node: 'T13H_hose_1', prevNode: 'T13H_hose_0', dir:  1 },
            { node: 'T13H_hose_2', prevNode: 'T13H_hose_1', dir: -1 },
            { node: 'H10',         prevNode: 'T13H_hose_2', dir: -1 },
            { node: 'H5_4',        prevNode: 'H10',         dir: -1 },
            { node: 'H5_6',        prevNode: 'H5_4',        dir: -1 },
            { node: 'H5_7',        prevNode: 'H5_6',        dir: -1 },
            { node: 'H5_3',        prevNode: 'H10',         dir:  1 },
            { node: 'H5_2',        prevNode: 'H5_3',        dir:  1 },
            { node: 'H5_1',        prevNode: 'H5_2',        dir:  1 },
            { node: 'H5_0',        prevNode: 'H5_1',        dir:  1 },
            { node: 'H12_0',       prevNode: 'H5_1',        dir:  1 },
            { node: 'H12_1',       prevNode: 'H12_0',       dir:  1 },
            { node: 'H13',         prevNode: 'H5_0',        dir: -1 },

            { node: 'H5_5',     prevNode: 'H5_4',  dir:  1 },
            { node: 'H6_1',     prevNode: 'H5_5',  dir:  1 },
            { node: 'H6_0',     prevNode: 'H6_1',  dir:  1 },
            { node: 'H8',       prevNode: 'H6_1',  dir:  1 },
            { node: 'H9',       prevNode: 'H8',    dir:  1 },

            { node: 'T10H',     prevNode: 'H6_0',  dir:  1, water: true },
            { node: 'NULL_H7',  prevNode: 'T10H',  dir:  1, water: true },

            { node: 'H6a_0',    prevNode: 'H5_7',  dir:  1 },
            { node: 'H6a_1',    prevNode: 'H6a_0', dir:  1 },
            { node: 'H7_0',     prevNode: 'H6a_0', dir:  1 },
            { node: 'H7_1',     prevNode: 'H7_0',  dir:  1 },
        ],
        BOUNDARY_BOX_TO_MAINS: [
            { node: 'H1_1',   prevNode: 'H1_0',  dir: -1 },
            { node: 'MB',     prevNode: 'H1_1',  dir:  1 },
            { node: 'MA',     prevNode: 'MB',    dir:  1 },
            { node: 'H',      prevNode: 'MA',    dir:  1 },
            { node: 'MC',     prevNode: 'H1_1',  dir: -1 },
            { node: 'T2H',    prevNode: 'MB',    dir:  1, water: true },
        ],
        FILL_LOOP: [
            { node: 'H7_1',     prevNode: null,    dir: -1 },
            { node: 'H7_0',     prevNode: 'H7_1',  dir: -1 },
            { node: 'H6a_0',    prevNode: 'H7_0',  dir: -1 },
            { node: 'H6a_1',    prevNode: 'H7_0',  dir:  1 },
            { node: 'H5_7',     prevNode: 'H6a_0', dir: -1 },
            { node: 'H5_6',     prevNode: 'H6a_0', dir:  1 },
            { node: 'H5_5',     prevNode: 'H5_6',  dir:  1 },
            { node: 'H5_4',     prevNode: 'H5_5',  dir:  1 },
            { node: 'H5_3',     prevNode: 'H5_4',  dir:  1 },
            { node: 'H5_2',     prevNode: 'H5_3',  dir:  1 },
            { node: 'H5_1',     prevNode: 'H5_2',  dir:  1 },
            { node: 'H5_0',     prevNode: 'H5_1',  dir:  1 },
            
            { node: 'H10',      prevNode: 'H5_4', dir:  1 },

            { node: 'H6_1',     prevNode: 'H5_5',  dir:  1 },
            { node: 'H6_0',     prevNode: 'H6_1',  dir:  1 },
            { node: 'H8',       prevNode: 'H6_1',  dir:  1 },
            { node: 'H9',       prevNode: 'H8',    dir:  1 },
            { node: 'T10H',     prevNode: 'H6_0',  dir:  1, water: true },
            { node: 'NULL_H7',  prevNode: 'T10H',  dir:  1, water: true },

            { node: 'T11H',      prevNode: 'H5_3', dir:  1, water: true },
            { node: 'H11_H11a',  prevNode: 'T11H', dir:  1, water: true },

            { node: 'H12_0',    prevNode: 'H5_1',        dir:  1 },
            { node: 'H12_1',    prevNode: 'H12_0',       dir:  1 },
            { node: 'H13',      prevNode: 'H5_0',        dir: -1 },
        ],
        SINK_FILTER: [
            { node: 'H6_1',     prevNode: null,    dir:  1 },
            { node: 'H6_0',     prevNode: 'H6_1',  dir:  1 },
            { node: 'H8',       prevNode: 'H6_1',  dir:  1 },
            { node: 'H9',       prevNode: 'H8',    dir:  1 },
            { node: 'T10H',     prevNode: 'H6_0',  dir:  1, water: true },
            { node: 'NULL_H7',  prevNode: 'T10H',  dir:  1, water: true },
        ],
        SINK_FILTER_TO_STOPCOCK: [
            { node: 'H5_5',     prevNode: null,    dir: -1 },
            { node: 'H5_6',     prevNode: 'H5_5',  dir: -1 },
            { node: 'H5_7',     prevNode: 'H5_6',  dir: -1 },
        ],
        SCV: [
            { node: 'H9',       prevNode: null,    dir: -1 },
            { node: 'H8',       prevNode: 'H9',    dir: -1 },
            { node: 'H6_0',     prevNode: 'H8',    dir:  1 },
            { node: 'T10H',     prevNode: 'H6_0',  dir:  1, water: true },
            { node: 'NULL_H7',  prevNode: 'T10H',  dir:  1, water: true },
        ],
        WASHING_MACHINE_SCV: [
            { node: 'H9',       prevNode: null,    dir: -1 },
            { node: 'H8',       prevNode: 'H9',    dir: -1 },
            { node: 'H6_0',     prevNode: 'H8',    dir:  1 },
            { node: 'T10H',     prevNode: 'H6_0',  dir:  1, water: true },
            { node: 'NULL_H7',  prevNode: 'T10H',  dir:  1, water: true },
        ],
        CISTERN_TO_SINK_TAP: [
            { node: 'H13',      prevNode: null,    dir:  1 },
            { node: 'H5_0',     prevNode: 'H13',   dir: -1 },
            { node: 'H5_1',     prevNode: 'H5_0',  dir: -1 },
            { node: 'H5_3',     prevNode: 'H5_1',  dir: -1 },
            { node: 'H5_4',     prevNode: 'H5_3',  dir: -1 },
            { node: 'H5_5',     prevNode: 'H5_4',  dir:  1 },
            { node: 'H6_1',     prevNode: 'H5_5',  dir:  1 },
            { node: 'H6_0',     prevNode: 'H6_1',  dir:  1 },
            { node: 'T10H',     prevNode: 'H6_0',  dir:  1, water: true },
            { node: 'NULL_H7',  prevNode: 'T10H',  dir:  1, water: true },
            // // { node: 'H7_0',     prevNode: 'H6_1',  dir:  1 },
            // // { node: 'H7_1',     prevNode: 'H7_0',  dir:  1 },
            // { node: 'H5_5',     prevNode: 'H5_6',  dir:  1 },
            // { node: 'H6_1',     prevNode: 'H5_5',  dir:  1 },
            // { node: 'H6_0',     prevNode: 'H6_1',  dir:  1 },
            // { node: 'T10H',     prevNode: 'H6_0',  dir:  1, water: true },
            // { node: 'NULL_H7',  prevNode: 'T10H',  dir:  1, water: true },
        ],
    },
    [SCENARIOS.AGRICULTURAL]: {
        HOUSEHOLD_OFFSCREEN: [
            { node: 'A',         prevNode: null,    dir: -1 },
            { node: 'MD',        prevNode: 'A',     dir: -1 },
            { node: 'ME',        prevNode: 'MD',    dir: -1 },
            { node: 'A3_0',      prevNode: 'MD',    dir:  1 },
            { node: 'A3_1',      prevNode: 'A3_0',  dir:  1 },
            { node: 'A3a',       prevNode: 'A3_1',  dir:  1 },
            { node: 'A3b_0',     prevNode: 'A3_1',  dir:  1 },
            { node: 'A3b_1',     prevNode: 'A3b_0', dir:  1 },
            { node: 'MF',        prevNode: 'ME',    dir: -1 },
            { node: 'MG',        prevNode: 'MF',    dir: -1 },
            { node: 'A4',        prevNode: 'MF',    dir:  1 },
            { node: 'A4High',    prevNode: 'A4',    dir:  1 },
        ],
        NON_HOUSEHOLD_OFFSCREEN: [
            { node: 'MG',        prevNode: null,   dir:  1 },
            { node: 'MF',        prevNode: 'MG',   dir:  1 },
            { node: 'ME',        prevNode: 'MF',   dir:  1 },
            { node: 'MD',        prevNode: 'ME',   dir:  1 },
            { node: 'A',         prevNode: 'MD',   dir:  1 },
            { node: 'A4',        prevNode: 'MG',   dir:  1 },
            { node: 'A4High',    prevNode: 'A4',   dir:  1 },
            { node: 'A3_0',      prevNode: 'ME',   dir:  1 },
            { node: 'A3_1',      prevNode: 'A3_0', dir:  1 },
            { node: 'A3a',       prevNode: 'A3_1', dir:  1 },
            { node: 'A3b_0',     prevNode: 'A3_1', dir:  1 },
            { node: 'A3b_1',     prevNode: 'A3b_0', dir:  1 },
        ],
        AIRGAP_TO_BURST_1: [
            { node: 'A4Low',     prevNode: null,    dir: -1 },
            { node: 'A4',        prevNode: 'A4Low', dir: -1 },
            { node: 'MF',        prevNode: 'A4',    dir:  1 },
            { node: 'ME',        prevNode: 'MF',    dir:  1 },
            { node: 'MD',        prevNode: 'ME',    dir:  1 },
            { node: 'T1A',       prevNode: 'MD',    dir:  1, water: true },
        ],
        AIRGAP_TO_ALL: [
            { node: 'A4Low',     prevNode: null,    dir: -1 },
            { node: 'A4',        prevNode: 'A4Low', dir: -1 },
            { node: 'MF',        prevNode: 'A4',    dir:  1 },
            { node: 'MG',        prevNode: 'A4',    dir: -1 },
            { node: 'ME',        prevNode: 'MF',    dir:  1 },
            { node: 'MD',        prevNode: 'ME',    dir:  1 },
            { node: 'A',         prevNode: 'MD',    dir:  1 },
            { node: 'T1A',       prevNode: 'MD',    dir:  1, water: true },

            { node: 'A3_0',      prevNode: 'ME',      dir:  1 },
            { node: 'A3_1',      prevNode: 'A3_0',    dir:  1 },
            { node: 'A3a',       prevNode: 'A3_1',    dir:  1 },
            { node: 'A3b_0',       prevNode: 'A3_1',  dir:  1 },
            { node: 'A3b_1',       prevNode: 'A3b_0', dir:  1 },
        ],
        // AIRGAP_TO_BURST_2: [
        //     { node: 'A4Low',     prevNode: null,    dir: -1 },
        //     { node: 'A4',        prevNode: 'A4Low', dir: -1 },
        //     { node: 'MF',        prevNode: 'A4',    dir:  1 },
        //     { node: 'T2A',       prevNode: 'MF',    dir:  1, water: true },
        // ],
        AIRGAP_TO_BOUNDARY_BOX: [
            { node: 'A4Low',     prevNode: null,    dir: -1 },
            { node: 'A4',        prevNode: 'A4Low', dir: -1 },
            { node: 'MF',        prevNode: 'A4',    dir:  1 },
            { node: 'ME',        prevNode: 'MF',    dir:  1 },
            { node: 'A3_0',      prevNode: 'ME',    dir:  1 },
        ],
        BOUNDARY_BOX_TO_BURST_1: [
            { node: 'A3_0',     prevNode: 'A3_1',    dir: -1 },
            { node: 'MD',       prevNode: 'A3_0',    dir:  1 },
            { node: 'T1A',      prevNode: 'MD',    dir:  1, water: true },
        ],
        // BOUNDARY_BOX_TO_BURST_2: [
        //     { node: 'A3_0',     prevNode: 'A3_1',    dir: -1 },
        //     { node: 'ME',       prevNode: 'A3_0',    dir: -1 },
        //     { node: 'T2A',      prevNode: 'ME',    dir:  1, water: true },
        // ],
        CROSS_CONNECTION_TO_BOUNDARY_BOX: [
            { node: 'A5_1',      prevNode: null,    dir: -1 },
            { node: 'A5_0',      prevNode: 'A5_1',  dir: -1 },
            { node: 'A3b_0',     prevNode: 'A5_0',  dir: -1 },
        ],
        BOUNDARY_BOX_JUNCTION_TO_FARM_HOUSE: [
            { node: 'A3a',       prevNode: 'A3b_0', dir:  1 },
        ],
        CROSS_CONNECTION_TO_PARLOUR: [
            { node: 'A3b_1',     prevNode: 'A5_0',  dir:  1 },
        ],
        BOUNDARY_BOX_JUNCTION_TO_BOUNDARY_BOX: [
            { node: 'A3_1',      prevNode: 'A3b_0', dir: -1 },
        ],
        BOUNDARY_BOX_TO_ALL_MAINS: [
            { node: 'A3_0',      prevNode: 'A3_1', dir: -1 },
            { node: 'MD',        prevNode: 'A3_0', dir:  1 },
            { node: 'A',         prevNode: 'MD',   dir:  1 },
            { node: 'ME',        prevNode: 'A3_0', dir: -1 },
            { node: 'MF',        prevNode: 'ME',   dir: -1 },
            { node: 'MG',        prevNode: 'MF',   dir: -1 },
            { node: 'A4',        prevNode: 'MF',   dir:  1 },
        ],
        AIRGAP_HIGH: [
            { node: 'A4High',    prevNode: 'A4',   dir:  1 },
        ],
        AIRGAP_LOW: [
            { node: 'A4Low',     prevNode: 'A4',   dir:  1 },
        ],
        STOPTAP_TO_PARLOUR: [
            // { node: 'A3b_0',     prevNode: null,  dir:  1 },
            // { node: 'A5_1',      prevNode: null,    dir: -1 },
            { node: 'A5_1',      prevNode: null,    dir: -1 },
            { node: 'A5_0',      prevNode: 'A5_1',  dir: -1 },
            { node: 'A3b_1',     prevNode: 'A5_0',  dir:  1 },
        ],
        STOPTAP_TO_ALL: [
            { node: 'A5_1',      prevNode: null,    dir: -1 },
            { node: 'A5_0',      prevNode: 'A5_1',  dir: -1 },
            { node: 'A3b_1',     prevNode: 'A5_0',  dir:  1 },
            { node: 'A3b_0',     prevNode: 'A5_0',  dir: -1 },
            { node: 'A3a',       prevNode: 'A3b_0', dir:  1 },
            { node: 'A3_1',      prevNode: 'A3b_0', dir: -1 },
            { node: 'A3_0',      prevNode: 'A3_1',  dir: -1 },
            { node: 'MD',        prevNode: 'A3_0',  dir:  1 },
            { node: 'A',         prevNode: 'MD',    dir:  1 },
            { node: 'ME',        prevNode: 'A3_0',  dir: -1 },
            { node: 'MF',        prevNode: 'ME',    dir: -1 },
            { node: 'MG',        prevNode: 'MF',    dir: -1 },
            { node: 'A4',        prevNode: 'MF',    dir:  1 },
            { node: 'T1A',       prevNode: 'MD',    dir:  1, water: true },
        ],
    },
    [SCENARIOS.NON_HOUSEHOLD]: {
        AGRICULTURAL_OFFSCREEN: [
            { node: 'N',     prevNode: null,   dir: -1 },
            { node: 'MH',    prevNode: 'N',    dir: -1 },
            { node: 'MI',    prevNode: 'MH',   dir: -1 },
            { node: 'MJ',    prevNode: 'MI',   dir: -1 },

            { node: 'N1_0',  prevNode: 'N',    dir:  1 },
            { node: 'N1_1',  prevNode: 'N1_0', dir:  1 },
            { node: 'N1_2',  prevNode: 'N1_1', dir:  1 },
            { node: 'N1_3',  prevNode: 'N1_2', dir:  1 },

            { node: 'NStorage',   prevNode: 'N1_3',     dir:  1, water: true },
            { node: 'NStoreFeed', prevNode: 'NStorage', dir: -1 },

            { node: 'N2_0',  prevNode: 'N1_2', dir:  1 },
            { node: 'N2_1',  prevNode: 'N2_0', dir:  1 },
            { node: 'N2_2',  prevNode: 'N2_1', dir:  1 },
            { node: 'N2_3',  prevNode: 'N2_2', dir:  1 },

            { node: 'N3_0',  prevNode: 'N2_1', dir:  1 },
            { node: 'N3_1',  prevNode: 'N3_0', dir:  1 },
            { node: 'N3_2',  prevNode: 'N3_1', dir:  1 },
            { node: 'N3_3',  prevNode: 'N3_2', dir:  1 },
            { node: 'N3_4',  prevNode: 'N3_3', dir:  1 },

            { node: 'N4',  prevNode: 'N3_0', dir:  1 },
            { node: 'T4N',     prevNode: 'N4', dir:  1, water: true },
            { node: 'NULL_N4', prevNode: 'T4N',  dir:  1, water: true },

            { node: 'N5',  prevNode: 'N3_1', dir:  1 },

            { node: 'N6_0',  prevNode: 'N3_2', dir:  1 },
            { node: 'N6_1',  prevNode: 'N6_0', dir:  1 },
            { node: 'N6_2',  prevNode: 'N6_1', dir:  1 },


            // { node: 'NCistern', prevNode: 'N3_4',     dir:  1, water: true },
            // { node: 'N7',       prevNode: 'NCistern', dir:  1 },
            // { node: 'N7_a',     prevNode: 'N7',       dir:  1 },
            // { node: 'N7_b',     prevNode: 'N7',       dir:  1 },
            // { node: 'N7_c',     prevNode: 'N7',       dir:  1 },
        ],
        OFFSCREEN: [
            { node: 'MJ',    prevNode: null,   dir:  1 },
            { node: 'MI',    prevNode: 'MJ',   dir:  1 },
            { node: 'MH',    prevNode: 'MI',   dir:  1 },
            { node: 'N',     prevNode: 'MH',   dir:  1 },

            { node: 'N1_0',  prevNode: 'MH',   dir:  1 },
            { node: 'N1_1',  prevNode: 'N1_0', dir:  1 },
            { node: 'N1_2',  prevNode: 'N1_1', dir:  1 },
            { node: 'N1_3',  prevNode: 'N1_2', dir:  1 },

            { node: 'NStorage',   prevNode: 'N1_3',     dir:  1, water: true },
            { node: 'NStoreFeed', prevNode: 'NStorage', dir: -1 },

            { node: 'N2_0',  prevNode: 'N1_2', dir:  1 },
            { node: 'N2_1',  prevNode: 'N2_0', dir:  1 },
            { node: 'N2_2',  prevNode: 'N2_1', dir:  1 },
            { node: 'N2_3',  prevNode: 'N2_2', dir:  1 },

            { node: 'N3_0',  prevNode: 'N2_1', dir:  1 },
            { node: 'N3_1',  prevNode: 'N3_0', dir:  1 },
            { node: 'N3_2',  prevNode: 'N3_1', dir:  1 },
            { node: 'N3_3',  prevNode: 'N3_2', dir:  1 },
            { node: 'N3_4',  prevNode: 'N3_3', dir:  1 },

            { node: 'N4',  prevNode: 'N3_0', dir:  1 },
            { node: 'N5',  prevNode: 'N3_1', dir:  1 },

            { node: 'N6_0',  prevNode: 'N3_2', dir:  1 },
            { node: 'N6_1',  prevNode: 'N6_0', dir:  1 },
            { node: 'N6_2',  prevNode: 'N6_1', dir:  1 },

            { node: 'NCistern', prevNode: 'N3_4',     dir:  1, water: true },
            { node: 'N7',       prevNode: 'NCistern', dir:  1 },
            { node: 'N7_a',     prevNode: 'N7',       dir:  1 },
            { node: 'N7_b',     prevNode: 'N7',       dir:  1 },
            { node: 'N7_c',     prevNode: 'N7',       dir:  1 },
        ],
        SEEPAGE: [
            { node: 'N2_2',    prevNode: null,   dir: -1 },
            { node: 'N3_0',    prevNode: 'N2_2', dir:  1 },
            { node: 'N4',      prevNode: 'N3_0', dir:  1 },
            { node: 'T4N',     prevNode: 'N4',   dir:  1, water: true },
            { node: 'NULL_N4', prevNode: 'T4N',  dir:  1, water: true },
        ],
        AIRGAP_TO_BURST_TWO: [
            { node: 'N5',      prevNode: null,   dir: -1 },
            { node: 'N3_1',    prevNode: 'N5',   dir: -1 },
            { node: 'N3_0',    prevNode: 'N3_1',   dir: -1 },
            { node: 'N2_1',    prevNode: 'N3_0',   dir: -1 },
        ],
        BURST_TWO_TO_DCV: [
            { node: 'N2_0',    prevNode: 'N2_1',   dir: -1 },
            { node: 'N1_2',    prevNode: 'N2_0',   dir: -1 },
        ],
        DCV_TO_BOUNDARY_BOX: [
            { node: 'N1_1',    prevNode: 'N1_2',   dir: -1 },
        ],
        BOUNDARY_BOX_TO_BURST_ONE: [
            { node: 'N1_0',    prevNode: 'N1_1',   dir: -1 },
            { node: 'MH',      prevNode: 'N1_0',   dir: -1 },
            { node: 'N',       prevNode: 'N1_0',   dir:  1 },
        ],
        RPZ_TO_BURST_TWO: [
            { node: 'N6_2',      prevNode: null,   dir: -1 },
            { node: 'N6_1',      prevNode: 'N6_2', dir: -1 },
            { node: 'N6_0',      prevNode: 'N6_1', dir: -1 },
            { node: 'N3_2',      prevNode: 'N6_0', dir: -1 },
            { node: 'N3_1',      prevNode: 'N3_2', dir: -1 },
            { node: 'N3_0',      prevNode: 'N3_1', dir: -1 },
            { node: 'N2_1',      prevNode: 'N3_0',   dir: -1 },
            { node: 'N4',        prevNode: 'N3_1',     dir:  1 },
            { node: 'T4N',       prevNode: 'N4',       dir:  1, water: true },
            { node: 'NULL_N4',   prevNode: 'T4N',      dir:  1, water: true },
        ],
        RPZ_TO_ALL: [
            { node: 'N6_2',      prevNode: null,   dir: -1 },
            { node: 'N6_1',      prevNode: 'N6_2', dir: -1 },
            { node: 'N6_0',      prevNode: 'N6_1', dir: -1 },
            { node: 'N3_3',      prevNode: 'N6_0', dir:  1 },
            { node: 'N3_4',      prevNode: 'N3_3', dir:  1 },
            { node: 'N3_2',      prevNode: 'N6_0', dir: -1 },
            { node: 'N3_1',      prevNode: 'N3_2', dir: -1 },

            { node: 'N4',        prevNode: 'N3_1', dir:  1 },
            { node: 'T4N',       prevNode: 'N4',   dir:  1, water: true },
            { node: 'NULL_N4',   prevNode: 'T4N',  dir:  1, water: true },

            { node: 'N3_0',      prevNode: 'N3_1', dir: -1 },
            { node: 'N2_1',      prevNode: 'N3_0',   dir: -1 },
            { node: 'N2_0',      prevNode: 'N2_1',   dir: -1 },
            { node: 'N2_2',      prevNode: 'N2_1',   dir:  1 },
            { node: 'N2_3',      prevNode: 'N2_2',   dir:  1 },

            { node: 'N1_3',      prevNode: 'N2_0',   dir:  1 },
            { node: 'N1_2',      prevNode: 'N2_0',   dir: -1 },
            { node: 'N1_1',      prevNode: 'N1_2',   dir: -1 },

            { node: 'NStorage',   prevNode: 'N1_3',     dir:  1, water: true },
            { node: 'NStoreFeed', prevNode: 'NStorage', dir: -1 },

        ],
        RPZ_TO_DCV: [
            { node: 'N6_2',      prevNode: null,   dir: -1 },
            { node: 'N6_1',      prevNode: 'N6_2', dir: -1 },
            { node: 'N6_0',      prevNode: 'N6_1', dir: -1 },
            { node: 'N3_3',      prevNode: 'N6_0', dir:  1 },
            { node: 'N3_4',      prevNode: 'N3_3', dir:  1 },
            { node: 'N3_2',      prevNode: 'N6_0', dir: -1 },
            { node: 'N3_1',      prevNode: 'N3_2', dir: -1 },

            { node: 'N4',        prevNode: 'N3_1', dir:  1 },
            { node: 'T4N',       prevNode: 'N4',   dir:  1, water: true },
            { node: 'NULL_N4',   prevNode: 'T4N',  dir:  1, water: true },

            { node: 'N3_0',      prevNode: 'N3_1', dir: -1 },
            { node: 'N2_1',      prevNode: 'N3_0',   dir: -1 },
            { node: 'N2_0',      prevNode: 'N2_1',   dir: -1 },
            { node: 'N2_2',      prevNode: 'N2_1',   dir:  1 },
            { node: 'N2_3',      prevNode: 'N2_2',   dir:  1 },

            { node: 'N1_3',      prevNode: 'N2_0',   dir:  1 },
            { node: 'N1_2',      prevNode: 'N2_0',   dir: -1 },

            { node: 'NStorage',   prevNode: 'N1_3',     dir:  1, water: true },
            { node: 'NStoreFeed', prevNode: 'NStorage', dir: -1 },

        ],
        DEAD_LEG_TO_ALL: [
            { node: 'NDeadLeg',  prevNode: null,       dir: -1 },
            { node: 'N6_0',      prevNode: 'NDeadLeg', dir: -1 },
            { node: 'N3_3',      prevNode: 'N6_0', dir:  1 },
            { node: 'N3_4',      prevNode: 'N3_3', dir:  1 },
            { node: 'N3_2',      prevNode: 'N6_0', dir: -1 },
            { node: 'N3_1',      prevNode: 'N3_2', dir: -1 },

            { node: 'N4',        prevNode: 'N3_1', dir:  1 },
            { node: 'T4N',       prevNode: 'N4',   dir:  1, water: true },
            { node: 'NULL_N4',   prevNode: 'T4N',  dir:  1, water: true },

            { node: 'N3_0',      prevNode: 'N3_1', dir: -1 },
            { node: 'N2_1',      prevNode: 'N3_0',   dir: -1 },
            { node: 'N2_0',      prevNode: 'N2_1',   dir: -1 },
            { node: 'N2_2',      prevNode: 'N2_1',   dir:  1 },
            { node: 'N2_3',      prevNode: 'N2_2',   dir:  1 },

            { node: 'N1_3',      prevNode: 'N2_0',   dir:  1 },
            { node: 'N1_2',      prevNode: 'N2_0',   dir: -1 },
            { node: 'N1_1',      prevNode: 'N1_2',   dir: -1 },

            { node: 'NStorage',   prevNode: 'N1_3',     dir:  1, water: true },
            { node: 'NStoreFeed', prevNode: 'NStorage', dir: -1 },

        ],
        DEAD_LEG_TO_SINK_TAP: [
            { node: 'NDeadLeg',  prevNode: null,       dir: -1 },
            { node: 'N6_0',      prevNode: 'NDeadLeg', dir: -1 },
            { node: 'N3_2',      prevNode: 'N6_0',     dir: -1 },
            { node: 'N3_1',      prevNode: 'N3_2',     dir: -1 },
            { node: 'N4',        prevNode: 'N3_1',     dir:  1 },
            { node: 'T4N',       prevNode: 'N4',       dir:  1, water: true },
            { node: 'NULL_N4',   prevNode: 'T4N',      dir:  1, water: true },
        ],
        DEAD_LEG_TO_BURST_TWO: [
            { node: 'NDeadLeg',  prevNode: null,       dir: -1 },
            { node: 'N6_0',      prevNode: 'NDeadLeg', dir: -1 },
            { node: 'N3_2',      prevNode: 'N6_0',     dir: -1 },
            { node: 'N3_1',      prevNode: 'N3_2',     dir: -1 },
            { node: 'N3_0',      prevNode: 'N3_1',     dir: -1 },
            { node: 'N2_1',      prevNode: 'N3_0',     dir: -1 },
        ],
        RPZ_TO_TANK_JUNCTION: [
            { node: 'N6_1',    prevNode: null,      dir: -1 },
            { node: 'N6_0',    prevNode: 'N6_1',    dir: -1 },
            { node: 'N3_3b_0', prevNode: 'N6_0',    dir: -1 },
            { node: 'N3_2',    prevNode: 'N3_3b_0', dir: -1 },
            { node: 'N3_2',    prevNode: 'N3_3b_0', dir: -1 },
        ],
        TANK_TO_TANK_JUNCTION: [
            { node: 'N5',      prevNode: null,    dir: -1 },
        ],
        TANK_JUNCTION_TO_PIPE_BURST: [
            // { node: 'N3_1',    prevNode: 'N3_2',  dir: -1 },
            { node: 'N3_1',    prevNode: 'N5',    dir: -1 },
            { node: 'N3_0',    prevNode: 'N3_1',  dir: -1 },
            { node: 'N1_2',    prevNode: 'N3_0',  dir: -1 },
            { node: 'N1_1',      prevNode: 'N1_2',   dir: -1 },
            
            { node: 'N4',      prevNode: 'N3_1',  dir:  1 },
            { node: 'T4N',     prevNode: 'N4',    dir:  1, water: true },
            { node: 'NULL_N4', prevNode: 'T4N',   dir:  1, water: true },
        ],
        TANK_TO_SINK_TAP: [
            { node: 'N5',      prevNode: null,    dir: -1 },
            { node: 'N3_1',    prevNode: 'N5',    dir: -1 },
            { node: 'N4',        prevNode: 'N3_1',     dir:  1 },
            { node: 'T4N',       prevNode: 'N4',       dir:  1, water: true },
            { node: 'NULL_N4',   prevNode: 'T4N',      dir:  1, water: true },
        ],
        PIPE_BURST_TO_BOUNDARY_BOX: [
            { node: 'N1_1',    prevNode: 'N1_2',  dir: -1 },
        ],
        PIPE_BURST: [
            { node: 'T1N',    prevNode: 'N1_2',   dir:  1, water: true },
        ],
        TANK_TO_SCV: [
            { node: 'N5',      prevNode: null,    dir: -1 },
            // { node: 'N3_1',    prevNode: 'N3_2',  dir: -1 },
            { node: 'N3_1',    prevNode: 'N5',    dir: -1 },
            { node: 'N3_0',    prevNode: 'N3_1',  dir: -1 },
            { node: 'N2_1',    prevNode: 'N3_0',  dir: -1 },
            { node: 'N2_0',    prevNode: 'N2_1',  dir: -1 },

            { node: 'N2_2',    prevNode: 'N3_0',  dir:  1 },
            { node: 'N2_3',    prevNode: 'N2_2',  dir:  1 },

            { node: 'N4',      prevNode: 'N3_1',  dir:  1 },
            { node: 'T4N',     prevNode: 'N4',    dir:  1, water: true },
            { node: 'NULL_N4', prevNode: 'T4N',   dir:  1, water: true },
            { node: 'N1_2',    prevNode: 'N2_0',  dir: -1 },
            { node: 'T1N_2',   prevNode: 'N2_1',  dir:  1, water: true },
        ],
        TANK_TO_BOUNDARY_BOX: [
            { node: 'N5',      prevNode: null,    dir: -1 },
            // { node: 'N3_1',    prevNode: 'N3_2',  dir: -1 },
            { node: 'N3_1',    prevNode: 'N5',    dir: -1 },
            { node: 'N3_0',    prevNode: 'N3_1',  dir: -1 },
            { node: 'N2_1',    prevNode: 'N3_0',  dir: -1 },
            { node: 'N2_0',    prevNode: 'N2_1',  dir: -1 },

            { node: 'N2_2',    prevNode: 'N3_0',  dir:  1 },
            { node: 'N2_3',    prevNode: 'N2_2',  dir:  1 },

            { node: 'N4',      prevNode: 'N3_1',  dir:  1 },
            { node: 'T4N',     prevNode: 'N4',    dir:  1, water: true },
            { node: 'NULL_N4', prevNode: 'T4N',   dir:  1, water: true },
            { node: 'N1_2',    prevNode: 'N2_0',  dir: -1 },
            { node: 'N1_1',    prevNode: 'N1_2',  dir: -1 },
            { node: 'T1N_2',   prevNode: 'N2_1',  dir:  1, water: true },
        ],
        TANK_TO_ALL: [
            { node: 'N5',      prevNode: null,    dir: -1 },
            { node: 'N3_1',      prevNode: 'N5', dir: -1 },
            { node: 'N3_2',      prevNode: 'N5', dir:  1 },

            { node: 'N6_0',  prevNode: 'N3_2', dir:  1 },
            { node: 'N6_1',  prevNode: 'N6_0', dir:  1 },
            { node: 'N6_2',  prevNode: 'N6_1', dir:  1 },

            { node: 'N3_3',      prevNode: 'N3_2', dir:  1 },
            { node: 'N3_4',      prevNode: 'N3_3', dir:  1 },

            // { node: 'N6_1',  prevNode: 'N6_0', dir:  1 },
            // { node: 'N6_2',  prevNode: 'N6_1', dir:  1 },

            // { node: 'N3_3',      prevNode: 'N6_0', dir:  1 },
            // { node: 'N3_4',      prevNode: 'N3_3', dir:  1 },
            // { node: 'N3_2',      prevNode: 'N6_0', dir:  1 },

            { node: 'N4',        prevNode: 'N3_1', dir:  1 },
            { node: 'T4N',       prevNode: 'N4',   dir:  1, water: true },
            { node: 'NULL_N4',   prevNode: 'T4N',  dir:  1, water: true },

            { node: 'N3_0',      prevNode: 'N3_1', dir: -1 },
            { node: 'N2_1',      prevNode: 'N3_0',   dir: -1 },
            { node: 'N2_0',      prevNode: 'N2_1',   dir: -1 },
            { node: 'N2_2',      prevNode: 'N3_0',   dir:  1 },
            { node: 'N2_3',      prevNode: 'N2_2',   dir:  1 },

            { node: 'T1N_2',     prevNode: 'N2_1',  dir:  1, water: true },

            { node: 'N1_3',      prevNode: 'N2_0',   dir:  1 },
            { node: 'N1_2',      prevNode: 'N2_0',   dir: -1 },
            { node: 'N1_1',      prevNode: 'N1_2',   dir: -1 },
            { node: 'N1_0',      prevNode: 'N1_1',   dir: -1 },

            { node: 'NStorage',   prevNode: 'N1_3',     dir:  1, water: true },
            { node: 'NStoreFeed', prevNode: 'NStorage', dir: -1 },

            { node: 'N',      prevNode: 'N1_0',   dir:  1 },
            { node: 'MH',    prevNode: 'N1_0',    dir: -1 },
            { node: 'MI',    prevNode: 'MH',   dir: -1 },
            { node: 'MJ',    prevNode: 'MI',   dir: -1 },

        ],
    },
};

export const CONTAMINATION_DURATION_MULTIPLIER = 1;

export const METER = {
    pressure:     0,
    flow:         0,
    conductivity: 0,
};

const HOUSEHOLD_ACTION_ROUTES = [
    [
        { id: 'T1H', state: 'Unsafe' },
    ],
    [
        { id: 'T1H', state: 'High Pressure' },
        { id: 'T7H', state: true },
    ],
    [
        { id: 'T1H', state: 'High Pressure' },
        { id: 'T13H', state: true },
    ],
    [
        { id: 'T1H', state: 'High Pressure' },
        { id: 'T12HCistern', state: true },
    ],
    [
        { id: 'T1H', state: 'High Pressure' },
        { id: 'T11H', state: true },
    ],
    [
        { id: 'T1H', state: 'High Pressure' },
        { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
        { id: 'T4HSeepage', state: true },
    ],
    [
        { id: 'T2H', state: true },
        { id: 'T7H', state: true },
    ],
    [
        { id: 'T2H', state: true },
        { id: 'T13H', state: true },
    ],
    [
        { id: 'T2H', state: true },
        { id: 'T12HCistern', state: true },
    ],
    [
        { id: 'T2H', state: true },
        { id: 'T11H', state: true },
    ],
    [
        { id: 'T2H', state: true },
        { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
        { id: 'T4HSeepage', state: true },
    ],
    [
        { id: 'T3H', state: true },
        { id: 'T7H', state: true },
    ],
    [
        { id: 'T3H', state: true },
        { id: 'T13H', state: true },
    ],
    [
        { id: 'T3H', state: true },
        { id: 'T12HCistern', state: true },
    ],
    [
        { id: 'T3H', state: true },
        { id: 'T11H', state: true },
    ],
    [
        { id: 'T3H', state: true },
        { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
        { id: 'T4HSeepage', state: true },
    ],
    [
        { id: 'T5H', state: true },
    ],
    [
        { id: 'T6H', state: true },
        { id: 'T7H', state: true },
    ],
    [
        { id: 'T6H', state: true },
        { id: 'T13H', state: true },
    ],
    [
        { id: 'T6H', state: true },
        { id: 'T12HCistern', state: true },
    ],
    [
        { id: 'T6H', state: true },
        { id: 'T11H', state: true },
    ],
    [
        { id: 'T6H', state: true },
        { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
        { id: 'T4HSeepage', state: true },
    ],
    [
        { id: 'T8H', state: ['Clean','Non-compliant','Contaminated'] },
    ],
    [
        { id: 'T9H', state: true },
        { id: 'T10H', state: true },
    ],


    // [
    //     { id: 'T1H', state: 'Unsafe' },
    // ],
    // [
    //     { id: 'T1H', state: 'High Pressure' },
    //     { id: 'T7H', state: true },
    // ],
    // [
    //     { id: 'T1H', state: 'High Pressure' },
    //     { id: 'T13H', state: true },
    // ],
    // [
    //     { id: 'T1H', state: 'High Pressure' },
    //     { id: 'T12HCistern', state: true },
    // ],
    // [
    //     { id: 'T1H', state: 'High Pressure' },
    //     { id: 'T11H', state: true },
    // ],
    // [
    //     { id: 'T1H', state: 'High Pressure' },
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    //     { id: 'T4HSeepage', state: true },
    // ],
    // [
    //     { id: 'T1H', state: 'High Pressure' },
    //     { id: 'T4HSeepage', state: true },
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    // ],

    // [
    //     { id: 'T2H', state: true },
    //     { id: 'T7H', state: true },
    // ],
    // [
    //     { id: 'T2H', state: true },
    //     { id: 'T13H', state: true },
    // ],
    // [
    //     { id: 'T2H', state: true },
    //     { id: 'T12HCistern', state: true },
    // ],
    // [
    //     { id: 'T2H', state: true },
    //     { id: 'T11H', state: true },
    // ],
    // [
    //     { id: 'T2H', state: true },
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    //     { id: 'T4HSeepage', state: true },
    // ],
    // [
    //     { id: 'T2H', state: true },
    //     { id: 'T4HSeepage', state: true },
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    // ],

    // [
    //     { id: 'T3H', state: true },
    //     { id: 'T7H', state: true },
    // ],
    // [
    //     { id: 'T3H', state: true },
    //     { id: 'T13H', state: true },
    // ],
    // [
    //     { id: 'T3H', state: true },
    //     { id: 'T12HCistern', state: true },
    // ],
    // [
    //     { id: 'T3H', state: true },
    //     { id: 'T11H', state: true },
    // ],
    // [
    //     { id: 'T3H', state: true },
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    //     { id: 'T4HSeepage', state: true },
    // ],
    // [
    //     { id: 'T3H', state: true },
    //     { id: 'T4HSeepage', state: true },
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    // ],

    // [
    //     { id: 'T4HSeepage', state: true },
    //     { id: 'T1H', state: 'High Pressure' },
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    // ],
    // [
    //     { id: 'T4HSeepage', state: true },
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    //     { id: 'T1H', state: 'High Pressure' },
    // ],
    // [
    //     { id: 'T4HSeepage', state: true },
    //     { id: 'T2H', state: true },
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    // ],
    // [
    //     { id: 'T4HSeepage', state: true },
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    //     { id: 'T2H', state: true },
    // ],
    // [
    //     { id: 'T4HSeepage', state: true },
    //     { id: 'T3H', state: true },
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    // ],
    // [
    //     { id: 'T4HSeepage', state: true },
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    //     { id: 'T3H', state: true },
    // ],
    // [
    //     { id: 'T4HSeepage', state: true },
    //     { id: 'T6H', state: true },
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    // ],
    // [
    //     { id: 'T4HSeepage', state: true },
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    //     { id: 'T6H', state: true },
    // ],

    // [
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    //     { id: 'T1H', state: 'High Pressure' },
    //     { id: 'T4HSeepage', state: true },
    // ],
    // [
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    //     { id: 'T4HSeepage', state: true },
    //     { id: 'T1H', state: 'High Pressure' },
    // ],
    // [
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    //     { id: 'T2H', state: true },
    //     { id: 'T4HSeepage', state: true },
    // ],
    // [
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    //     { id: 'T4HSeepage', state: true },
    //     { id: 'T2H', state: true },
    // ],
    // [
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    //     { id: 'T3H', state: true },
    //     { id: 'T4HSeepage', state: true },
    // ],
    // [
    //     { id: 'T4H', state: ['MDPE','Copper','Barrier'] },
    //     { id: 'T4HSeepage', state: true },
    //     { id: 'T3H', state: true },
    // ],

    // [
    //     { id: 'T5H', state: true },
    // ],

    // [
    //     { id: 'T13H', state: true },
    //     { id: 'T1H', state: 'High Pressure' },
    // ],
    // [
    //     { id: 'T13H', state: true },
    //     { id: 'T2H', state: true },
    // ],
    // [
    //     { id: 'T13H', state: true },
    //     { id: 'T3H', state: true },
    // ],
    // [
    //     { id: 'T13H', state: true },
    //     { id: 'T6H', state: true },
    // ],
    
    // [
    //     { id: 'T6H', state: true },
    //     { id: 'T7H', state: true },
    // ],
    // [
    //     { id: 'T6H', state: true },
    //     { id: 'T13H', state: true },
    // ],
    // [
    //     { id: 'T6H', state: true },
    //     { id: 'T12HCistern', state: true },
    // ],
    // [
    //     { id: 'T6H', state: true },
    //     { id: 'T11H', state: true },
    // ],

    // [
    //     { id: 'T7H', state: true },
    //     { id: 'T1H', state: 'High Pressure' },
    // ],
    // [
    //     { id: 'T7H', state: true },
    //     { id: 'T2H', state: true },
    // ],
    // [
    //     { id: 'T7H', state: true },
    //     { id: 'T3H', state: true },
    // ],
    // [
    //     { id: 'T7H', state: true },
    //     { id: 'T6H', state: true },
    // ],

    // [
    //     { id: 'T8H', state: ['Clean','Non-compliant','Contaminated'] },
    // ],

    // [
    //     { id: 'T9H', state: false },
    //     { id: 'T10H', state: true },
    // ],
    // [
    //     { id: 'T10H', state: true },
    //     { id: 'T9H', state: false },
    // ],

    // [
    //     { id: 'T11H', state: true },
    //     { id: 'T1H', state: 'High Pressure' },
    // ],
    // [
    //     { id: 'T11H', state: true },
    //     { id: 'T2H', state: true },
    // ],
    // [
    //     { id: 'T11H', state: true },
    //     { id: 'T3H', state: true },
    // ],
    // [
    //     { id: 'T11H', state: true },
    //     { id: 'T6H', state: true },
    // ],

    // [
    //     { id: 'T12HCistern', state: true },
    //     { id: 'T1H', state: 'High Pressure' },
    // ],
    // [
    //     { id: 'T12HCistern', state: true },
    //     { id: 'T2H', state: true },
    // ],
    // [
    //     { id: 'T12HCistern', state: true },
    //     { id: 'T3H', state: true },
    // ],
    // [
    //     { id: 'T12HCistern', state: true },
    //     { id: 'T6H', state: true },
    // ],
];

const AGRICULTURAL_ACTION_ROUTES = [
    [
        { id: 'T1A', state: true },
        { id: 'T4A', state: true },
    ],
    [
        { id: 'T1A', state: true },
        { id: 'T5A', state: true },
        { id: 'T6A', state: true },
    ],
    [
        { id: 'T1A', state: true },
        { id: 'T6A', state: true },
        { id: 'T5A', state: true },
    ],

    [
        { id: 'T2A', state: true },
        { id: 'T4A', state: true },
    ],

    [
        { id: 'T3A', state: true },
        { id: 'T4A', state: true },
    ],
    [
        { id: 'T3A', state: true },
        { id: 'T5A', state: true },
        { id: 'T6A', state: true },
    ],
    [
        { id: 'T3A', state: true },
        { id: 'T6A', state: true },
        { id: 'T5A', state: true },
    ],

    [
        { id: 'T4A', state: true },
        { id: 'T1A', state: true },
    ],
    [
        { id: 'T4A', state: true },
        { id: 'T2A', state: true },
    ],
    [
        { id: 'T4A', state: true },
        { id: 'T3A', state: true },
    ],

    [
        { id: 'T5A', state: true },
        { id: 'T1A', state: true },
        { id: 'T6A', state: true },
    ],
    [
        { id: 'T5A', state: true },
        { id: 'T6A', state: true },
        { id: 'T1A', state: true },
    ],
    [
        { id: 'T5A', state: true },
        { id: 'T2A', state: true },
        { id: 'T6A', state: true },
    ],
    [
        { id: 'T5A', state: true },
        { id: 'T6A', state: true },
        { id: 'T2A', state: true },
    ],
    [
        { id: 'T5A', state: true },
        { id: 'T3A', state: true },
        { id: 'T6A', state: true },
    ],
    [
        { id: 'T5A', state: true },
        { id: 'T6A', state: true },
        { id: 'T3A', state: true },
    ],

    [
        { id: 'T6A', state: true },
        { id: 'T1A', state: true },
        { id: 'T5A', state: true },
    ],
    [
        { id: 'T6A', state: true },
        { id: 'T5A', state: true },
        { id: 'T1A', state: true },
    ],
    [
        { id: 'T6A', state: true },
        { id: 'T2A', state: true },
        { id: 'T5A', state: true },
    ],
    [
        { id: 'T6A', state: true },
        { id: 'T5A', state: true },
        { id: 'T2A', state: true },
    ],
    [
        { id: 'T6A', state: true },
        { id: 'T3A', state: true },
        { id: 'T5A', state: true },
    ],
    [
        { id: 'T6A', state: true },
        { id: 'T5A', state: true },
        { id: 'T3A', state: true },
    ],
];

const NON_HOUSEHOLD_ACTION_ROUTES = [
    [
        { id: 'T1N', state: true },
        { id: 'T5N', state: true },
        { id: 'T4N', state: [true,false] },
    ],
    [
        { id: 'T1N', state: true },
        { id: 'T6N', state: ['None','RPZ'] },
        { id: 'T4N', state: [true,false] },
    ],

    [
        { id: 'T2N', state: true },
        { id: 'T5N', state: true },
        { id: 'T4N', state: [true,false] },
    ],
    [
        { id: 'T2N', state: true },
        { id: 'T6N', state: ['None','RPZ'] },
        { id: 'T4N', state: [true,false] },
    ],

    [
        { id: 'T3N', state: true },
    ],
    
    [
        { id: 'T4N', state: [true,false] },
        { id: 'T1N', state: true },
        { id: 'T6N', state: ['None','RPZ'] },
    ],
    [
        { id: 'T4N', state: [true,false] },
        { id: 'T6N', state: ['None','RPZ'] },
        { id: 'T1N', state: true },
    ],
    [
        { id: 'T4N', state: [true,false] },
        { id: 'T2N', state: true },
        { id: 'T6N', state: ['None','RPZ'] },
    ],
    [
        { id: 'T4N', state: [true,false] },
        { id: 'T6N', state: ['None','RPZ'] },
        { id: 'T2N', state: true },
    ],
    
    [
        { id: 'T5N', state: true },
        { id: 'T1N', state: true },
        { id: 'T4N', state: [true,false] },
    ],
    [
        { id: 'T5N', state: true },
        { id: 'T4N', state: [true,false] },
        { id: 'T1N', state: true },
    ],
    [
        { id: 'T5N', state: true },
        { id: 'T2N', state: true },
        { id: 'T4N', state: [true,false] },
    ],
    [
        { id: 'T5N', state: true },
        { id: 'T4N', state: [true,false] },
        { id: 'T2N', state: true },
    ],
    
    [
        { id: 'T6N', state: ['None','RPZ'] },
        { id: 'T1N', state: true },
        { id: 'T4N', state: [true,false] },
    ],
    [
        { id: 'T6N', state: ['None','RPZ'] },
        { id: 'T4N', state: [true,false] },
        { id: 'T1N', state: true },
    ],
    [
        { id: 'T6N', state: ['None','RPZ'] },
        { id: 'T2N', state: true },
        { id: 'T4N', state: [true,false] },
    ],
    [
        { id: 'T6N', state: ['None','RPZ'] },
        { id: 'T4N', state: [true,false] },
        { id: 'T2N', state: true },
    ],
    
    [
        { id: 'T7N', state: true },
    ],
    
];

export const ACTION_ROUTES = [
    ...HOUSEHOLD_ACTION_ROUTES,
    ...AGRICULTURAL_ACTION_ROUTES,
    ...NON_HOUSEHOLD_ACTION_ROUTES,
];

// {
//     T1H: [T4HSeepage],
// }

// fix info positioning
// fix meter positioning
// remove select box colour change on touching hotspot