import { useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import YouTube from 'react-youtube';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { overlayVisibleAtom } from '../recoil/state.recoil';

const Container = styled.div`
position: absolute;
z-index: 9999999;
top: 50%;
left: 50%;
width: ${p => p.width}px;
height: ${p => p.height}px;
color: white;
background-color: #1a1a1a;
box-shadow: 0 0 1rem 1rem rgba(0,0,0, 0.2);
transition: transform 0.3s ease-in-out;
// transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
`;

const CloseButtonStyles = styled.a`
position: absolute;
top: 0;
right: -4rem;
width: 3rem;
height: 3rem;
border: 1px solid #0b3647;
border-radius: 50%;
color: #0b3647;
background-color: transparent;
font-size: 3rem;
text-decoration: none;
display: flex;
justify-content: center;
align-items: center;
`;

const CloseButton = ({ clickHandler }) => {
    return <CloseButtonStyles href='#' onClick={clickHandler}>
        &times;
    </CloseButtonStyles>
};

const Link = styled.a`
position: absolute;
z-index: 999999;
top: 0;
left: 50%;

color: #0b3647;
text-decoration: none;

transition: transform 0.2s ease-in-out;
transform: translateX(-50%) scale3d(1, 1, 1);

&:hover,
&:focus,
&:active {
    transform: translateX(-50%) scale3d(1.4, 1.4, 1.4);
}
`;

export const InfoVideo = () => {
    const [ showOverlay, setShowOverlay ] = useRecoilState(overlayVisibleAtom);
    const closeHandler = e => {
        e.preventDefault();
        setShowOverlay(false);
    };
    const { width, height } = useMemo(() => {
        // return { width: 1280, height: 1280 * (616 / 1080) };
        return { width: 1080, height: 616 };
    }, []);
    return <Container width={width} height={height}>
        <CloseButton clickHandler={closeHandler} />
        <YouTube videoId='1-rPOcCCtJQ' opts={{
            width,
            height,
            playerVars: {
                autoplay: 1,
                controls: 0,
                modestbranding: 1,
            },
        }} />
    </Container>
};

export const InfoLink = ({ scenario }) => {
    const [ showOverlay, setShowOverlay ] = useRecoilState(overlayVisibleAtom);
    const clickHandler = e => {
        e.preventDefault();
        setShowOverlay(true);
    };
    switch (parseInt(scenario)) {
        case 2:
            return <>
                <Link href='#' target='_blank' onClick={clickHandler}>
                    <FontAwesomeIcon icon={faInfoCircle} size='3x' />
                </Link>
            </>
        default:
            return null;
    }
};

export default InfoLink;