import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { PIPES, HOTSPOTS, WATER, METER, ACTION_ROUTES } from '../config/constants';

export const overlayVisibleAtom = atom({
    key: 'overlayVisible',
    default: false,
});

// pipesAtom contains all pipes across all scenarios
// can be filtered by scenario using selectors
// lengths are calculated when SVG paths are mounted
// otherwise read only
export const pipesAtom = atom({
    key: 'pipes',
    default: PIPES,
});

export const scenarioPipesSelectorFamily = selectorFamily({
    key: 'scenarioPipes',
    get: scenario => ({ get }) => get(pipesAtom).filter(_ => _.scenario === scenario),
});

export const scenarioPipeIdsSelectorFamily = selectorFamily({
    key: 'scenarioPipeIds',
    get: scenario => ({ get }) => get(scenarioPipesSelectorFamily(scenario)).map(_ => _.id),
});

export const scenarioPipeLengthsSelectorFamily = selectorFamily({
    key: 'scenarioPipeLengths',
    get: scenario => ({ get }) => {
        const pipeIds = get(scenarioPipeIdsSelectorFamily(scenario));
        const pipeLengths = pipeIds.reduce((obj, id) => {
            const { length } = get(pipeSelectorFamily(id));
            obj[id] = length;
            return obj;
        }, {});
        return pipeLengths;
    },
});

export const pipeSelectorFamily = selectorFamily({
    key: 'pipe',
    get: id => ({ get }) => get(pipesAtom).find(_ => _.id === id),
    set: id => ({ set }, val) => set(pipesAtom, state => ([ ...state.filter(_ => _.id !== id), val ])),
});

// action routes
export const actionRoutesAtom = atom({
    key: 'actionRoutes',
    default: ACTION_ROUTES
});

export const validRoutesAtom = atom({
    key: 'validRoutes',
    default: ACTION_ROUTES
});

export const currentRouteAtom = atom({
    key: 'currentRoute',
    default: []
});

export const currentRouteIdxSelector = selector({
    key: 'currentRouteIdx',
    get: ({ get }) => Math.max(get(currentRouteAtom).length - 1, 0),
});

export const resetActionRouteSelector = selector({
    key: 'resetActionRoute',
    set: ({ set }) => set(currentRouteAtom, [])
});

// hotspotsAtom contains all hotspots across all scenarios
// can be filtered by scenario using selectors
// has state that changes, mostly booleans
export const hotspotsAtom = atom({
    key: 'hotspots',
    default: HOTSPOTS,
});

export const scenarioHotspotsSelectorFamily = selectorFamily({
    key: 'scenarioHotspots',
    get: scenario => ({ get }) => get(hotspotsAtom).filter(_ => _.scenario === scenario),
    set: scenario => ({ set }, val) => {
        set(hotspotsAtom, state => ([ ...state.filter(_ => _.scenario !== scenario && _.id !== scenario), ...val ]));
    },
});

export const scenarioHotspotIdsSelectorFamily = selectorFamily({
    key: 'scenarioHotspotIds',
    get: scenario => ({ get }) => get(scenarioHotspotsSelectorFamily(scenario)).map(_ => _.id),
});

export const hotspotSelectorFamily = selectorFamily({
    key: 'hotspot',
    get: id => ({ get }) => get(hotspotsAtom).find(_ => _.id === id),
    set: id => ({ set }, val) => set(hotspotsAtom, state => ([ ...state.filter(_ => _.id !== id), { ...val, timestamp: Date.now()} ])),
});

export const multiChoiceOpenAtom = atom({
    key: 'multiChoice',
    default: '',
});

export const showResetButtonAtom = atom({
    key: 'showResetButton',
    default: false,
});

// flow
export const flowAtom = atom({
    key: 'flow',
    default: [],
});

export const pipeFlowDirectionSelectorFamily = selectorFamily({
    key: 'pipeFlowDirection',
    get: id => ({ get }) => get(flowAtom).find(_ => _.id === id)?.dir || 0,
});

// contamination
export const contaminationAtomFamily = atomFamily({
    key: 'contamination',
    default: [],
});

// water
export const waterAtom = atom({
    key: 'water',
    default: WATER,
});

export const waterSelectorFamily = selectorFamily({
    key: 'waterSelector',
    get: id => ({ get }) => get(waterAtom).find(_ => _.id === id),
});

// meter
export const meterAtomFamily = atomFamily({
    key: 'meter',
    default: METER,
});

export const pressureSelectorFamily = selectorFamily({
    key: 'pressure',
    get: scenario => ({ get }) => get(meterAtomFamily(scenario)).pressure,
});

export const flowSelectorFamily = selectorFamily({
    key: 'flow',
    get: scenario => ({ get }) => get(meterAtomFamily(scenario)).flow,
});

export const conductivitySelectorFamily = selectorFamily({
    key: 'conductivity',
    get: scenario => ({ get }) => get(meterAtomFamily(scenario)).conductivity,
});

export const boreholePressureSelectorFamily = selectorFamily({
    key: 'boreholePressure',
    get: scenario => ({ get }) => get(meterAtomFamily(scenario)).boreholePressure,
});

// selectors
// household
export const isHydrantOnSelector = selector({
    key: 'isHydrantOn',
    get: ({ get }) => get(hotspotSelectorFamily('T1H_OnOff')).value,
});

// export const isHydrantDCVSelector = selector({
//     key: 'isHydrantDCV',
//     // get: ({ get }) => {
//     //     const hotspot = get(hotspotSelectorFamily('T1H_DCV'));
//     //     const selected = hotspot.selected;
//     //     if (selected === 'With DCV')
//     //         return true;
//     //     return false;
//     // },
//     get: ({ get }) => get(hotspotSelectorFamily('T1H_DCV')).value,
// });

export const isOutsideTapDCVSelector = selector({
    key: 'isOutsideTapDCV',
    // get: ({ get }) => {
    //     const hotspot = get(hotspotSelectorFamily('T13H_DCV'));
    //     const selected = hotspot.selected;
    //     if (selected === 'With DCV')
    //         return true;
    //     return false;
    // },
    get: ({ get }) => get(hotspotSelectorFamily('T13H_DCV')).value,
});

export const isWashingMachineOnSelector = selector({
    key: 'isWashingMachineOn',
    get: ({ get }) => get(hotspotSelectorFamily('T9H_OnOff')).value,
});

export const isWashingMachineSCVSelector = selector({
    key: 'isWashingMachineSCV',
    // get: ({ get }) => {
    //     const hotspot = get(hotspotSelectorFamily('T9H'));
    //     const selected = hotspot.selected;
    //     if (selected === 'With SCV')
    //         return true;
    //     return false;
    // },
    get: ({ get }) => get(hotspotSelectorFamily('T9H')).value,
});

export const isShowerDCVSelector = selector({
    key: 'isShowerDCV',
    // get: ({ get }) => {
    //     const hotspot = get(hotspotSelectorFamily('T11H_DCV'));
    //     const selected = hotspot.selected;
    //     if (selected === 'With DCV')
    //         return true;
    //     return false;
    // },
    get: ({ get }) => get(hotspotSelectorFamily('T11H_DCV')).value,
});

export const isHouseholdOffscreenRightSelector = selector({
    key: 'isHouseholdOffscreenRight',
    get: ({ get }) => get(hotspotSelectorFamily('HOffRight')).value,
});

export const isHydrantOpenSelector = selector({
    key: 'isHydrantOpen',
    // get: ({ get }) => get(hotspotSelectorFamily('T1H')).value,
    get: ({ get }) => get(isHydrantHighPressureSelector),
});

export const isHydrantSafeSelector = selector({
    key: 'isHydrantSafe',
    get: ({ get }) => {
        const hotspot = get(hotspotSelectorFamily('T1H'));
        const selected = hotspot.selected;
        if (selected === 'Safe')
            return true;
        return false;
    },
});

export const isHydrantUnsafeSelector = selector({
    key: 'isHydrantUnsafe',
    get: ({ get }) => {
        const hotspot = get(hotspotSelectorFamily('T1H'));
        const selected = hotspot.selected;
        if (selected === 'Unsafe')
            return true;
        return false;
    },
});

export const isHydrantHighPressureSelector = selector({
    key: 'isHydrantHighPressure',
    get: ({ get }) => {
        const hotspot = get(hotspotSelectorFamily('T1H'));
        const selected = hotspot.selected;
        if (selected === 'High Pressure')
            return true;
        return false;
    },
});

export const isHouseholdPipeBurstSelector = selector({
    key: 'isHouseholdPipeBurst',
    get: ({ get }) => get(hotspotSelectorFamily('T2H')).value,
});

export const isHouseholdBoundaryBoxOpenSelector = selector({
    key: 'isHouseholdBoundaryBoxOpen',
    get: ({ get }) => get(hotspotSelectorFamily('T3H')).value,
});

export const isStopCockOpenSelector = selector({
    key: 'isStopCockOpen',
    get: ({ get }) => get(hotspotSelectorFamily('T6H')).value,
});

export const isHouseholdNegativePressureSelector = selector({
    key: 'isHouseholdNegativePressure',
    get: ({ get }) => get(isHydrantOnSelector) || get(isHouseholdPipeBurstSelector) ? true : false,
});

export const isSeepageSelector = selector({
    key: 'isSeepage',
    // get: ({ get }) => {
    //     const hotspot = get(hotspotSelectorFamily('T4H'));
    //     const selected = hotspot.selected;
    //     const correct = hotspot.correct;
    //     if (selected === correct)
    //         return true;
    //     return false;
    // },
    get: ({ get }) => get(hotspotSelectorFamily('T4HSeepage')).value,
});

export const isMdpePipeSelector = selector({
    key: 'isMdpePipe',
    get: ({ get }) => {
        const hotspot = get(hotspotSelectorFamily('T4H'));
        const selected = hotspot.selected;
        if (selected === 'MDPE')
            return true;
        return false;
    },
});

export const isCopperPipeSelector = selector({
    key: 'isCopperPipe',
    get: ({ get }) => {
        const hotspot = get(hotspotSelectorFamily('T4H'));
        const selected = hotspot.selected;
        if (selected === 'Copper')
            return true;
        return false;
    },
});

export const isBarrierPipeSelector = selector({
    key: 'isBarrierPipe',
    get: ({ get }) => {
        const hotspot = get(hotspotSelectorFamily('T4H'));
        const selected = hotspot.selected;
        if (selected === 'Barrier')
            return true;
        return false;
    },
});

export const isSeepageProofPipeMaterialSelector = selector({
    key: 'isSeepageProofPipeMaterial',
    get: ({ get }) => {
        const isCopper = get(isCopperPipeSelector);
        const isBarrier = get(isBarrierPipeSelector);
        if (isCopper || isBarrier)
            return true;
        return false;
    },
});

export const isContaminatedPipeSelector = selector({
    key: 'isContaminatedPipe',
    get: ({ get }) => get(hotspotSelectorFamily('T5H')).value,
});

export const isFillLoopConnectedSelector = selector({
    key: 'isFillLoopConnected',
    get: ({ get }) => get(hotspotSelectorFamily('T7H')).value,
});

export const isFillLoopWithoutDCVSelector = selector({
    key: 'isFillLoopWithoutDCV',
    // get: ({ get }) => {
    //     const hotspot = get(hotspotSelectorFamily('T7H'));
    //     const selected = hotspot.selected;
    //     if (selected === 'Without DCV')
    //         return true;
    //     return false;
    // },
    get: ({ get }) => get(hotspotSelectorFamily('T7H')).value,
});

export const isFillLoopWithDCVSelector = selector({
    key: 'isFillLoopWithDCV',
    // get: ({ get }) => {
    //     const hotspot = get(hotspotSelectorFamily('T7H'));
    //     const selected = hotspot.selected;
    //     if (selected === 'With DCV')
    //         return true;
    //     return false;
    // },
    get: ({ get }) => !get(hotspotSelectorFamily('T7H')).value,
});

export const isSinkFilterFittedSelector = selector({
    key: 'isSinkFilterFitted',
    get: ({ get }) => get(hotspotSelectorFamily('T8H')).value,
});

export const isMaintainedFilterSelector = selector({
    key: 'isMaintainedFilter',
    // get: ({ get }) => {
    //     const hotspot = get(hotspotSelectorFamily('T8H'));
    //     const selected = hotspot.selected;
    //     if (selected === 'Maintained')
    //         return true;
    //     return false;
    // },
    get: ({ get }) => get(isSinkFilterFittedSelector),
});

export const isNonMaintainedFilterSelector = selector({
    key: 'isNonMaintainedFilter',
    // get: ({ get }) => {
    //     const hotspot = get(hotspotSelectorFamily('T8H'));
    //     const selected = hotspot.selected;
    //     if (selected === 'Non-Maintained')
    //         return true;
    //     return false;
    // },
    get: ({ get }) => get(isSinkFilterFittedSelector),
});

export const isCleanSinkFilterSelector = selector({
    key: 'isCleanSinkFilter',
    get: ({ get }) => {
        const hotspot = get(hotspotSelectorFamily('T8H'));
        const selected = hotspot.selected;
        if (selected === 'Clean')
            return true;
        return false;
    },
});

export const isNonCompliantSinkFilterSelector = selector({
    key: 'isNonCompliantSinkFilter',
    get: ({ get }) => {
        const hotspot = get(hotspotSelectorFamily('T8H'));
        const selected = hotspot.selected;
        if (selected === 'Non-compliant')
            return true;
        return false;
    },
});

export const isContaminatedSinkFilterSelector = selector({
    key: 'isContaminatedSinkFilter',
    get: ({ get }) => {
        const hotspot = get(hotspotSelectorFamily('T8H'));
        const selected = hotspot.selected;
        if (selected === 'Contaminated')
            return true;
        return false;
    },
});

export const isSCVFittedSelector = selector({
    key: 'isSCVFitted',
    get: ({ get }) => get(hotspotSelectorFamily('T9H')).value,
});

export const isSinkTapRunningSelector = selector({
    key: 'isSinkTapRunning',
    get: ({ get }) => get(hotspotSelectorFamily('T10H')).value,
});

export const isBathTapRunningSelector = selector({
    key: 'isBathTapRunning',
    get: ({ get }) => get(hotspotSelectorFamily('T11H')).value,
});

export const isHouseholdIncorrectAirGapSelector = selector({
    key: 'isHouseholdIncorrectAirGap',
    get: ({ get }) => get(hotspotSelectorFamily('T12HCistern')).value,
});

export const isOutsideTapRunningSelector = selector({
    key: 'isOutsideTapRunning',
    get: ({ get }) => get(hotspotSelectorFamily('T13H')).value,
});

export const isHoseSubmergedInPondSelector = selector({
    key: 'isHoseSubmergedInPond',
    get: ({ get }) => get(hotspotSelectorFamily('T13H')).value,
});

export const isShowerHeadImmersedSelector = selector({
    key: 'isShowerHeadImmersed',
    get: ({ get }) => get(isHouseholdNegativePressureSelector) && get(isBathTapRunningSelector) ? true : false,
});

export const isContaminationFromWashingMachineSelector = selector({
    key: 'isContaminationFromWashingMachine',
    get: ({ get }) => get(isWashingMachineOnSelector) && !get(isWashingMachineSCVSelector) && get(isSinkTapRunningSelector) ? true : false,
});

// agricultural
export const isStoptapOnSelector = selector({
    key: 'isStoptapOn',
    get: ({ get }) => get(hotspotSelectorFamily('T6A_Stoptap')).value,
});

export const isBoreholePump8BARSelector = selector({
    key: 'isBoreholePump8BAR',
    get: ({ get }) => get(hotspotSelectorFamily('T6A_8Bar')).value,
});

export const isAgriculturalOffscreenLeftSelector = selector({
    key: 'isAgriculturalOffscreenLeft',
    get: ({ get }) => get(hotspotSelectorFamily('AOffLeft')).value,
});

export const isAgriculturalOffscreenRightSelector = selector({
    key: 'isAgriculturalOffscreenRight',
    get: ({ get }) => get(hotspotSelectorFamily('AOffRight')).value,
});

export const isAgriculturalNegativePressureSelector = selector({
    key: 'isAgriculturalNegativePressure',
    get: ({ get }) => get(isAgriculturalPipeBurstOneSelector) ? true : false,
});

export const isAgriculturalPipeBurstOneSelector = selector({
    key: 'isAgriculturalPipeBurstOne',
    get: ({ get }) => get(hotspotSelectorFamily('T1A')).value,
});

// export const isAgriculturalPipeBurstTwoSelector = selector({
//     key: 'isAgriculturalPipeBurstTwo',
//     get: ({ get }) => get(hotspotSelectorFamily('T2A')).value,
// });

// export const isAgriculturalBoundaryBoxOpenSelector = selector({
//     key: 'isAgriculturalBoundaryBoxOpen',
//     get: ({ get }) => get(hotspotSelectorFamily('T3A')).value,
// });

export const isAgriculturalIncorrectAirGapSelector = selector({
    key: 'isAgriculturalIncorrectAirGap',
    get: ({ get }) => get(hotspotSelectorFamily('T4A')).value,
});

export const isCrossConnectionSelector = selector({
    key: 'isCrossConnection',
    get: ({ get }) => get(hotspotSelectorFamily('T5A')).value,
});

export const isBoreholePumpActiveSelector = selector({
    key: 'isBoreholePumpActive',
    // get: ({ get }) => get(hotspotSelectorFamily('T6A')).value,
    get: ({ get }) => true,
});

// non-household
export const isNonHouseholdDeadLegSelector = selector({
    key: 'isNonHouseholdDeadLeg',
    get: ({ get }) => get(hotspotSelectorFamily('NDeadLeg')).value,
});

export const isNonHouseholdDCVSelector = selector({
    key: 'isNonHouseholdDCV',
    get: ({ get }) => get(hotspotSelectorFamily('T2N_DCV')).value,
});

// export const isNonHouseholdSeepageSelector = selector({
//     key: 'isNonHouseholdSeepage',
//     get: ({ get }) => get(hotspotSelectorFamily('NSeepage')).value,
// });

// export const isNonHouseholdMdpePipeSelector = selector({
//     key: 'isNonHouseholdMdpePipe',
//     get: ({ get }) => {
//         const hotspot = get(hotspotSelectorFamily('NPipeMaterial'));
//         const selected = hotspot.selected;
//         if (selected === 'MDPE')
//             return true;
//         return false;
//     },
// });

// export const isNonHouseholdCopperPipeSelector = selector({
//     key: 'isNonHouseholdCopperPipe',
//     get: ({ get }) => {
//         const hotspot = get(hotspotSelectorFamily('NPipeMaterial'));
//         const selected = hotspot.selected;
//         if (selected === 'Copper')
//             return true;
//         return false;
//     },
// });

// export const isNonHouseholdBarrierPipeSelector = selector({
//     key: 'isNonHouseholdBarrierPipe',
//     get: ({ get }) => {
//         const hotspot = get(hotspotSelectorFamily('NPipeMaterial'));
//         const selected = hotspot.selected;
//         if (selected === 'Barrier')
//             return true;
//         return false;
//     },
// });

// export const isisNonHouseholdSeepageProofPipeMaterialSelector = selector({
//     key: 'isisNonHouseholdSeepageProofPipeMaterial',
//     get: ({ get }) => {
//         const isCopper = get(isNonHouseholdCopperPipeSelector);
//         const isBarrier = get(isNonHouseholdBarrierPipeSelector);
//         if (isCopper || isBarrier)
//             return true;
//         return false;
//     },
// });

export const isNonHouseholdOffscreenLeftSelector = selector({
    key: 'isNonHouseholdOffscreenLeft',
    get: ({ get }) => get(hotspotSelectorFamily('NOffLeft')).value,
});

export const isNonHouseholdOffscreenRightSelector = selector({
    key: 'isNonHouseholdOffscreenRight',
    get: ({ get }) => get(hotspotSelectorFamily('NOffRight')).value,
});

export const isNonHouseholdBoundaryBoxOpenSelector = selector({
    key: 'isNonHouseholdBoundaryBoxOpen',
    get: ({ get }) => get(hotspotSelectorFamily('T2N')).value,
});

export const isNonHouseholdPipeBurstSelector = selector({
    key: 'isNonHouseholdPipeBurst',
    get: ({ get }) => get(hotspotSelectorFamily('T1N')).value,
});

export const isNonHouseholdPipeBurstTwoSelector = selector({
    key: 'isNonHouseholdPipeBurstTwo',
    get: ({ get }) => get(hotspotSelectorFamily('T1N_2')).value,
});

export const isNonHouseholdNegativePressureSelector = selector({
    key: 'isNonHouseholdNegativePressure',
    get: ({ get }) => get(isNonHouseholdPipeBurstSelector) ? true : false,
});

export const isNonHouseholdSinkTapRunningSelector = selector({
    key: 'isNonHouseholdSinkTapRunning',
    get: ({ get }) => get(hotspotSelectorFamily('T4N')).value,
});

export const isNonHouseholdIncorrectAirGapSelector = selector({
    key: 'isNonHouseholdIncorrectAirGap',
    get: ({ get }) => get(hotspotSelectorFamily('T5N')).value,
});

export const isNonHouseholdNoneFittedSelector = selector({
    key: 'isNonHouseholdNoneFitted',
    get: ({ get }) => {
        const hotspot = get(hotspotSelectorFamily('T6N'));
        const selected = hotspot.selected;
        if (selected === 'None')
            return true;
        return false;
    },
});

export const isNonHouseholdSCVFittedSelector = selector({
    key: 'isNonHouseholdSCVFitted',
    get: ({ get }) => {
        const hotspot = get(hotspotSelectorFamily('T6N'));
        const selected = hotspot.selected;
        if (selected === 'SCV')
            return true;
        return false;
    },
});

export const isNonHouseholdRPZFittedSelector = selector({
    key: 'isNonHouseholdRPZFitted',
    // get: ({ get }) => {
    //     const hotspot = get(hotspotSelectorFamily('T6N'));
    //     const selected = hotspot.selected;
    //     if (selected === 'RPZ')
    //         return true;
    //     return false;
    // },
    get: ({ get }) => get(hotspotSelectorFamily('T6N')).value,
});

export const isNonHouseholdVanWashConnectedSelector = selector({
    key: 'isNonHouseholdVanWashConnected',
    get: ({ get }) => get(hotspotSelectorFamily('T3N')).value,
});

export const isWaterOptimiserFittedSelector = selector({
    key: 'isWaterOptimiserFitted',
    get: ({ get }) => get(hotspotSelectorFamily('T7N')).value,
});

export const nonHouseholdCisternLevelAtom = atom({
    key: 'nonHouseholdCisternLevel',
    default: 1,
});

export const reduceNonHouseholdCisternLevelSelector = selector({
    key: 'reduceNonHouseholdCisternLevel',
    set: ({ get, set }) => {
        const level = get(nonHouseholdCisternLevelAtom);
        if (level > 0.2)
            set(nonHouseholdCisternLevelAtom, level - 0.2);
        else
            set(nonHouseholdCisternLevelAtom, 1);
    },
});

