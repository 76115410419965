import { Switch, Route, Redirect } from 'react-router-dom';
import { DEFAULT_SCENARIO } from '../config/constants';

const Routes = ({ children }) => {
    return <Switch>
        <Route exact path='/'>
            <Redirect to={`/${DEFAULT_SCENARIO}`} />
        </Route>
        <Route exact path='/:scenario'>
            {children}
        </Route>
        <Route exact path='/:scenario/:screen'>
            {children}
        </Route>
    </Switch>
};

export default Routes;