import { useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSprings } from 'react-spring';
import { isNonHouseholdDCVSelector, isNonHouseholdDeadLegSelector, isNonHouseholdRPZFittedSelector, isCrossConnectionSelector, isStoptapOnSelector, isAgriculturalPipeBurstOneSelector, isAgriculturalIncorrectAirGapSelector, isHydrantOnSelector, isSinkFilterFittedSelector, isWashingMachineSCVSelector, isWashingMachineOnSelector, isHouseholdPipeBurstSelector, isOutsideTapDCVSelector, isShowerHeadImmersedSelector, isShowerDCVSelector, isStopCockOpenSelector, isHouseholdBoundaryBoxOpenSelector, isNonHouseholdPipeBurstSelector, isNonHouseholdPipeBurstTwoSelector, isNonHouseholdSinkTapRunningSelector, isContaminationFromWashingMachineSelector, isAgriculturalOffscreenLeftSelector, isHouseholdOffscreenRightSelector, isNonHouseholdOffscreenLeftSelector, isAgriculturalOffscreenRightSelector, isNonHouseholdOffscreenRightSelector, isBathTapRunningSelector, isFillLoopWithoutDCVSelector, isNonMaintainedFilterSelector, waterAtom, contaminationAtomFamily, isSeepageProofPipeMaterialSelector, isSinkTapRunningSelector, isNonHouseholdNoneFittedSelector, isNonHouseholdSCVFittedSelector, isNonHouseholdNegativePressureSelector, isNonHouseholdIncorrectAirGapSelector, isHouseholdNegativePressureSelector, isHydrantUnsafeSelector, isSeepageSelector, isContaminatedPipeSelector, isOutsideTapRunningSelector, scenarioPipeIdsSelectorFamily, scenarioPipeLengthsSelectorFamily, isHouseholdIncorrectAirGapSelector } from '../recoil/state.recoil';
import { WATER_COLORS, WATER_QUALITY, CONTAMINATION_DURATION_MULTIPLIER } from '../config/constants';
import useWaterColour from './useWaterColour.hook';

const getStrokeDashOffset = (dir, pathLength) => dir === -1 ? pathLength * 2 : 0;
const getDuration = pathLength => pathLength * CONTAMINATION_DURATION_MULTIPLIER;

const useContamination = scenario => {
    const isHouseholdOffscreenRight = useRecoilValue(isHouseholdOffscreenRightSelector);
    const isAgriculturalOffscreenLeft = useRecoilValue(isAgriculturalOffscreenLeftSelector);
    const isAgriculturalOffscreenRight = useRecoilValue(isAgriculturalOffscreenRightSelector);
    const isNonHouseholdOffscreenLeft = useRecoilValue(isNonHouseholdOffscreenLeftSelector);
    const isNonHouseholdOffscreenRight = useRecoilValue(isNonHouseholdOffscreenRightSelector);
    const isHouseholdNegativePressure = useRecoilValue(isHouseholdNegativePressureSelector);
    const isHydrantOn = useRecoilValue(isHydrantOnSelector);
    const isHydrantUnsafe = useRecoilValue(isHydrantUnsafeSelector);
    const isHouseholdPipeBurst = useRecoilValue(isHouseholdPipeBurstSelector);
    const isSeepage = useRecoilValue(isSeepageSelector);
    const isContaminatedPipe = useRecoilValue(isContaminatedPipeSelector);
    const isOutsideTapRunning = useRecoilValue(isOutsideTapRunningSelector);
    const isOutsideTapDCV = useRecoilValue(isOutsideTapDCVSelector);
    const isHouseholdIncorrectAirGap = useRecoilValue(isHouseholdIncorrectAirGapSelector);
    const isShowerHeadImmersed = useRecoilValue(isShowerHeadImmersedSelector);
    const isShowerDCV = useRecoilValue(isShowerDCVSelector);
    const isSinkFilterFitted = useRecoilValue(isSinkFilterFittedSelector);
    const isFillLoopWithoutDCV = useRecoilValue(isFillLoopWithoutDCVSelector);
    const isAgriculturalPipeBurstOne = useRecoilValue(isAgriculturalPipeBurstOneSelector);
    const isAgriculturalIncorrectAirGap = useRecoilValue(isAgriculturalIncorrectAirGapSelector);
    const isCrossConnection = useRecoilValue(isCrossConnectionSelector);
    const isStoptapOn = useRecoilValue(isStoptapOnSelector);
    const isNonHouseholdNegativePressure = useRecoilValue(isNonHouseholdNegativePressureSelector);
    const isNonHouseholdNoneFitted = useRecoilValue(isNonHouseholdNoneFittedSelector);
    const isNonHouseholdSCVFitted = useRecoilValue(isNonHouseholdSCVFittedSelector);
    const isNonHouseholdDCV = useRecoilValue(isNonHouseholdDCVSelector);
    const isNonHouseholdIncorrectAirGap = useRecoilValue(isNonHouseholdIncorrectAirGapSelector);
    const isSinkTapRunning = useRecoilValue(isSinkTapRunningSelector);
    const isBathTapRunning = useRecoilValue(isBathTapRunningSelector);
    const isSeepageProofPipeMaterial = useRecoilValue(isSeepageProofPipeMaterialSelector);
    const isNonMaintainedFilter = useRecoilValue(isNonMaintainedFilterSelector);
    const isContaminationFromWashingMachine = useRecoilValue(isContaminationFromWashingMachineSelector);
    const isNonHouseholdRPZFitted = useRecoilValue(isNonHouseholdRPZFittedSelector);
    const isNonHouseholdSinkTapRunning = useRecoilValue(isNonHouseholdSinkTapRunningSelector);
    const isNonHouseholdDeadLeg = useRecoilValue(isNonHouseholdDeadLegSelector);
    const isWashingMachineSCV = useRecoilValue(isWashingMachineSCVSelector);
    const isWashingMachineOn = useRecoilValue(isWashingMachineOnSelector);
    const isStopCockOpen = useRecoilValue(isStopCockOpenSelector);
    const isHouseholdBoundaryBoxOpen = useRecoilValue(isHouseholdBoundaryBoxOpenSelector);
    // const isNonHouseholdSeepage = useRecoilValue(isNonHouseholdSeepageSelector);
    // const isNonHouseholdMdpePipe = useRecoilValue(isNonHouseholdMdpePipeSelector);
    const isNonHouseholdPipeBurst = useRecoilValue(isNonHouseholdPipeBurstSelector);
    const isNonHouseholdPipeBurstTwo = useRecoilValue(isNonHouseholdPipeBurstTwoSelector);
    // const isToxic = (isHouseholdIncorrectAirGap && isSinkTapRunning) || ((isNonHouseholdIncorrectAirGap || isNonHouseholdNoneFitted) && (isNonHouseholdPipeBurst || isNonHouseholdPipeBurstTwo)) || (isNonHouseholdSeepage && isNonHouseholdMdpePipe && isNonHouseholdSinkTapRunning) || isContaminationFromWashingMachine || isAgriculturalOffscreenLeft || isAgriculturalOffscreenRight || isNonHouseholdOffscreenRight || isHydrantUnsafe || (isHouseholdNegativePressure && isFillLoopWithoutDCVSelector && !isOutsideTapRunning && !isBathTapRunning) || (isNonMaintainedFilter && isSinkTapRunning) || (isSeepage && !isSeepageProofPipeMaterial && isSinkTapRunning) || (isContaminatedPipe && isSinkTapRunning) || (isHouseholdNegativePressure && isHouseholdIncorrectAirGap) || (isNonHouseholdNegativePressure && (isNonHouseholdNoneFitted || isNonHouseholdSCVFitted || isNonHouseholdIncorrectAirGap)) ? true : false;
    const isToxic = (isHouseholdIncorrectAirGap && isSinkTapRunning) || ((isNonHouseholdIncorrectAirGap || isNonHouseholdNoneFitted) && (isNonHouseholdPipeBurst || isNonHouseholdPipeBurstTwo)) || isContaminationFromWashingMachine || isAgriculturalOffscreenLeft || isAgriculturalOffscreenRight || isNonHouseholdOffscreenRight || isHydrantUnsafe || (isHouseholdNegativePressure && isFillLoopWithoutDCVSelector && !isOutsideTapRunning && !isBathTapRunning) || (isNonMaintainedFilter && isSinkTapRunning) || (isSeepage && !isSeepageProofPipeMaterial && isSinkTapRunning) || (isContaminatedPipe && isSinkTapRunning) || (isHouseholdNegativePressure && isHouseholdIncorrectAirGap) || (isNonHouseholdNegativePressure && (isNonHouseholdNoneFitted || isNonHouseholdSCVFitted || isNonHouseholdIncorrectAirGap)) ? true : false;

    const waterColour = useWaterColour();

    // const waterColour = (() => {
    //     switch (true) {
            // case isHouseholdOffscreenRight:
            // case isAgriculturalOffscreenLeft:
            // case isAgriculturalOffscreenRight:
            // case isNonHouseholdOffscreenLeft:
            // case isNonHouseholdOffscreenRight:
            //     return WATER_COLORS[WATER_QUALITY.TOXIC];
            // case isHouseholdIncorrectAirGap && (!isStopCockOpen || !isHouseholdBoundaryBoxOpen) && isSinkTapRunning:
            //     return WATER_COLORS[WATER_QUALITY.PINK];
            // case isBathTapRunning && !isShowerDCV && (!isStopCockOpen || !isHouseholdBoundaryBoxOpen) && isSinkTapRunning:
            //     return WATER_COLORS[WATER_QUALITY.ORANGE];
            // case isOutsideTapRunning && !isOutsideTapDCV && isHouseholdPipeBurst:
            // case !isAgriculturalIncorrectAirGap && isAgriculturalPipeBurstOne:
            // case isCrossConnection && isStoptapOn:
            //     return WATER_COLORS[WATER_QUALITY.BROWN];
            // case isWashingMachineOn && !isWashingMachineSCV && isSinkTapRunning:
            // case isNonHouseholdDeadLeg && isNonHouseholdSinkTapRunning:
            //     return WATER_COLORS[WATER_QUALITY.YELLOW];
            // case !isSinkFilterFitted:
            //     return WATER_COLORS[WATER_QUALITY.MUSTARD];
            // case isFillLoopWithoutDCV && isSinkTapRunning:
            // case !isNonHouseholdRPZFitted && isNonHouseholdSinkTapRunning:
            //     return WATER_COLORS[WATER_QUALITY.RED];
            // case isContaminatedPipe:
            //     return WATER_COLORS[WATER_QUALITY.GREY];
            // case isSeepage && !isSeepageProofPipeMaterial:
            //     return WATER_COLORS[WATER_QUALITY.BLACK];
            // case isHydrantOn:
            //     return WATER_COLORS[WATER_QUALITY.CREAM];
            // case isNonHouseholdIncorrectAirGap && isNonHouseholdPipeBurstTwo:
            // case isNonHouseholdIncorrectAirGap && isNonHouseholdPipeBurst && !isNonHouseholdDCV:
            //     return WATER_COLORS[WATER_QUALITY.GREEN];
            // default:
            //     return WATER_COLORS[WATER_QUALITY.CLEAN];
    //     }
    // })();
    // const isNotClean = false;

    const pipeSegmentIds = useRecoilValue(scenarioPipeIdsSelectorFamily(scenario));
    const pipeSegmentLengths = useRecoilValue(scenarioPipeLengthsSelectorFamily(scenario));

    const setWater = useSetRecoilState(waterAtom);
    const contamination = useRecoilValue(contaminationAtomFamily(scenario));
    const parentNodes = contamination.filter(_ => _.prevNode === null);
    const getChildNodes = node => contamination.filter(({ prevNode }) => prevNode === node);

    const [ springs, setSprings ] = useSprings(pipeSegmentIds.length, idx => ({
        stroke: WATER_COLORS[WATER_QUALITY.CLEAN],
        strokeDashoffset: pipeSegmentLengths[pipeSegmentIds[idx]] || 0,
        immediate: true,
    }));
    const resetAllSegments = () => setSprings(idx => ({
        stroke: WATER_COLORS[WATER_QUALITY.CLEAN],
        strokeDashoffset: pipeSegmentLengths[pipeSegmentIds[idx]] || 0,
        immediate: true,
        onRest: () => {},
    }));
    const resetContaminatedSegments = () => setSprings(idx => {
        if (contamination.length && contamination.find(_ => _.node === pipeSegmentIds[idx]))
            return {
                // stroke: WATER_COLORS[isToxic ? WATER_QUALITY.TOXIC : WATER_QUALITY.TAINTED],
                stroke: waterColour,
                immediate: true,
            };
    });
    const resetWater = () => setWater(state => state.map(_ => ({ ..._, contaminated: false })));
    const animatePipes = ({ node, dir }) => setSprings(idx => {
        if (node === pipeSegmentIds[idx]) {
            const pathLength = pipeSegmentLengths[node];
            const childNodes = getChildNodes(node);
            let onRest = () => {};
            if (childNodes.length)
                onRest = () => startAnimationChain(childNodes);
            return {
                // stroke: WATER_COLORS[isToxic ? WATER_QUALITY.TOXIC : WATER_QUALITY.TAINTED],
                stroke: waterColour,
                strokeDashoffset: getStrokeDashOffset(dir, pathLength),
                immediate: false,
                config: { duration: getDuration(pathLength) },
                onRest,
            };
        }
    });
    const animateWater = ({ node }) => {
        const childNodes = getChildNodes(node);
        setWater(state => [ ...state.filter(_ => _.id !== node), { ...state.find(_ => _.id === node), contaminated: true } ]);
        if (childNodes.length)
            setTimeout(() => startAnimationChain(childNodes), 400);
    };
    const startAnimationChain = arr => arr.map(childNode => childNode.water ? animateWater(childNode) : animatePipes(childNode));

    useEffect(() => {
        resetAllSegments();
        resetWater();
        if (contamination.length) {
            resetContaminatedSegments();
            startAnimationChain(parentNodes);
        }
    }, [contamination]);

    return { pipeSegmentIds, pipeSegmentLengths, contamination, springs };
};

export default useContamination;